import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { listModifier } from '../storage'
import Modifier from '../../../models/modifier'

export default function DeleteModifier({ modifier }: { modifier: Modifier }) {
    return (
        <Delete
            title="Eliminar modificador"
            path={`/api/modifiers/${modifier.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listModifier.setQuery('page', 1)
                listModifier.load()
            }}
        >
            ¿Esta seguro de eliminar el modificador <b>{modifier.name}</b>?
        </Delete>
    )
}
