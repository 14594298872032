import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { BoxItemProps } from '../types'
import colors from 'config/theme/colors'

export default function ItemCounter({
    name,
    addQuantity,
    decrementQuantity,
    disabled,
    price = 0,
    hasStockControl = false,
    currentStock = 0,
    quantity = 0
}: BoxItemProps) {
    return (
        <Box minH="40px" py="2">
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={4}
            >
                <Box>
                    <Text>{name}</Text>
                    {price > 0 && (
                        <Flex alignItems={'center'} direction={'row'} gap={2}>
                            <Text>S/{price.toFixed(2)}</Text>
                            {quantity > 1 && <Text fontSize={'xs'}>c/u</Text>}
                        </Flex>
                    )}
                </Box>

                <Flex justifyContent={'center'} alignItems="center" gap={3}>
                    {hasStockControl && currentStock <= 0 && (
                        <Text color={colors['alo-red']} fontWeight="bold">
                            Agotado
                        </Text>
                    )}
                    <Flex
                        alignItems="center"
                        color="white"
                        justifyContent="center"
                    >
                        {quantity > 0 && (
                            <>
                                <Button
                                    bg={colors.dark}
                                    w={{
                                        base: 8
                                    }}
                                    h={{
                                        base: 8
                                    }}
                                    borderRadius="lg"
                                    fontSize="3xl"
                                    onClick={decrementQuantity}
                                    cursor="pointer"
                                    _hover={{}}
                                    _active={{}}
                                >
                                    -
                                </Button>
                                <Text
                                    fontSize="xl"
                                    color={colors.dark}
                                    mx={2}
                                    minW={{
                                        base: 8
                                    }}
                                    textAlign="center"
                                >
                                    {quantity}
                                </Text>
                            </>
                        )}

                        <Button
                            bg={colors.dark}
                            w={{
                                base: 8
                            }}
                            h={{
                                base: 8
                            }}
                            borderRadius="lg"
                            fontSize="3xl"
                            onClick={addQuantity}
                            cursor="pointer"
                            {...(disabled && {
                                opacity: '.6',
                                pointerEvents: 'none'
                            })}
                            _hover={{}}
                            _active={{}}
                        >
                            +
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}
