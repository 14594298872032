import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import CategoryItem from '../../models/category_item'
import Combo from '../../models/combo'
import Feature from '../../models/feature'
import Item from '../../models/item'
import Modifier from '../../models/modifier'
import ModifierGroup from '../../models/modifier_group'
import PreparationDestiny from '../../models/preparation_destiny'
import ProductionItem from '../../models/production_item'
// import PrinterMachine from '../../models/printer_machine'
import Supply from '../../models/supply'
import TypeMeasure from '../../models/type_measure'
import { END_POINTS } from './constants'
import { INVENTORY_TYPES } from './Letter/util'
import { FormDestiny, ListCategory } from './types'

export const listCategory = new ControlList<ListCategory>({
    path: '/api/category_items',
    theads: ['Nombre', 'Destino de preparación', 'Orden', 'Acciones'], // omited
    removeLimit: true
})

listCategory.setQuery('sort', 'order')

export const formCategory = new FormEvent({
    name: '',
    printer_id: ''
})

export const listDestiny = new ControlList<PreparationDestiny>({
    path: '/api/preparation_destinations',
    theads: ['Nombre', 'Tipo de conexión', 'IP', 'Acciones']
})

export const formDestiny = new FormEvent<FormDestiny>({
    name: '',
    ip: '',
    connectionType: ''
})

export const listItem = new ControlList<Item & { category_item: CategoryItem }>(
    {
        path: '/api/items',
        theads: ['', 'Nombre', 'Categoría', 'Precio', 'Acciones']
    }
)
listItem.setQuery('type', 1)

export const listSupply = new ControlList<
    Supply & {
        type_measure: TypeMeasure
    }
>({
    path: '/api/supplies',
    theads: ['#', 'Nombre', 'Unidad', 'Stock', 'Acciones']
})

export const listFeature = new ControlList<Feature>({
    path: '/api/features',
    theads: ['#', 'Nombre', 'Cantidad de subcaracteristicas', 'Acciones']
})

export const listProductionItem = new ControlList<ProductionItem>({
    path: '/api/production_items',
    theads: ['', 'Nombre']
})

export const listModifier = new ControlList<Modifier>({
    path: `/api${END_POINTS.MODIFIER}`,
    theads: ['#', 'Nombre', 'Acciones']
})

export const listModifierGroup = new ControlList<ModifierGroup>({
    path: `/api${END_POINTS.MODIFIER_GROUP}`,
    theads: [
        '#',
        'Nombre',
        'Cantidad de Modificadores',
        'Productos asociados',
        'Acciones'
    ]
})

export const listCombo = new ControlList<
    Combo & { category_item: CategoryItem }
>({
    path: '/api/combos',
    theads: ['', 'Nombre', 'Categoría', 'Precio', 'Acciones']
})

export const formItem = new FormEvent<any>({
    id: '',
    name: '',
    image_url: '',
    price: '',
    category_id: '',
    minimal_stock: '0',
    has_inventory_control: false,
    has_features: false,
    features: [],
    commission: '',
    has_commission: false,
    inventory_management_type: INVENTORY_TYPES.NO_INVENTORY,
    item_production_items: [],
    modifier_groups: [],
    initial_modifier_groups: [],
    initial_item_production_items: []
})

export type ItemCombo = Item & { quantity: number }

interface FormCombo {
    id?: string
    name: string
    price: string
    image_url: null | string
    category_id: string
    items: ItemCombo[]
}

export const formCombo = new FormEvent<FormCombo>({
    id: '',
    name: '',
    price: '',
    image_url: null,
    category_id: '',
    items: []
})

export const formFeature = new FormEvent<any>({
    name: '',
    sub_features: [
        {
            name: '',
            supply_id: '',
            price: '',
            quantity: ''
        }
    ]
})

export const formSupply = new FormEvent({
    id: '',
    name: '',
    has_inventory_control: false,
    stock: '',
    type_measure_id: '',
    default_visual_measure_id: ''
})

export const formEditionStock = new FormEvent({
    id: '',
    name: '',
    actual_stock: '',
    stock: '',
    type_movement_id: '',
    quantity: '',
    comment: ''
})

export const formModifier = new FormEvent<{
    id: string
    name: string
    production_items: any[]
    initial_production_items: any[]
}>({
    id: '',
    name: '',
    production_items: [],
    initial_production_items: []
})

export const formModifierGroup = new FormEvent<{
    id: string
    name: string
    modifiers: any[]
}>({
    id: '',
    name: '',
    modifiers: []
})
