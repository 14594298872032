import {
    Box,
    Flex,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Icon,
    useDisclosure
} from '@chakra-ui/react'
import Link from '../Sidebar/Link'
import LogoBox from '../Sidebar/LogoBox'
import dataLinks from '../Sidebar/dataLinks'
import Hamburguer from '../Icon/Hamburguer'
import useStorage from 'hooks/useStorage'

export default function SidebarMenu() {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { business_variables } = useStorage()
    const enable_warehouse = business_variables.find(
        v => v.code === '006'
    )?.value

    const isEnabledReservation =
        business_variables.find(v => v.code === '016')?.value === '1'

    const isEnabledElectronicReceipts =
        business_variables.find(v => v.code === '002')?.value === '1'

    const isEnabledTables =
        business_variables.find(v => v.code === '023')?.value === '1'

    let linksList = dataLinks
    if (!isEnabledReservation) {
        linksList = dataLinks.filter(
            l => l.path !== '/bookings' && l.path !== '/tourist_groups'
        )
    }
    if (!isEnabledElectronicReceipts) {
        linksList = linksList.filter(l => l.path !== '/generateVoucher')
    }
    if (!isEnabledTables) {
        linksList = linksList.filter(l => l.path !== '/tables')
    }

    const enable_credit_sales = business_variables.find(
        v => v.code === '021'
    )?.value

    return (
        <Flex pr="4">
            <Icon
                as={Hamburguer}
                fill={'blackAlpha.600'}
                cursor="pointer"
                fontSize="2xl"
                aria-label="Open menu"
                onClick={onOpen}
                _hover={{ fill: 'blackAlpha.500' }}
                pt={{ base: '0', md: '1' }}
            ></Icon>
            <Box>
                <Drawer isOpen={isOpen} onClose={onClose} placement="left">
                    <DrawerOverlay />
                    <DrawerContent maxW={'fit-content'}>
                        <Box w="232px" bg="black" color="white" h="100vh">
                            <Flex>
                                <Flex direction="column" py={8} w="full">
                                    <LogoBox />
                                    <Box
                                        className="scroll-no-bar"
                                        overflow={'auto'}
                                        style={{
                                            maxHeight: 'calc(100dvh - 180px)'
                                        }}
                                    >
                                        {linksList
                                            .filter(
                                                r =>
                                                    !(
                                                        (r.path ==
                                                            '/warehouses' &&
                                                            enable_warehouse ===
                                                                '0') ||
                                                        (r.path ==
                                                            '/pendingAccounts' &&
                                                            enable_credit_sales ===
                                                                '0')
                                                    )
                                            )
                                            .map(route => (
                                                <Link
                                                    key={route.path}
                                                    label={route.label}
                                                    Icon={route.Icon}
                                                    path={route.path}
                                                    subLinks={route.subLinks}
                                                />
                                            ))}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    </DrawerContent>
                </Drawer>
            </Box>
        </Flex>
    )
}
