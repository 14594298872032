import { Box, Text } from '@chakra-ui/react'
import { ModifierGroup } from '../../../../types/runway_state'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'

export default function ModifierGroups({
    modifierGroups
}: {
    modifierGroups?: ModifierGroup[]
}) {
    return (
        <>
            {modifierGroups &&
                modifierGroups.map((fes, _i) => (
                    <Box key={_i} display={'flex'} gap={2} fontSize="14px">
                        <Text pl={6} pr={5}>
                            <TextModifierGroupModifiers modifierGroup={fes} />
                        </Text>
                    </Box>
                ))}
        </>
    )
}
