import React, { useMemo } from 'react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalFooter,
    Text,
    Input,
    Flex,
    Checkbox,
    FormControl,
    Icon,
    Box
} from '@chakra-ui/react'
import Clean from '../../../../components/Clean'
import ErrorHelper from '../../../../components/ErrorHelper'
import { END_POINTS } from '../../constants'
import ModifierGroup from '../../../../models/modifier_group'
import Pagination from '../../../../components/Pagination'
import { useGet } from '../../../../hooks/useHttp'
import ArrowUp from '../../../../components/Icon/ArrowUp'
import ArrowDown from '../../../../components/Icon/ArrowDown'

interface Props {
    open: boolean
    onClose: () => void
    handleAddModifierGroups: (modifierGroups: ModifierGroup[]) => void
    title: string
    selectedModifierGroups?: ModifierGroup[]
    size?:
        | 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | 'full'
}

export default function ModalModifierGroups({
    open,
    onClose,
    handleAddModifierGroups,
    title,
    selectedModifierGroups = [],
    size = '2xl'
}: Props) {
    const finalRef = React.useRef<any>()
    const [helper, setHelper] = React.useState<string>('')
    const [checkedProductionItems, setCheckedProductionItems] = React.useState<{
        [id: number]: boolean
    }>({})
    const [opens, setOpens] = React.useState<{
        [id: number]: boolean
    }>({})
    const [modifiers, setModifiers] = React.useState<ModifierGroup[]>([])
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [search, setSearch] = React.useState('')

    const ids_modifier_groups = useMemo(
        () => selectedModifierGroups.map(data => data.id),
        [selectedModifierGroups]
    )

    const isValid = () => {
        let values = {}
        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                values = {
                    ...values,
                    [key]: value
                }
            }
        }
        return Object.keys(values).length !== 0
    }

    const clear = () => {
        setHelper('')
        setCheckedProductionItems({})
        setModifiers([])
        setOpens({})
    }

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!isValid()) {
            setHelper('Debe seleccionar por lo menos uno')
            return
        }

        const _productionItems = []

        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                const findProductionItem = modifiers.find(
                    data => Number(data.id) === Number(key)
                )
                if (findProductionItem) {
                    _productionItems.push(findProductionItem)
                }
            }
        }
        handleAddModifierGroups(_productionItems)

        clear()
    }

    const handleChange = (e: any, modifier: ModifierGroup) => {
        const value = e.target.checked
        const id = modifier.id
        if (value) {
            setModifiers(prev => [...prev, modifier])
        }

        setCheckedProductionItems(prev => {
            return {
                ...prev,
                [id]: value
            }
        })
    }

    const { data } = useGet<ModifierGroup[]>(
        `/api${END_POINTS.MODIFIER_GROUP}?limit=${limit}&page=${page}&search=${search}`,
        []
    )

    const handleClose = () => {
        clear()
        onClose()
    }

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={open}
            onClose={handleClose}
            isCentered={true}
            size={size}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    <Text>{title}</Text>
                </ModalHeader>
                <form onSubmit={handleSubmit} noValidate>
                    <ModalBody pb={6}>
                        <FormControl>
                            <ErrorHelper helper={helper} />
                        </FormControl>
                        <Flex mb="4">
                            <Clean
                                width={'full'}
                                value={search}
                                onClick={() => setSearch('')}
                            >
                                <Input
                                    value={search}
                                    placeholder="🏸 Buscar por nombre"
                                    onChange={e => setSearch(e.target.value)}
                                />
                            </Clean>
                        </Flex>
                        {data.result.map(_data => {
                            const selected = ids_modifier_groups.includes(
                                _data.id
                            )

                            const isChecked =
                                selected ||
                                (checkedProductionItems[_data.id] ?? false)

                            return (
                                <Box key={_data.id}>
                                    <Flex>
                                        <Box w="10%">
                                            <Checkbox
                                                size="lg"
                                                isChecked={isChecked}
                                                disabled={selected}
                                                onChange={event =>
                                                    handleChange(event, _data)
                                                }
                                            />
                                        </Box>
                                        <Box
                                            w="10%"
                                            onClick={() =>
                                                setOpens({
                                                    ...opens,
                                                    [_data.id]: !(
                                                        opens[_data.id] ?? false
                                                    )
                                                })
                                            }
                                        >
                                            {opens[_data.id] ?? false ? (
                                                <Icon as={ArrowDown} />
                                            ) : (
                                                <Icon as={ArrowUp} />
                                            )}
                                        </Box>
                                        <Box w="80%">{_data.name}</Box>
                                    </Flex>
                                    {(opens[_data.id] ?? false) &&
                                        _data.modifier_group_modifiers.length >
                                            0 && (
                                            <>
                                                <Box display="flex">
                                                    <Box w="20%" />
                                                    <Box
                                                        width={'40%'}
                                                        paddingX="2"
                                                    >
                                                        <Text fontSize="14px">
                                                            Nombre
                                                        </Text>
                                                    </Box>
                                                    <Box
                                                        paddingX="2"
                                                        width={'40%'}
                                                    >
                                                        <Text fontSize="14px">
                                                            Precio
                                                        </Text>
                                                    </Box>
                                                </Box>
                                                <Flex>
                                                    <Box w="20%" />
                                                    <Box w="80%">
                                                        {_data.modifier_group_modifiers.map(
                                                            _gm => (
                                                                <Flex
                                                                    key={_gm.id}
                                                                >
                                                                    <Box
                                                                        width={
                                                                            '50%'
                                                                        }
                                                                        paddingX="2"
                                                                        paddingY="1"
                                                                    >
                                                                        {
                                                                            _gm
                                                                                .modifier
                                                                                .name
                                                                        }
                                                                    </Box>
                                                                    <Box
                                                                        paddingX="2"
                                                                        paddingY="1"
                                                                        width={
                                                                            '50%'
                                                                        }
                                                                    >
                                                                        S./{' '}
                                                                        {
                                                                            _gm.price
                                                                        }
                                                                    </Box>
                                                                </Flex>
                                                            )
                                                        )}
                                                    </Box>
                                                </Flex>
                                            </>
                                        )}
                                </Box>
                            )
                        })}

                        <Pagination
                            limit={limit}
                            page={page}
                            pages={data.pagination?.pages ?? 0}
                            onLimit={setLimit}
                            onPage={setPage}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={handleClose}
                            type="button"
                        >
                            Cancelar
                        </Button>
                        <Button variant="alo-green:lg-md" ml={3} type="submit">
                            Agregar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
