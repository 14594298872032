import React from 'react'
import { ModifierGroup } from 'types/runway_state'

interface Props {
    modifierGroup: ModifierGroup
}

export default function TextModifierGroupModifiers({ modifierGroup }: Props) {
    return (
        <>
            {modifierGroup.name} :{' '}
            {modifierGroup.modifier_group_modifiers
                .map(
                    fssf =>
                        `${fssf.name}${
                            fssf.price > 0
                                ? `(S/${fssf.price})x${fssf.quantity}`
                                : ` x${fssf.quantity}`
                        }`
                )
                .join(', ')}
        </>
    )
}
