import {
    FormControl,
    FormErrorMessage,
    FormLabel
} from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Flex, RadioGroup } from '@chakra-ui/react'
import dayjs from 'dayjs'
import useDelivery from '../../../../../hooks/useDelivery'
import DeliveryMode, { DeliveryStatus } from '../../../../../types/deliveryMode'
import BoxContent from '../../../shared/BoxContent'
import RadioLabel from '../../../shared/RadioLabel'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../../../store/delivery-form.store'
import ObservationField from './ObservationField'

export default function DeliveryDetails() {
    const { delivery } = useDelivery()
    const deliveryMode = useDeliveryForm(state => state.deliveryMode)
    const date = useDeliveryForm(state => state.date)
    const hour = useDeliveryForm(state => state.hour)
    const set = useDeliveryForm(state => state.set)
    const helpers = useDeliveryHelper()

    return (
        <BoxContent title="Detalles de delivery">
            <FormControl mb={2} isInvalid={!!helpers.deliveryMode}>
                <FormLabel
                    color="gray.700"
                    fontWeight="normal"
                    fontSize="sm"
                    mb={0}
                >
                    Modo de entrega
                </FormLabel>
                <RadioGroup
                    value={deliveryMode}
                    isDisabled={
                        delivery
                            ? delivery.status > 2 ||
                              delivery.status === DeliveryStatus.CANCELED
                            : false
                    }
                    onChange={val => {
                        if (parseInt(val) === DeliveryMode.SCHEDULED) {
                            set({
                                deliveryMode: val,
                                date: dayjs().format('YYYY-MM-DD')
                            })
                        } else {
                            set({ deliveryMode: val })
                        }
                        helpers.setHelpers({ deliveryMode: '' })
                    }}
                >
                    <RadioLabel
                        label="Inmediata"
                        value={DeliveryMode.INMEDIATE.toString()}
                    />
                    <RadioLabel
                        label="Programada"
                        value={DeliveryMode.SCHEDULED.toString()}
                    />
                </RadioGroup>
                <FormErrorMessage>{helpers.deliveryMode}</FormErrorMessage>
            </FormControl>
            {(Number(deliveryMode) as DeliveryMode) ===
                DeliveryMode.SCHEDULED && (
                <FormControl mb={2} isInvalid={!!helpers.date}>
                    <FormLabel
                        color="gray.700"
                        fontWeight="normal"
                        fontSize="sm"
                        mb={0}
                    >
                        Hora de entrega
                    </FormLabel>
                    <Flex
                        gap={2}
                        flexDir={['column', 'column', 'column', 'row']}
                    >
                        <Input
                            bg="white"
                            size="sm"
                            rounded="md"
                            min={dayjs().format('YYYY-MM-DD')}
                            type="date"
                            isDisabled={
                                delivery
                                    ? delivery.status > 2 ||
                                      delivery.status ===
                                          DeliveryStatus.CANCELED
                                    : false
                            }
                            value={date}
                            onChange={ev => {
                                set({ date: ev.target.value })
                                helpers.setHelpers({ date: '' })
                            }}
                        />
                        <Input
                            bg="white"
                            size="sm"
                            rounded="md"
                            isDisabled={
                                delivery
                                    ? delivery.status > 2 ||
                                      delivery.status ===
                                          DeliveryStatus.CANCELED
                                    : false
                            }
                            type="time"
                            value={hour}
                            onChange={ev => {
                                set({ hour: ev.target.value })
                                helpers.setHelpers({ date: '' })
                            }}
                        />
                    </Flex>
                    <FormErrorMessage>
                        {helpers.date || helpers.hour}
                    </FormErrorMessage>
                </FormControl>
            )}
            <ObservationField />
        </BoxContent>
    )
}
