import React from 'react'
import { Text } from '@chakra-ui/react'
import colors from 'config/theme/colors'
import ModifierGroupItem from 'models/modifier_group_item'

interface Props {
    modifierGroupITem: ModifierGroupItem
    isRequiredQuantity: boolean
}

export default function TextSelectQuantity({
    isRequiredQuantity,
    modifierGroupITem
}: Props) {
    const getText = () => {
        if (
            modifierGroupITem.is_required &&
            modifierGroupITem.min_quantity > 0
        ) {
            return `Selecciona al menos ${modifierGroupITem.min_quantity}`
        }

        if (
            modifierGroupITem.is_required &&
            !modifierGroupITem.has_range &&
            modifierGroupITem.max_quantity > 0
        ) {
            return `Selecciona ${modifierGroupITem.max_quantity}`
        }

        if (!modifierGroupITem.is_required) {
            return `Selecciona máximo ${modifierGroupITem.max_quantity}`
        }

        return null
    }

    const text = getText()

    if (!text) return null

    return (
        <Text
            fontSize={'sm'}
            {...(isRequiredQuantity && {
                fontWeight: 'bold',
                color: colors.red[500]
            })}
        >
            {text}
        </Text>
    )
}
