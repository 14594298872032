import { useState } from 'react'
import {
    ItemData,
    MenuContextInterface,
    MenuItem
} from '../context/MenuContext'
import itemHash from '../utilities/item-hash'
import { ModifierGroupSelected } from 'components/ModalModifiersItem/types'
import Big from 'big.js'
import {
    compareModifierGroups,
    getTotalPrice
} from 'components/ModalModifiersItem/utils'
import { generateId } from 'utilities/generateId'

export default function useMenuProviderValues(
    defaultInitialMenu: MenuItem[] = []
): MenuContextInterface {
    const [initialMenu, setInitialMenu] = useState<MenuItem[]>(
        () => defaultInitialMenu
    )
    const [menu, setMenu] = useState<MenuItem[]>(() => defaultInitialMenu)

    function init(_menu: MenuItem[]) {
        const menuData = []
        for (const menuItem of _menu) {
            menuData.push({ ...menuItem })
        }
        setInitialMenu([...menuData])
        setMenu(_menu)
    }

    function addItem(itemData: ItemData, quantity = 1) {
        const hash = itemHash({
            itemId: itemData.id
        })

        const item = menu.find(
            _item => _item.hash === hash || _item.itemId === itemData.id
        )

        if (item) {
            setMenu(
                menu.map(_item => {
                    if (_item.hash === item.hash) {
                        /**
                         * Cuando su cantidad es valor a cero, quiere decir que está eliminado,
                         * solo que aún se mantiene registrado en el estado.
                         */
                        const wasRemoved = _item.quantity === 0
                        return {
                            ..._item,
                            description: wasRemoved ? '' : _item.description,
                            price: wasRemoved ? itemData.price : _item.price,
                            quantity: _item.quantity + quantity
                        }
                    }
                    return _item
                })
            )
            return
        }

        setMenu([
            ...menu,
            {
                hash,
                itemId: itemData.id,
                name: itemData.name,
                quantity,
                price: itemData.price,
                description: '',
                categoryName: itemData.category_item.name,
                categoryItemId: itemData.category_item_id,
                commission: itemData.has_commission ? itemData.commission : 0
            }
        ])
    }

    function updateQuantity(itemIdOrHash: number | string, quantity: number) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        quantity
                    }
                }

                return item
            })
        )
    }

    function getQuantity(itemId: number) {
        return menu
            .filter(menuItem => menuItem.itemId === itemId)
            .reduce((n, menuItem) => menuItem.quantity + n, 0)
    }

    function updatePrice(itemIdOrHash: number | string, price: number) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        price,
                        total_price: getTotalPrice(
                            price,
                            item.modifierGroups ?? []
                        )
                    }
                }

                return item
            })
        )
    }

    function updateDescription(
        itemIdOrHash: number | string,
        description: string
    ) {
        setMenu(_menu =>
            _menu.map(item => {
                const inspect =
                    (typeof itemIdOrHash === 'number' &&
                        itemIdOrHash === item.itemId) ||
                    itemIdOrHash === item.hash
                if (inspect) {
                    return {
                        ...item,
                        description
                    }
                }

                return item
            })
        )
    }

    function clear() {
        setMenu(menu.map(item => ({ ...item, quantity: 0 })))
    }

    function getPriceTotal() {
        return menu.reduce(
            (n, item) =>
                n +
                (item.modifierGroups && item.modifierGroups?.length > 0
                    ? (item.total_price ?? 0) * item.quantity
                    : item.price * item.quantity),
            0
        )
    }

    function addItemWithModifiers(
        itemData: ItemData,
        quantity = 1,
        modifierGroupsSelected: ModifierGroupSelected[],
        description = ''
    ) {
        const copyMenu = [...menu]
        let isFinded = false
        for (const menuItem of copyMenu) {
            if (
                compareModifierGroups(menuItem.modifierGroups ?? [], [
                    ...modifierGroupsSelected
                ]) &&
                itemData.id === menuItem.itemId
            ) {
                menuItem.quantity = menuItem.quantity + quantity
                isFinded = true
                break
            }
        }

        if (isFinded) {
            setMenu([...copyMenu])
        } else {
            const hash = itemHash({
                itemId: itemData.id,
                alias: generateId()
            })
            const acumulate_price_modifiers = modifierGroupsSelected.reduce(
                (a, b) =>
                    a +
                    b.modifier_group_modifiers.reduce(
                        (c, d) => c + d.price * d.quantity,
                        0
                    ),
                0
            )

            setMenu([
                ...menu,
                {
                    hash,
                    itemId: itemData.id,
                    name: itemData.name,
                    quantity,
                    price: itemData.price,
                    description,
                    categoryName: itemData.category_item.name,
                    categoryItemId: itemData.category_item_id,
                    commission: itemData.has_commission
                        ? itemData.commission
                        : 0,
                    modifierGroups: [...modifierGroupsSelected],
                    total_price: Number(
                        new Big(itemData.price ?? 0)
                            .plus(acumulate_price_modifiers)
                            .toString()
                    ),
                    hasModifiers: true
                }
            ])
        }
    }

    function updateItemWithModifiers(
        menuItemHash: string,
        quantity = 1,
        modifierGroupsSelected: ModifierGroupSelected[],
        description = ''
    ) {
        const copyMenu = [...menu]
        const findMenuItem = copyMenu.find(
            menuItem => menuItem.hash === menuItemHash
        )
        let isFinded = false
        for (const menuItem of copyMenu) {
            const inspect = menuItemHash === menuItem.hash

            if (
                findMenuItem?.itemId === menuItem.itemId &&
                compareModifierGroups(menuItem.modifierGroups ?? [], [
                    ...modifierGroupsSelected
                ]) &&
                !inspect
            ) {
                menuItem.quantity = menuItem.quantity + quantity
                isFinded = true
                break
            }
        }

        let updateMenu: MenuItem[] = []

        if (isFinded) {
            updateMenu = [
                ...copyMenu.filter(menuItem => menuItem.hash !== menuItemHash)
            ]
        } else {
            const acumulate_price_modifiers = modifierGroupsSelected.reduce(
                (a, b) =>
                    a +
                    b.modifier_group_modifiers.reduce(
                        (c, d) => c + d.price * d.quantity,
                        0
                    ),
                0
            )
            updateMenu = [
                ...menu.map(menuItem => {
                    const inspect = menuItemHash === menuItem.hash
                    if (inspect) {
                        return {
                            ...menuItem,
                            description,
                            quantity,
                            modifierGroups: [...modifierGroupsSelected],
                            total_price: Number(
                                new Big(menuItem.price ?? 0)
                                    .plus(acumulate_price_modifiers)
                                    .toString()
                            )
                        }
                    }

                    return menuItem
                })
            ]
        }

        setMenu([...updateMenu])
    }

    return {
        init,
        initialMenu,
        menu,
        addItem,
        updateQuantity,
        getQuantity,
        updatePrice,
        clear,
        getPriceTotal,
        updateDescription,
        setMenu,
        addItemWithModifiers,
        updateItemWithModifiers
    }
}
