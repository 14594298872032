import { createContext } from 'react'
import Item from '../models/item'
import CategoryItem from '../models/category_item'
import { ModifierGroupSelected } from 'components/ModalModifiersItem/types'

export interface MenuItem {
    /**
     * unique.
     * alias-item_id-description
     */
    hash: string
    itemId: number
    /**
     * Sirve solo para la pre-order
     * @deprecated
     */
    preOrderItemId?: number
    orderItemId?: number
    userPriceEdition?: number
    name: string
    quantity: number
    price: number
    description: string
    categoryName: string
    categoryItemId: number
    commission: number
    modifierGroups?: ModifierGroupSelected[]
    /**
     * Sirve solo para cuando se tenga modificadores
     */
    total_price?: number
    isForEdition?: boolean
    hasModifiers?: boolean
}

export interface ItemData extends Item {
    category_item: CategoryItem
}

export interface MenuContextInterface {
    /**
     * Inicializa los datos. Pensado solo para edición
     * @param menuItem el menú.
     */
    init: (menuItem: MenuItem[]) => void
    /**
     * Sirve para realizar una comparación con el menú y así determinar cual es el item que se va eliminar, actualizar o agregar.
     * Este es solo usado en edición.
     */
    initialMenu: MenuItem[]
    /**
     * Se agrega los nuevos platos al menú.
     * En edición, se deben cargar los items previamente guardados.
     */
    menu: MenuItem[]
    /**
     * Agrega un nuevo item al menú. Si existe en el menú aumentará su cantidad.
     * @param itemData Item
     * @param quantity Cantidad que se va agregar.
     */
    addItem: (itemData: ItemData, quantity?: number) => void
    /**
     * Actualiza la cantidad del item del menú.
     */
    updateQuantity: (itemIdOrHash: number | string, quantity: number) => void
    /**
     * Retorna la cantidad del item del menú actual.
     */
    getQuantity: (itemId: number) => number
    /**
     * Actualiza el precio actual
     */
    updatePrice: (itemIdOrHash: number | string, price: number) => void
    /**
     * Actualiza la descripción
     */
    updateDescription: (
        itemIdOrHash: number | string,
        description: string
    ) => void
    /**
     * Todos los items del menú existentes pasarán a ser cantidad cero.
     * No se eliminan porque será necesario la información en la página modo edición.
     */
    clear: () => void
    /**
     * Precio total del menú.
     */
    getPriceTotal: () => number
    /**
     * Reemplaza el menu actual
     */
    setMenu: (menu: MenuItem[]) => void
    /**
     * Agrega un nuevo item con modificadores al menú. Si existe en el menú aumentará su cantidad.
     * @param itemData Item
     * @param quantity Cantidad que se va agregar.
     * @param modifierGroupsSelected Modificadores seleccionados para el item.
     */
    addItemWithModifiers: (
        itemData: ItemData,
        quantity: number,
        modifierGroupsSelected: ModifierGroupSelected[],
        description?: string
    ) => void
    /**
     *
     * @param itemIdOrHash Para editar el item
     * @param quantity Cantidad a actualizar en el titem.
     * @param modifierGroupsSelected Modificadores seleccionados para el item.
     */
    updateItemWithModifiers: (
        itemHash: string,
        quantity: number,
        modifierGroupsSelected: ModifierGroupSelected[],
        description?: string
    ) => void
}

const MenuContext = createContext<MenuContextInterface>({
    init: () => null,
    initialMenu: [],
    menu: [],
    addItem: () => null,
    updateQuantity: () => null,
    getQuantity: () => 0,
    updatePrice: () => null,
    updateDescription: () => null,
    clear: () => null,
    getPriceTotal: () => 0,
    setMenu: () => null,
    addItemWithModifiers: () => null,
    updateItemWithModifiers: () => null
})

export default MenuContext
