import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Button,
    Flex,
    HStack,
    Input
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Download from '../../../../components/Icon/Download'
import colors from '../../../../config/theme/colors'
// import { useGet } from '../../../../hooks/useHttp'
import { get } from '../../../../lib/http'
import Pagination from '../../../../types/Pagination'
import useExportHistory from '../../hooks/useExportHistory'
import TableMovements from './TableMovements'
import { FindItemMovement, ModalHistoriesProps } from './types'

export default function ModalHistories({
    isOpen,
    onClose,
    item,
    initialStartDate,
    inititalEndDate
}: ModalHistoriesProps) {
    const [data, setData] = useState<FindItemMovement[]>([])

    const [pagination, setPagination] = useState<Pagination | null>(null)
    const [startDate, setStartDate] = useState(() => initialStartDate)
    const [endDate, setEndDate] = useState(() => inititalEndDate)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    async function load() {
        const itemMovements = await get<FindItemMovement[]>(
            `/api/movement_production_items?search=&limit=${limit}&page=${page}&start_date=${dayjs
                .tz(startDate + ' 00:00:00', 'America/Lima')
                .toISOString()}&end_date=${dayjs
                .tz(endDate + ' 23:59:59', 'America/Lima')
                .toISOString()}&production_item_id=${
                item.production_item.id
            }&warehouse_id=${item.warehouse.id}&sort=id&order=desc`
        )

        if (itemMovements.data) {
            setData(itemMovements.data.result)
            setPagination(itemMovements.data?.pagination || null)
        }
    }

    useEffect(() => {
        if (!isOpen) return
        load()
    }, [isOpen, limit, page, startDate, endDate])

    useEffect(() => {
        setStartDate(initialStartDate)
        setEndDate(inititalEndDate)
    }, [initialStartDate, inititalEndDate])

    const { submit, isLoading } = useExportHistory(item, startDate, endDate)

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl" closeOnOverlayClick>
            <ModalOverlay />
            <ModalContent px={2} py={4}>
                <ModalBody pb={6}>
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        {`Historial de ${item.production_item?.name}`}
                    </Text>
                    <Text
                        textAlign="center"
                        fontSize="sm"
                        color={colors['gray-2']}
                        mb={6}
                    >
                        {`Almacén: ${item.warehouse.name}`}
                    </Text>
                    <Flex
                        flexDirection={{
                            base: 'column',
                            md: 'row'
                        }}
                        align="center"
                        mb={6}
                        justify="center"
                        gap={4}
                    >
                        <HStack spacing={2}>
                            <Text>Desde:</Text>
                            <Input
                                type="date"
                                value={startDate}
                                max={endDate}
                                onChange={ev => setStartDate(ev.target.value)}
                            />
                        </HStack>
                        <HStack spacing={2}>
                            <Text>Hasta:</Text>
                            <Input
                                type="date"
                                min={startDate}
                                value={endDate}
                                onChange={ev => setEndDate(ev.target.value)}
                            />
                        </HStack>
                    </Flex>
                    <Flex justify="space-between" my={4} align="center">
                        <Text fontWeight="bold" color={colors['gray-2']}>
                            Movimientos
                        </Text>
                        <Button
                            variant="alo-gray:sm-xl"
                            fontSize="xs"
                            type="submit"
                            rightIcon={<Download fill="white" />}
                            onClick={submit}
                            isLoading={isLoading}
                            isDisabled={data.length === 0}
                        >
                            Exportar a Excel
                        </Button>
                    </Flex>
                    <TableMovements
                        onLimit={setLimit}
                        onPage={setPage}
                        page={page}
                        limit={limit}
                        pages={pagination?.pages || 1}
                        itemMovements={data}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
