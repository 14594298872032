import { Tr, Td, Image } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listCombo } from '../storage'
import DefaultPlate from '../../../assets/default-plate.png'
import DeleteCombo from './DeleteCombo'
import Edition from './Edition'

export default function Combo() {
    return (
        <List
            control={listCombo}
            tbody={(combo, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        <Image
                            boxSize="50px"
                            src={combo.image_url ?? DefaultPlate}
                            alt={combo.name}
                            objectFit="cover"
                            ml="auto"
                        />
                    </Td>
                    <Td textAlign="center">{combo.name}</Td>
                    <Td textAlign="center">{combo.category_item?.name}</Td>
                    <Td textAlign="center">S/{combo.price}</Td>
                    <Td textAlign="center">
                        <Edition combo={combo} />
                        <DeleteCombo combo={combo} />
                    </Td>
                </Tr>
            )}
        />
    )
}
