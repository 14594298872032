import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    HStack,
    Center,
    Spinner,
    Fade,
    ModalHeader,
    ModalCloseButton,
    Box
} from '@chakra-ui/react'
import useModalModifiersItem from './hooks/useModalModifiersItem'
import Section1 from './Section1'
import { IceCreamProps } from './types'
import SectionCounter from './SectionCounter'

export default function ModalModifiersItem({
    element,
    itemId,
    onDone,
    edition,
    quantity = 1,
    description: initialDescription
}: IceCreamProps) {
    const {
        isOpen,
        onOpen,
        onClose,
        counter,
        setCounter,
        features,
        isLoading,
        item,
        submitHandler,
        addQuantityModifierGroupItem,
        modifierGroups,
        requiredQuantityModifierGroup,
        description,
        setDescription
    } = useModalModifiersItem(
        itemId,
        onDone,
        quantity,
        edition,
        initialDescription
    )

    const itemName = item?.name ?? ''
    const basePrice = item?.price ?? 0

    return (
        <>
            {element(onOpen)}
            <Modal
                isCentered={false}
                size="xl"
                onClose={onClose}
                isOpen={isOpen}
            >
                <ModalOverlay />
                <ModalContent h="100dvh" margin="0">
                    <ModalHeader bg="#F6F6F6">
                        {itemName} S/ {basePrice.toFixed(2)}
                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody p="0">
                        {isLoading && (
                            <Center h="96" w="full">
                                <Spinner />
                            </Center>
                        )}
                        <form onSubmit={submitHandler}>
                            {!isLoading && (
                                <Fade in={isOpen}>
                                    <HStack
                                        flexDirection={{
                                            base: 'column'
                                        }}
                                        align="stretch"
                                    >
                                        <Box pr="2">
                                            <Box
                                                overflowY={'auto'}
                                                minH="calc(100vh - 198px)"
                                                maxH="calc(100vh - 198px)"
                                            >
                                                <Section1
                                                    modifierGroupItems={
                                                        item
                                                            ? item.modifier_group_items
                                                            : []
                                                    }
                                                    featuresSelected={features}
                                                    addQuantityModifierGroupItem={
                                                        addQuantityModifierGroupItem
                                                    }
                                                    modifierGroupSelected={
                                                        modifierGroups
                                                    }
                                                    requiredQuantityModifierGroup={
                                                        requiredQuantityModifierGroup
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        <SectionCounter
                                            counter={counter}
                                            setCounter={setCounter}
                                            isEdition={!!edition}
                                            basePrice={basePrice}
                                            modifierGroupSelected={
                                                modifierGroups
                                            }
                                            description={description}
                                            setDescription={setDescription}
                                        />
                                    </HStack>
                                </Fade>
                            )}
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
