import { useDisclosure, useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { FeatureSelected, GetItem, ModifierGroupSelected } from '../types'
import Big from 'big.js'
import { get } from 'lib/http'
import Feature from 'models/feature'
import { SubFeature } from 'models/sub_feature'
import ModifierGroup, { ModifierGroupModifier } from 'models/modifier_group'

export default function useModalModifiersItem(
    itemId: number,
    onDone: (
        featuresSelected: FeatureSelected[],
        quantity: number,
        modifierGroupsSelected: ModifierGroupSelected[],
        item_price_plus_features_price: number,
        idGenerated?: string,
        description?: string
    ) => void,
    initialQuantity: number,
    edition?: ModifierGroupSelected[],
    editionDescription?: string
) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [counter, setCounter] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [features, setFeatures] = useState<FeatureSelected[]>([])
    const [modifierGroups, setModifierGroups] = useState<
        ModifierGroupSelected[]
    >([])
    const [item, setItem] = useState<GetItem | null>(null)
    const [description, setDescription] = useState<string>('')
    const [requiredQuantityModifierGroup, setRequiredQuantityModifierGroup] =
        useState({})
    const toast = useToast()

    async function _onOpen() {
        onOpen()
        setLoading(true)
        const { data, error } = await get<GetItem>(`/api/items/${itemId}`)
        if (!error) {
            setItem(data.result)
            resetModifierGroups(data.result.modifier_group_items)
            if (edition) {
                setModifierGroups(nextModifierSelect => {
                    return nextModifierSelect.map(ms => {
                        const modifierSelectedOfEdition = edition.find(
                            e => e.modifier_group_id === ms.modifier_group_id
                        )
                        if (modifierSelectedOfEdition) {
                            return {
                                ...modifierSelectedOfEdition,
                                modifier_group_modifiers: [
                                    ...modifierSelectedOfEdition.modifier_group_modifiers
                                ]
                            }
                        }
                        return ms
                    })
                })
            }
            if (editionDescription) {
                setDescription(editionDescription)
            }
        }
        setLoading(false)
        setCounter(initialQuantity)
    }

    function _setFeature(
        feature: Feature,
        subFeature: SubFeature,
        quantity: number
    ) {
        const nextFeatures = [...features]
        const nextFeature = nextFeatures.find(
            f => f.feature_id === feature.id
        ) as FeatureSelected

        if (nextFeature.subs.length === quantity && quantity > 1) return
        if (quantity === 1) {
            nextFeature.subs = [
                {
                    sub_feature_id: subFeature.id,
                    name: subFeature.name,
                    price: subFeature.price,
                    aditional_information: ''
                }
            ]
            setFeatures(nextFeatures)
        } else {
            nextFeature.subs = [...nextFeature.subs]
            nextFeature.subs.push({
                sub_feature_id: subFeature.id,
                name: subFeature.name,
                price: subFeature.price,
                aditional_information: ''
            })
            setFeatures(nextFeatures)
        }
    }

    function addQuantityModifierGroupItem(
        modifierGroup: ModifierGroup,
        modifierGroupModifier: ModifierGroupModifier,
        quantity: number
    ) {
        const updateModifierGroups = [...modifierGroups]
        const findModifierGroup = updateModifierGroups.find(
            f => f.modifier_group_id === modifierGroup.id
        )

        if (!findModifierGroup) return

        const findModifierGroupItem =
            findModifierGroup.modifier_group_modifiers.find(
                m => m.id === modifierGroupModifier.id
            )

        if (!findModifierGroupItem) {
            findModifierGroup.modifier_group_modifiers = [
                ...findModifierGroup.modifier_group_modifiers,
                {
                    id: modifierGroupModifier.id,
                    modifier_id: modifierGroupModifier.modifier_id,
                    name: modifierGroupModifier.modifier.name,
                    price: modifierGroupModifier.price,
                    quantity
                }
            ]
        } else {
            const newQuantity = findModifierGroupItem.quantity + quantity

            const newModifierGroupItems = []

            for (const _modifierGroupItem of findModifierGroup.modifier_group_modifiers) {
                if (_modifierGroupItem.id === findModifierGroupItem.id) {
                    if (newQuantity !== 0) {
                        newModifierGroupItems.push({
                            ..._modifierGroupItem,
                            quantity: newQuantity
                        })
                    }
                } else {
                    newModifierGroupItems.push(_modifierGroupItem)
                }
            }

            findModifierGroup.modifier_group_modifiers = newModifierGroupItems
        }

        setModifierGroups(updateModifierGroups)
    }

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const base_price_item = item ? item.price : 0 //el item.price se vuelve a traer de la BD
        const acumulate_price_subfeatures = modifierGroups.reduce(
            (a, b) =>
                a +
                b.modifier_group_modifiers.reduce(
                    (c, d) => c + d.price * d.quantity,
                    0
                ),
            0
        )

        const item_price_plus_features_price = Number(
            new Big(base_price_item)
                .plus(acumulate_price_subfeatures)
                .toString()
        )

        const formElem = ev.target as HTMLFormElement
        if (formElem.name === 'form-information') return
        const item_features =
            (item?.item_features as GetItem['item_features']) ?? []
        for (const itemFeature of item_features) {
            const featureSelected = features.find(
                f => f.feature_id === itemFeature.feature_id
            )
            if (featureSelected) {
                const subsQuantity = featureSelected.subs.length
                if (
                    itemFeature.quantity !== subsQuantity &&
                    itemFeature.quantity > 0
                ) {
                    toast({
                        title: `Seleccione ${featureSelected.quantity} ${featureSelected.name}`,
                        status: 'error',
                        position: 'top',
                        isClosable: true
                    })
                    return
                }
            } else if (itemFeature.quantity > 0) {
                toast({
                    title: `Seleccione ${itemFeature.quantity} ${itemFeature.feature.name}`,
                    status: 'error',
                    position: 'top',
                    isClosable: true
                })
                return
            }
        }

        const item_modifiers_group_items =
            (item?.modifier_group_items as GetItem['modifier_group_items']) ??
            []

        let _requiredQuantityModifierGroup = {}

        for (const itemModifierGroupItem of item_modifiers_group_items) {
            if (!itemModifierGroupItem.is_required) continue

            const itemModifierGroupSelected = modifierGroups.find(
                m =>
                    m.modifier_group_id ===
                    itemModifierGroupItem.modifier_group_id
            )

            if (!itemModifierGroupSelected) {
                _requiredQuantityModifierGroup = {
                    ..._requiredQuantityModifierGroup,
                    [itemModifierGroupItem.modifier_group_id]: true
                }

                continue
            }

            const minQuantity = itemModifierGroupItem.has_range
                ? itemModifierGroupItem.min_quantity
                : itemModifierGroupItem.max_quantity

            const quantityModifiers =
                itemModifierGroupSelected?.modifier_group_modifiers?.reduce(
                    (a, b) => a + b.quantity,
                    0
                ) ?? 0

            if (quantityModifiers < minQuantity) {
                _requiredQuantityModifierGroup = {
                    ..._requiredQuantityModifierGroup,
                    [itemModifierGroupItem.modifier_group_id]: true
                }
            }
        }

        if (Object.keys(_requiredQuantityModifierGroup).length > 0) {
            setRequiredQuantityModifierGroup({
                ..._requiredQuantityModifierGroup
            })
            return
        }

        onDone(
            features.filter(f => f.subs.length > 0),
            counter,
            modifierGroups.filter(m => m.modifier_group_modifiers.length > 0),
            item_price_plus_features_price,
            undefined,
            description
        )

        onClose()
        setRequiredQuantityModifierGroup({})
        setCounter(1)
        setDescription('')
    }

    function resetModifierGroups(
        modifierGroupItems: GetItem['modifier_group_items']
    ) {
        setModifierGroups(
            modifierGroupItems.map(modifierGroupItem => ({
                name: modifierGroupItem.modifier_group.name,
                modifier_group_id: modifierGroupItem.modifier_group_id,
                modifier_group_modifiers: []
            }))
        )
    }

    return {
        item,
        counter,
        setCounter,
        onClose,
        isOpen,
        onOpen: _onOpen,
        features,
        setFeature: _setFeature,
        isLoading,
        setFeatures,
        submitHandler,
        modifierGroups,
        addQuantityModifierGroupItem,
        requiredQuantityModifierGroup,
        description,
        setDescription
    }
}
