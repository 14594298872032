import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { listCombo } from '../storage'
import Combo from '../../../models/combo'

export default function DeleteCombo({ combo }: { combo: Combo }) {
    return (
        <Delete
            title="Eliminar combo"
            path={`/api/combos/${combo.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listCombo.setQuery('page', 1)
                listCombo.load()
            }}
        >
            ¿Esta seguro de eliminar el combo <b>{combo.name}</b>?
        </Delete>
    )
}
