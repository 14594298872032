import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import Block from '../../../components/Icon/Block'
import CashIcon from '../../../components/Icon/CashIcon'
import Printer from '../../../components/Icon/Printer'
import ModalPin from '../../../components/ModalPin'
import ModalErrorSend from '../../Statement/ModalContents/ModalErrorSend'
import DialogCancel from '../ContentModals/DialogCancel'
import useConfirmSale from '../hooks/useConfirmSale'
import usePrecount from '../hooks/usePrecount'
import { storagePage } from '../storage'
import DialogEditOrder from '../ContentModals/DialogEditOrder'
import useDialog from '../../../hooks/useDialog'
import useSession from '../../../hooks/useSession'
import useStorage from '../../../hooks/useStorage'

export default function ContentBottom() {
    const {
        confirmHandler,
        payHandler,
        isLogged,
        isWaiter,
        openModal,
        dataModal,
        setOpenModal,
        update,
        confirmPreOrderHandler,
        userCanCancelItems
    } = useConfirmSale()

    const { precountHandler, isPrecountLoading } = usePrecount()
    const { table_id } = useParams<{ table_id?: string }>()
    const {
        selectedItems,
        editOrderTable,
        hasOrderTable,
        table,
        isOpenCashbox,
        isPreOrder
    } = storagePage

    const { session } = useSession()

    const { business_variables } = useStorage()

    const enable_admin_pin = business_variables.find(
        v => v.code === '013'
    )?.value

    const need_admin_pin =
        enable_admin_pin == '1' && session?.user.is_vip != true

    const newItems = storagePage.selectedItems
    let decreaseItems = false
    for (let i = 0; i < newItems.length; i++) {
        for (let j = 0; j < newItems[i].items.length; j++) {
            if (
                newItems[i].items[j].quantity <
                    newItems[i].items[j].quantity_recorded &&
                newItems[i].items[j].order_item_id != null
            ) {
                decreaseItems = true
                break
            }
        }
        if (decreaseItems == true) break
    }

    const money = selectedItems
        .map(selected =>
            selected.items.reduce((a, b) => {
                let restCost = 0
                if (b.modifier_groups) {
                    restCost = b.modifier_groups.reduce(
                        (c, d) =>
                            c +
                            d.modifier_group_modifiers.reduce(
                                (e, f) => e + f.price * f.quantity,
                                0
                            ),
                        0
                    )
                }
                return a + (b.price + restCost) * b.quantity
                //Esto era cuando el precio del item ya incluía los precios de las subcaracteristicas
                //return a + b.price * b.quantity
            }, 0)
        )
        .reduce((a, b) => a + b, 0)

    const dialogZeroItems = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                No se puede actualizar el pedido con <b>cero ítems</b>, utilice
                la opción de <b>Anular pedido</b>.
            </>
        )
    })

    function evaluateUpdate(click: () => void, clickPin: () => void) {
        if (money == 0) {
            dialogZeroItems() // Modal que informa que no se puede actualizar con 0 items
        } else if (decreaseItems && need_admin_pin) {
            clickPin() // Modal para ingresar pin de autorización
        } else if (decreaseItems && !need_admin_pin) {
            click() // Modal para ingresar motivo de anulación
        } else {
            confirmHandler() // Actualiza pedido sin motivo
        }
    }

    return (
        <Box px="6" position="absolute" bottom={0} w="full" zIndex={1}>
            <Divider mb="3" />
            <Box mb={4}>
                <Box mb="4" d="flex" justifyContent="space-between">
                    <Text fontWeight="bold">TOTAL</Text>
                    <Text fontWeight="bold">S/ {money.toFixed(2)}</Text>
                </Box>
                {table_id &&
                    (editOrderTable || !hasOrderTable) &&
                    !editOrderTable && (
                        <ModalPin
                            title="Procesar pedido"
                            element={click => (
                                <Button
                                    variant="alo-green:lg-md"
                                    fontSize={{
                                        xl: 'lg',
                                        lg: 'sm'
                                    }}
                                    w="full"
                                    leftIcon={
                                        <CashIcon fill="white" fontSize={32} />
                                    }
                                    onClick={
                                        !isLogged && selectedItems.length > 0 //mozo logeado si pasa
                                            ? click //lanza modal pin
                                            : () => confirmHandler() //envia a calcular si pasa directo o selecciona mozo
                                    }
                                    isDisabled={
                                        !storagePage.isOpenCashbox && !table_id
                                    }
                                >
                                    Confirmar pedido (S/ {money.toFixed(2)})
                                </Button>
                            )}
                            onDone={confirmHandler}
                        />
                    )}
                {table_id &&
                    (editOrderTable || !hasOrderTable) &&
                    editOrderTable && (
                        <DialogEditOrder
                            element={click => (
                                <ModalPin
                                    title="Pin de autorización"
                                    element={clickPin => (
                                        <Button
                                            variant="alo-green:lg-md"
                                            fontSize={{
                                                xl: 'lg',
                                                lg: 'sm'
                                            }}
                                            w="full"
                                            leftIcon={
                                                <CashIcon
                                                    fill="white"
                                                    fontSize={32}
                                                />
                                            }
                                            onClick={() =>
                                                evaluateUpdate(click, clickPin)
                                            }
                                            isDisabled={
                                                !storagePage.isOpenCashbox &&
                                                !table_id
                                            }
                                        >
                                            Actualizar pedido (S/{' '}
                                            {money.toFixed(2)})
                                        </Button>
                                    )}
                                    onDone={click}
                                    adminPin
                                />
                            )}
                            update={update}
                        />
                    )}
                <ModalErrorSend
                    message={dataModal.message}
                    receipts={dataModal.receipts}
                    isOpen={openModal}
                    setOpen={setOpenModal}
                />
                {!table_id && !isPreOrder && (
                    <Button
                        variant="alo-green:lg-md"
                        fontSize={{
                            xl: 'lg',
                            lg: 'sm'
                        }}
                        w="full"
                        leftIcon={<CashIcon fill="white" fontSize={32} />}
                        onClick={() => confirmHandler()}
                        isDisabled={!storagePage.isOpenCashbox && !table_id}
                    >
                        Ir a pagar (S/ {money.toFixed(2)})
                    </Button>
                )}
                {!table_id && isPreOrder && (
                    <Button
                        variant="alo-green:lg-md"
                        fontSize={{
                            xl: 'lg',
                            lg: 'sm'
                        }}
                        w="full"
                        leftIcon={<CashIcon fill="white" fontSize={32} />}
                        onClick={() => confirmPreOrderHandler()}
                    >
                        Guardar pre-pedido (S/ {money.toFixed(2)})
                    </Button>
                )}
                {hasOrderTable && !editOrderTable && (
                    <Flex gridGap={'5px'}>
                        <Button
                            variant="alo-blue:lg-md"
                            fontSize="sm"
                            isLoading={isPrecountLoading}
                            leftIcon={<Printer fill="white" fontSize={20} />}
                            onClick={precountHandler}
                            flexGrow={1}
                        >
                            Precuenta
                        </Button>
                        {isLogged && !isWaiter() && (
                            <Button
                                variant="alo-green:lg-md"
                                fontSize="sm"
                                isDisabled={!isOpenCashbox}
                                leftIcon={
                                    <CashIcon fill="white" fontSize={20} />
                                }
                                onClick={payHandler}
                                flexGrow={1}
                            >
                                Pagar
                            </Button>
                        )}
                        {isLogged && userCanCancelItems() && (
                            <DialogCancel
                                order_id={table?.order.id as number}
                                element={click => (
                                    <ModalPin
                                        title="Pin de autorización"
                                        element={clickPin => (
                                            <Button
                                                variant="alo-red:lg-md"
                                                fontSize="sm"
                                                leftIcon={
                                                    <Block
                                                        fill="white"
                                                        fontSize={20}
                                                    />
                                                }
                                                onClick={
                                                    need_admin_pin
                                                        ? clickPin
                                                        : click
                                                }
                                                flexGrow={1}
                                            >
                                                Anular
                                            </Button>
                                        )}
                                        onDone={click}
                                        adminPin
                                    />
                                )}
                            />
                        )}
                    </Flex>
                )}
            </Box>
        </Box>
    )
}
