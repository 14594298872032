import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import Modifier from '../../../models/modifier'
import { formModifier, listModifier } from '../storage'
import FormResource from './FormModifier'
import {
    getInformationEditModifier,
    ProductionItemModifier,
    SEPARATOR
} from './util'

export default function useModifier(modifier?: Modifier) {
    const { validation, observer, submit } = useSubmit(formModifier, {
        path: modifier ? `/api/modifiers/${modifier.id}` : '/api/modifiers',
        method: modifier ? 'put' : 'post',
        done: () => listModifier.load()
    })

    const toast = useToast()

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<any>(f => ({
        name: f.name,
        ...(f?.id
            ? {
                  ...getInformationEditModifier(
                      f?.production_items ?? [],
                      f.initial_production_items ?? []
                  )
              }
            : {
                  production_items:
                      f.production_items?.map((productionItem: any) => {
                          return {
                              quantity: Number(productionItem.quantity),
                              magnitude_id: Number(productionItem.magnitude_id),
                              production_item_id: Number(
                                  productionItem.production_item.id
                              )
                          }
                      }) ?? []
              })
    }))

    const validationSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev?.preventDefault()
        const production_items: ProductionItemModifier[] =
            formModifier.store().production_items ?? []
        if (production_items?.length > 0) {
            let error = ''

            for (const productionItem of production_items) {
                if (
                    productionItem.magnitude_id === '' &&
                    Number(productionItem.quantity) <= 0
                ) {
                    error =
                        error +
                        'Debe seleccionar una unidad y la cantidad debe ser mayor a cero' +
                        SEPARATOR

                    continue
                }

                if (productionItem.magnitude_id === '') {
                    error = error + 'Debe seleccionar una unidad' + SEPARATOR

                    continue
                }

                if (Number(productionItem.quantity) <= 0) {
                    error =
                        error + 'La cantidad debe ser mayor a cero' + SEPARATOR

                    continue
                }
                error = error + SEPARATOR
            }

            const errorSlice = error.slice(0, -1)
            const _errors = errorSlice.split(SEPARATOR)
            const filterErrors = _errors.filter(d => d !== '')

            if (filterErrors.length > 0) {
                formModifier.setHelpers({
                    production_items: errorSlice
                })
                return
            }
        }
        submit(ev)
    }

    async function openModal() {
        if (modifier) {
            const { data, error } = await get(`/api/modifiers/${modifier.id}`)

            if (data) {
                const _modifier: any = data.result
                const _production_items =
                    _modifier?.recipe?.recipe_inputs?.map((_data: any) => {
                        return {
                            production_item: _data.production_item,
                            quantity: _data.quantity,
                            magnitude_id: _data.magnitude_id
                        }
                    }) ?? []
                formModifier.store({
                    id: _modifier.id.toString() || '',
                    name: _modifier.name,
                    production_items: _production_items,
                    initial_production_items: _production_items
                })
                formModifier.init(formModifier.store)
            }

            if (error) {
                toast({
                    title:
                        error.message ||
                        'Lo sentimos, ocurrió un error al recuperar la información , vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                return
            }
        } else {
            formModifier.init()
        }
        modal(FormResource, {
            title: modifier ? 'Editar Modificador' : 'Nuevo Modificador',
            onSubmit: validationSubmit,
            size: 'xl'
        })
    }

    return openModal
}
