import { useHistory } from 'react-router-dom'
import useDelivery from '../../../hooks/useDelivery'
import useRunway from '../../../hooks/useRunway'

export default function useGoToPay() {
    const { push } = useHistory()
    const { products, delivery } = useDelivery()

    const { saveSelectedItems } = useRunway()

    function go(orderId: number) {
        const itemsForToPay = products.map(group => ({
            category_item_id: group.category_item_id,
            category_item_name: group.category_item_name,
            items: group.items.map(item => ({
                id: item.itemId,
                order_item_id: item.orderItemId,
                name: item.name,
                quantity: item.quantity,
                quantity_recorded: item.quantity,
                price: item.price,
                user_price_edition: item.price,
                description: item.description,
                feature_settings: [],
                modifier_groups: []
            }))
        }))

        saveSelectedItems(itemsForToPay)
        push(
            `/orders/${orderId}/confirm?delivery_id=${delivery?.id}&client_id=${delivery?.client_id}`
        )
    }

    return {
        go
    }
}
