import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import Description from '../../../../../components/Icon/Description'
import colors from '../../../../../config/theme/colors'
import Close from '../../../../../components/Icon/Close'
import { MenuItem as MenuItemInterface } from '../../../../../context/MenuContext'
import ModalEditItem from '../../../modals/ModalEditItem'
import { useState } from 'react'
import useOrderByTable from '../../../hooks/useOrderByTable'
import useManagerPermission from '../../../hooks/useManagerPermission'
import ModalModifiersItem from 'components/ModalModifiersItem'
import useMenu from 'hooks/useMenu'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'

interface MenuItemProps {
    menuItem: MenuItemInterface
    onDelete: () => void
    onClick: () => void
}

export default function MenuItem({ menuItem, onDelete }: MenuItemProps) {
    const { isModeEdition } = useOrderByTable()
    const { updateItemWithModifiers } = useMenu()
    const [isOpen, setIsOpen] = useState(false)
    const { canWaiterOrDealarEdit } = useManagerPermission()
    function clickHandler(ev: any) {
        const target = ev.target as HTMLDivElement
        if (target.classList.contains('close')) return
        setIsOpen(true)
    }

    const hasModifiers =
        menuItem.modifierGroups && menuItem.modifierGroups?.length > 0

    const price = hasModifiers ? menuItem.total_price ?? 0 : menuItem.price

    return (
        <>
            <ModalEditItem
                isOpen={isOpen}
                data={{
                    hash: menuItem.hash,
                    itemId: menuItem.itemId,
                    categoryItemId: menuItem.categoryItemId,
                    name: menuItem.name,
                    quantity: menuItem.quantity,
                    description: menuItem.description,
                    unitPrice: menuItem.price,
                    modifierGroups: menuItem.modifierGroups
                }}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
            />
            <>
                <ModalModifiersItem
                    itemId={menuItem.itemId}
                    onDone={(
                        _featuresSelected,
                        quantity,
                        modifierGroupsSelected,
                        _item_price_plus,
                        _idGenerated,
                        description
                    ) => {
                        updateItemWithModifiers(
                            menuItem.hash,
                            quantity,
                            modifierGroupsSelected,
                            description ?? ''
                        )
                    }}
                    quantity={menuItem.quantity}
                    edition={menuItem.modifierGroups}
                    description={menuItem.description ?? ''}
                    element={click => (
                        <>
                            <Flex
                                onClick={ev => {
                                    if (
                                        (hasModifiers ||
                                            Boolean(menuItem.hasModifiers)) &&
                                        !Boolean(menuItem.isForEdition)
                                    ) {
                                        click()
                                        return
                                    }

                                    clickHandler(ev)
                                }}
                                justifyContent="space-between"
                                align="center"
                                role="group"
                                fontSize="lg"
                                px={{ base: 3, lg: 6 }}
                                py={0.5}
                                cursor="pointer"
                                pointerEvents={isModeEdition ? 'auto' : 'none'}
                                w="full"
                                _hover={{
                                    bg: 'blackAlpha.50'
                                }}
                            >
                                <Text
                                    textAlign="left"
                                    fontSize={{
                                        xl: '16px',
                                        lg: '14px'
                                    }}
                                >
                                    <Box
                                        minW="6"
                                        as="span"
                                        display="inline-block"
                                    >
                                        {menuItem.quantity}
                                    </Box>
                                    {menuItem.name}
                                    {menuItem.description && (
                                        <Icon
                                            as={Description}
                                            fill={colors['gray-2']}
                                            fontSize="18px"
                                            ml={2}
                                        />
                                    )}
                                </Text>
                                <Flex align="center">
                                    <Text
                                        textAlign="right"
                                        fontSize={{ xl: '16px', lg: '14px' }}
                                    >
                                        S/
                                        {(price * menuItem.quantity).toFixed(2)}
                                    </Text>
                                    {canWaiterOrDealarEdit(menuItem.itemId) && (
                                        <IconButton
                                            icon={
                                                <Close
                                                    fill={colors.secondary}
                                                />
                                            }
                                            aria-label="Eliminar"
                                            colorScheme="gray"
                                            size="sm"
                                            fontSize={{
                                                xl: 18,
                                                lg: 15
                                            }}
                                            className="close"
                                            cursor="pointer"
                                            ml={2}
                                            mr={{
                                                base: 0,
                                                lg: -4
                                            }}
                                            onClick={onDelete}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                            <>
                                {menuItem.modifierGroups &&
                                    menuItem.modifierGroups.map((fes, _i) => (
                                        <Box
                                            key={_i}
                                            display={'flex'}
                                            gap={2}
                                            fontSize="14px"
                                            pointerEvents={
                                                isModeEdition ? 'auto' : 'none'
                                            }
                                            onClick={ev => {
                                                if (
                                                    (hasModifiers ||
                                                        Boolean(
                                                            menuItem.hasModifiers
                                                        )) &&
                                                    !Boolean(
                                                        menuItem.isForEdition
                                                    )
                                                ) {
                                                    click()
                                                    return
                                                }

                                                clickHandler(ev)
                                            }}
                                            pl={6}
                                        >
                                            <Text pl={6} pr={5}>
                                                <TextModifierGroupModifiers
                                                    modifierGroup={fes}
                                                />
                                            </Text>
                                        </Box>
                                    ))}
                            </>
                        </>
                    )}
                />
            </>
        </>
    )
}
