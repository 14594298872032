import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listModifier } from '../storage'
import DeleteSupply from './DeleteModifier'
import Edition from './Edition'

export default function Modifier() {
    return (
        <List
            control={listModifier}
            tbody={(modifier, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{modifier.name}</Td>
                    <Td textAlign="center">
                        <Edition modifier={modifier} />
                        <DeleteSupply modifier={modifier} />
                    </Td>
                </Tr>
            )}
        />
    )
}
