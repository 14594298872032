import React from 'react'
import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Grid,
    SelectProps,
    Select,
    Text,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Icon
} from '@chakra-ui/react'
import ErrorHelper from '../../../../components/ErrorHelper'
import FileField from '../../../../components/FileField'
import { FileFieldProps } from '../../../../components/FileField/types'
import NumberField from '../../../../components/NumberField'
import { useGet } from '../../../../hooks/useHttp'
import CategoryItem from '../../../../models/category_item'
import { ItemCombo, formCombo } from '../../storage'
import FieldSearchItem from './FieldSearchItem'
import Item from '../../../../models/item'
import CustomNumberInput from '../../../InventoryManagement/components/CustomNumberInput'
import Close from '../../../../components/Icon/Close'
import colors from '../../../../config/theme/colors'
import onlyNumber from '../../../../utilities/onlyNumber'

export default function FormCategory() {
    return (
        <>
            <>
                <Text mb={3} fontWeight="bold" fontSize="20px">
                    Información General
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <Name />
                    <Price />
                </Grid>
                <Category />
                <Image />
            </>
            <AssociatedProducts />
        </>
    )
}

function Name() {
    const { props, helper, error } = formCombo.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del producto"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Price() {
    const { props, helper } = formCombo.useField<InputProps>('price')
    return (
        <FormControl mb={4}>
            <FormLabel>Precio (S/)</FormLabel>
            <NumberField {...props} placeholder="Ingresar el precio" />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Category() {
    const { data } = useGet<CategoryItem[]>('/api/category_items?sort=name', [])
    const { props, helper } = formCombo.useField<SelectProps>('category_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Categoría</FormLabel>
            <Select {...props} placeholder="Seleccionar categoría del producto">
                {data.result.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Image() {
    const { props, helper } = formCombo.useField<FileFieldProps>('image_url')
    return (
        <FormControl mb={4}>
            <FormLabel>Foto del producto (opcional)</FormLabel>
            <FileField {...props} />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function AssociatedProducts() {
    const { value: products, setValue, helper } = formCombo.useValue('items')

    const handleAddItem = (data: Item | null) => {
        if (!data) return
        const newsProducts = [...products]
        const findProductIndex = newsProducts?.findIndex(
            (_data: any) => _data.id === data.id
        )
        if (findProductIndex === -1) {
            newsProducts.push({
                ...data,
                quantity: 1
            })
        } else {
            const findProduct = newsProducts[findProductIndex]
            newsProducts[findProductIndex] = {
                ...data,
                quantity: findProduct.quantity + 1
            }
        }

        setValue([...newsProducts])
    }

    const handleDelete = (index: number) => {
        const newsProducts = []
        for (let i = 0; i < products.length; i++) {
            if (i !== index) {
                newsProducts.push(products[i])
            }
        }
        setValue([...newsProducts])
    }

    const handleChange = (value: string, index: number, name: string) => {
        const newsProducts: ItemCombo[] = []
        for (let i = 0; i < products.length; i++) {
            if (i === index) {
                if (name === 'quantity') {
                    const v = onlyNumber(value, {
                        decimal: 0,
                        min: 1,
                        negative: false,
                        max: Infinity
                    })
                    newsProducts.push({
                        ...products[i],
                        quantity: Number(v)
                    })
                } else {
                    newsProducts.push({
                        ...products[i],
                        [name]: value
                    })
                }
            } else {
                newsProducts.push(products[i])
            }
        }
        setValue([...newsProducts])
    }
    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Productos asociados
            </Text>
            <FormControl mb={4}>
                <FormLabel>Buscar Producto</FormLabel>
                <ErrorHelper helper={helper} />
                <FieldSearchItem onSelect={handleAddItem} />
            </FormControl>
            <TableContainer>
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Producto</Th>
                            <Th whiteSpace={'normal'}>Precio</Th>
                            <Th w="56">Cantidad</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {products.map((product, index) => (
                            <Tr p={2} key={product.id}>
                                <Td>{product.name}</Td>
                                <Td>S/{product.price}</Td>

                                <Td w="56">
                                    <CustomNumberInput
                                        valueForm={product.quantity}
                                        handleChange={handleChange}
                                        name="quantity"
                                        placeholder="Ingresar cant."
                                        index={index}
                                        min={1}
                                    />
                                </Td>
                                <Td>
                                    <Icon
                                        onClick={() => handleDelete(index)}
                                        cursor="pointer"
                                        fill={colors.error}
                                        as={Close}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    {products.length === 0 && (
                        <TableCaption>
                            No existen productos asociados
                        </TableCaption>
                    )}
                </Table>
            </TableContainer>
        </>
    )
}
