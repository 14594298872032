import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import { formModifierGroup, listModifierGroup } from '../storage'
import FormModifierGroup from './FormModifierGroup'
import { END_POINTS } from '../constants'
import ModifierGroup from '../../../models/modifier_group'

export default function useModifierGroup(modifierGroup?: ModifierGroup) {
    const toast = useToast()
    const { validation, observer, submit } = useSubmit(formModifierGroup, {
        path: modifierGroup
            ? `/api${END_POINTS.MODIFIER_GROUP}/${modifierGroup.id}`
            : `/api${END_POINTS.MODIFIER_GROUP}`,
        method: modifierGroup ? 'put' : 'post',
        done: () => listModifierGroup.load(),
        error: e => {
            toast({
                title: e.message,
                position: 'top',
                status: 'error'
            })
        }
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        modifiers: f.modifiers.map((sf: any) => {
            return {
                name: sf.modifier.name,
                price: Number(sf.price),
                modifier_id: Number(sf.modifier.id)
            }
        })
    }))

    function openModal() {
        if (modifierGroup) {
            formModifierGroup.store({
                id: modifierGroup.id.toString(),
                name: modifierGroup.name,
                modifiers: modifierGroup.modifier_group_modifiers ?? []
            })
            formModifierGroup.init(formModifierGroup.store)
        } else {
            formModifierGroup.init()
        }
        modal(FormModifierGroup, {
            title: modifierGroup
                ? 'Editar Grupo de Modificador'
                : 'Nuevo Grupo de Modificador',
            onSubmit: submit,
            size: 'xl'
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    modifiers: any[]
}
