import { Button, Spinner } from '@chakra-ui/react'
import { BoxCategoryProps } from './types'

export default function BoxCategory({
    name,
    isActive,
    onClick,
    isLoading
}: BoxCategoryProps) {
    const isLoadingCategory = isActive && isLoading
    return (
        <Button
            minH={{ base: 'auto', lg: '60px' }}
            borderRadius="lg"
            whiteSpace={{ base: 'nowrap', lg: 'normal' }}
            w="full"
            size="sm"
            textAlign="center"
            variant={isActive ? 'solid' : 'outline'}
            colorScheme="whiteAlpha"
            fontWeight="normal"
            bg={{
                base: isActive ? 'dark' : 'transparent',
                lg: isActive ? 'white' : 'transparent'
            }}
            pointerEvents={isActive ? 'none' : 'auto'}
            color={{
                base: isActive ? 'white' : 'dark',
                lg: isActive ? 'dark' : 'white'
            }}
            borderWidth={{
                base: 1,
                md: 2
            }}
            onClick={onClick}
            transition=".2s ease"
        >
            {isLoadingCategory ? <Spinner /> : <>{name}</>}
        </Button>
    )
}
