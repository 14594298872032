import { Box, Heading, Icon } from '@chakra-ui/react'
import { Section1Props } from '../types'
import React from 'react'
import Item from './Item'
import Badge from './Badge'
import { isCompleteQuantityModifiers, isDisabledAdd } from '../utils'
import TextSelectQuantity from './TextSelectQuantity'
import ArrowDown from 'components/Icon/ArrowDown'
import ArrowUp from 'components/Icon/ArrowUp'

export default function Section1({
    modifierGroupItems,
    addQuantityModifierGroupItem,
    modifierGroupSelected,
    requiredQuantityModifierGroup
}: Section1Props) {
    const [opens, setOpens] = React.useState<{
        [id: number]: boolean
    }>({})

    return (
        <Box
            w={{
                base: '100%'
            }}
            p="8"
        >
            {modifierGroupItems?.map((itemF, i) => {
                const open = opens[itemF.id] ?? i === 0 ? true : false
                const requiredQuantity =
                    requiredQuantityModifierGroup[itemF.modifier_group_id] ??
                    false

                const isCompleteQuantity = isCompleteQuantityModifiers(
                    itemF,
                    modifierGroupSelected.find(
                        m => m.modifier_group_id === itemF.modifier_group.id
                    )
                )

                return (
                    <Box key={i} mb={4}>
                        <Box
                            onClick={() =>
                                setOpens({
                                    ...opens,
                                    [itemF.id]: !open
                                })
                            }
                            display={'flex'}
                            justifyContent={'space-between'}
                            gap={4}
                            mb={3}
                            borderBottom={'2px solid #F6F6F6'}
                            py={1}
                        >
                            <Box>
                                <Heading fontSize="lg">
                                    {itemF?.modifier_group?.name}
                                </Heading>

                                <TextSelectQuantity
                                    isRequiredQuantity={
                                        itemF.is_required &&
                                        requiredQuantity &&
                                        !isCompleteQuantity
                                    }
                                    modifierGroupITem={itemF}
                                />
                            </Box>

                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'start'}
                                gap={2}
                            >
                                {itemF.is_required && (
                                    <Badge isComplete={isCompleteQuantity} />
                                )}

                                <Icon
                                    cursor="pointer"
                                    fontSize="3xl"
                                    as={open ? ArrowDown : ArrowUp}
                                />
                            </Box>
                        </Box>

                        {open && (
                            <>
                                <Box>
                                    {itemF?.modifier_group?.modifier_group_modifiers?.map(
                                        data => {
                                            const isDisabledAddItem =
                                                isDisabledAdd(
                                                    modifierGroupSelected,
                                                    itemF,
                                                    data
                                                )

                                            return (
                                                <Item
                                                    key={data.id}
                                                    modifierGroupModifier={data}
                                                    addQuantityModifierGroupItem={
                                                        addQuantityModifierGroupItem
                                                    }
                                                    modifierGroupSelected={
                                                        modifierGroupSelected
                                                    }
                                                    modifierGroupItem={itemF}
                                                    disabled={isDisabledAddItem}
                                                />
                                            )
                                        }
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                )
            })}
        </Box>
    )
}
