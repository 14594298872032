import { ChangeEvent, useState } from 'react'
import {
    Input,
    Select,
    Switch,
    Text,
    Box,
    Icon,
    Button
} from '@chakra-ui/react'
import { formItem } from '../../storage'
import {
    CHOICE_TYPES,
    choice_types,
    QUANTITY_CHOICE_TYPES,
    quantity_choice_types
} from '../util'
import ModalModifierGroups from './ModalModifierGroups'
import ModifierGroup from '../../../../models/modifier_group'
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DraggableProvided
} from 'react-beautiful-dnd'
import Apps from '../../../../components/Icon/Apps'
import colors from '../../../../config/theme/colors'
import Close from '../../../../components/Icon/Close'
import onlyNumber from '../../../../utilities/onlyNumber'

export default function ModifierGroups() {
    const [open, setOpen] = useState<boolean>(false)
    const { value, setValue, helper } = formItem.useValue('modifier_groups')

    const errors = helper !== '' ? JSON.parse(helper ?? '{}') : {}

    const handleChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const name = e.target.name
        if (name === 'max_quantity' || name === 'min_quantity') {
            const _value = onlyNumber(e.target.value, {
                decimal: 0,
                min: 0,
                max: Infinity,
                negative: false
            })

            e.target.value = _value
        }

        value[index][name] = e.target.value
        setValue([...value])
    }

    const handleDelete = (index: number) => {
        const newFeatures = []
        for (let i = 0; i < value.length; i++) {
            if (i !== index) {
                newFeatures.push(value[i])
            }
        }
        setValue([...newFeatures])
    }

    const handleAddModifierGroups = (data: ModifierGroup[]) => {
        const newsProductionItems = []

        for (const productionItem of data) {
            const findProductionItem = value?.find(
                (_data: any) => _data?.modifier_group?.id === productionItem?.id
            )
            if (!findProductionItem) {
                newsProductionItems.push({
                    modifier_group: productionItem,
                    min_quantity: 0,
                    max_quantity: 0,
                    choice_type: '',
                    quantity_choice_type: ''
                })
            }
        }

        setValue([...value, ...newsProductionItems])
        setOpen(false)
    }

    const handleChangeCanRepeat = (index: number, checked: boolean) => {
        value[index]['can_repeat'] = checked
        setValue([...value])
    }

    const modifierGroups = value.map((data: any) => data.modifier_group)

    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Grupo de Modificadores
            </Text>
            {value?.length > 0 && <TitlesModifierGroups />}
            <Rows
                data={value}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setData={setValue}
                handleChangeCanRepeat={handleChangeCanRepeat}
                errors={errors}
            />
            <Box>
                <Button
                    background="#FFF"
                    height="40px"
                    fontSize="14px"
                    color="#4E6BD3"
                    border="1px solid #C5C9D0"
                    borderRadius="18px"
                    fontWeight={500}
                    width="114px"
                    type="button"
                    onClick={() => setOpen(true)}
                >
                    Agregar
                </Button>
            </Box>
            <ModalModifierGroups
                open={open}
                onClose={() => setOpen(false)}
                handleAddModifierGroups={handleAddModifierGroups}
                title="Asociar artículo(s) de inventario"
                size="3xl"
                selectedModifierGroups={modifierGroups ?? []}
            />
        </>
    )
}

function Rows({
    data,
    handleChange,
    handleDelete,
    setData,
    handleChangeCanRepeat,
    errors
}: {
    data: any[]
    handleChange: any
    handleDelete: any
    setData: (value: any[]) => void
    handleChangeCanRepeat: any
    errors: {
        [key: string]: string
    }
}) {
    const [isDisabledDrop, setDisabledDrop] = useState(false)

    function dragEndHandler(result: DropResult) {
        if (!result.destination) return
        setDisabledDrop(true)

        const originIndex = result.source.index
        const destinationIndex = result.destination.index

        if (originIndex === destinationIndex) return

        const toTop = originIndex > destinationIndex

        const dataOrigin = data[originIndex]

        const newCategoryItems = []

        for (let i = 0; i < data.length; i++) {
            const categoryItem = data[i]
            if (i === originIndex) continue
            if (toTop) {
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
                newCategoryItems.push(categoryItem)
            } else {
                newCategoryItems.push(categoryItem)
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
            }
        }

        setData([...newCategoryItems])

        setDisabledDrop(false)
    }

    return (
        <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable-1">
                {provided => (
                    <>
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {data.map((modifier, i) => (
                                <Draggable
                                    key={modifier.modifier_group?.id}
                                    draggableId={modifier.modifier_group?.id?.toString()}
                                    index={i}
                                >
                                    {_provided => (
                                        <>
                                            <ModifierGroupRow
                                                handleChange={handleChange}
                                                handleDelete={handleDelete}
                                                index={i}
                                                modifierGroup={modifier}
                                                isDisabledDrop={isDisabledDrop}
                                                provided={_provided}
                                                handleChangeCanRepeat={
                                                    handleChangeCanRepeat
                                                }
                                                error={
                                                    errors[
                                                        modifier?.modifier_group
                                                            ?.id
                                                    ] ?? ''
                                                }
                                            />
                                        </>
                                    )}
                                </Draggable>
                            ))}
                        </Box>
                        {provided.placeholder}
                    </>
                )}
            </Droppable>
        </DragDropContext>
    )
}

function ModifierGroupRow({
    modifierGroup,
    handleChange,
    index,
    handleDelete,
    isDisabledDrop,
    provided,
    handleChangeCanRepeat,
    error = ''
}: {
    modifierGroup: any
    handleChange: any
    index: number
    handleDelete: any
    isDisabledDrop: boolean
    provided: DraggableProvided
    handleChangeCanRepeat: any
    error?: string
}) {
    return (
        <Box
            marginBottom="2"
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <Box
                display="flex"
                border="1px solid #C4C4C4"
                borderRadius="10px"
                paddingX="2"
            >
                <Box
                    paddingX="2"
                    paddingY="2"
                    width={'5%'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    {...provided.dragHandleProps}
                    {...(isDisabledDrop
                        ? {
                              pointerEvents: 'none'
                          }
                        : {})}
                >
                    <Icon fill={colors['gray-3']} as={Apps} fontSize="20" />
                </Box>
                <Box
                    width={{
                        base: '25%'
                    }}
                    paddingX="2"
                    paddingY="2"
                    borderRight="1px solid #C4C4C4"
                >
                    <Input
                        defaultValue={modifierGroup.modifier_group.name}
                        placeholder=""
                        maxLength={180}
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="name"
                    />
                </Box>
                <Box
                    width={'15%'}
                    paddingX="2"
                    paddingY="2"
                    borderRight="1px solid #C4C4C4"
                >
                    <Select
                        value={modifierGroup.choice_type}
                        placeholder="Seleccione"
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="choice_type"
                        onChange={e => handleChange(index, e)}
                    >
                        {choice_types.map(choiceType => (
                            <option
                                key={choiceType.value}
                                value={choiceType.value}
                            >
                                {choiceType.label}
                            </option>
                        ))}
                    </Select>
                </Box>

                <Box
                    width={'15%'}
                    paddingX="2"
                    paddingY="2"
                    borderRight="1px solid #C4C4C4"
                    {...(modifierGroup.choice_type !==
                        CHOICE_TYPES.MANDATORY && {
                        pointerEvents: 'none',
                        opacity: '0.3'
                    })}
                >
                    <Select
                        value={modifierGroup.quantity_choice_type}
                        placeholder="Seleccione"
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="quantity_choice_type"
                        onChange={e => handleChange(index, e)}
                    >
                        {quantity_choice_types.map(qchoiceType => (
                            <option
                                key={qchoiceType.value}
                                value={qchoiceType.value}
                            >
                                {qchoiceType.label}
                            </option>
                        ))}
                    </Select>
                </Box>

                <Box
                    paddingX="2"
                    borderRight="1px solid #C4C4C4"
                    paddingY="2"
                    width={{
                        base: '10%'
                    }}
                    {...((modifierGroup.choice_type !==
                        CHOICE_TYPES.MANDATORY ||
                        modifierGroup.quantity_choice_type !==
                            QUANTITY_CHOICE_TYPES.RANGE) && {
                        pointerEvents: 'none',
                        opacity: '0.3'
                    })}
                >
                    <Input
                        value={modifierGroup.min_quantity}
                        placeholder="Cantidad Mínima"
                        maxLength={180}
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="min_quantity"
                        onChange={e => handleChange(index, e)}
                    />
                </Box>
                <Box
                    paddingX="2"
                    borderRight="1px solid #C4C4C4"
                    paddingY="2"
                    width={{
                        base: '10%'
                    }}
                    {...(modifierGroup.choice_type === '' && {
                        pointerEvents: 'none',
                        opacity: '0.3'
                    })}
                >
                    <Input
                        value={modifierGroup.max_quantity}
                        placeholder="Cantidad Máxima"
                        maxLength={180}
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="max_quantity"
                        onChange={e => handleChange(index, e)}
                    />
                </Box>

                <Box
                    paddingX="2"
                    paddingY="2"
                    width={'15%'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRight="1px solid #C4C4C4"
                >
                    <Switch
                        isChecked={modifierGroup?.can_repeat ?? false}
                        onChange={e =>
                            handleChangeCanRepeat(index, e.target.checked)
                        }
                    />
                </Box>

                <Box
                    paddingX="2"
                    paddingY="2"
                    width={'5%'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon
                        onClick={() => handleDelete(index)}
                        fill={colors.error}
                        as={Close}
                        cursor="pointer"
                    />
                </Box>
            </Box>
            {error && (
                <Text fontSize={'14px'} color={colors.error}>
                    {error}
                </Text>
            )}
        </Box>
    )
}

function TitlesModifierGroups() {
    return (
        <Box display="flex">
            <Box width={'5%'}></Box>
            <Box width={'25%'} paddingX="4">
                <Text fontSize="12px" mb="2">
                    Nombre
                </Text>
            </Box>
            <Box paddingX="1" width={'15%'}>
                <Text fontSize="12px" mb="2">
                    Elección
                </Text>
            </Box>
            <Box paddingX="1" width={'15%'}>
                <Text fontSize="12px" mb="2">
                    Cant. a seleccionar
                </Text>
            </Box>
            <Box paddingX="2" width={'10%'}>
                <Text fontSize="12px" mb="2">
                    Cant. mínima
                </Text>
            </Box>
            <Box width={'10%'} paddingX="1">
                <Text fontSize="12px" mb="2">
                    Cant. máxima
                </Text>
            </Box>
            <Box width={'15%'} paddingX="1">
                <Text fontSize="12px" mb="2">
                    ¿ Se puede repetir su selección?
                </Text>
            </Box>
        </Box>
    )
}
