import {
    Box,
    Button,
    Flex,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    VStack
} from '@chakra-ui/react'
import Close from '../../../../components/Icon/Close'
import { useGet } from '../../../../hooks/useHttp'
import ProductionArea from '../../../../models/production_area'

interface PrintDestinationFieldProps {
    printerIds: number[]
    setPrinterIds: (printers: number[]) => void
}

export default function PrintDestinationField({
    printerIds,
    setPrinterIds
}: PrintDestinationFieldProps) {
    const { data } = useGet<ProductionArea[]>(
        '/api/production_areas?limit=100&page=1&order=asc&sort=id',
        []
    )

    const printers = data.result.filter(
        printer => !printerIds.includes(printer.id)
    )

    const printersSelected = data.result.filter(
        printer => !!printerIds.includes(printer.id)
    )

    function clickHandler(printerId: number) {
        setPrinterIds([...printerIds, printerId])
    }

    function clickRemove(printerId: number) {
        setPrinterIds(printerIds.filter(_printerId => _printerId !== printerId))
    }

    return (
        <Flex
            w="full"
            borderWidth="1px"
            borderRadius="lg"
            flexWrap="wrap"
            p={1}
            gap={1}
        >
            {printersSelected.map(printer => (
                <Flex
                    bg="gray.100"
                    py="6px"
                    px={3}
                    key={printer.id}
                    mx={1}
                    borderRadius="md"
                    fontSize="sm"
                    align="center"
                >
                    {printer.name}
                    <Icon
                        as={Close}
                        ml={2}
                        fontSize="xs"
                        fill="blackAlpha.700"
                        cursor="pointer"
                        onClick={() => clickRemove(printer.id)}
                    />
                </Flex>
            ))}
            <Popover isLazy>
                <PopoverTrigger>
                    <Button size="sm">+</Button>
                </PopoverTrigger>
                <PopoverContent maxW="48">
                    <PopoverBody p={0} boxShadow="lg">
                        <VStack>
                            {printers.map(printer => (
                                <Box
                                    cursor="pointer"
                                    py={1}
                                    px={4}
                                    onClick={() => clickHandler(printer.id)}
                                    w="full"
                                    _hover={{
                                        bg: 'blackAlpha.100'
                                    }}
                                    key={printer.id}
                                >
                                    {printer.name}
                                </Box>
                            ))}
                            {printers.length === 0 && (
                                <Box
                                    py={1}
                                    px={4}
                                    w="full"
                                    fontSize="sm"
                                    color="blackAlpha.700"
                                    textAlign="center"
                                >
                                    Sin resultados
                                </Box>
                            )}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Flex>
    )
}
