import { Box, Heading } from '@chakra-ui/react'
import { Fragment } from 'react'
import useRunway from '../../../hooks/useRunway'
import { SelectedItem } from '../../../types/runway_state'
import Item from './Item'

export default function ListItems() {
    const { selectedItems } = useRunway()

    function getPrice(item: SelectedItem['items'][number]) {
        let restCost = 0

        if (item.modifier_groups) {
            restCost = item.modifier_groups.reduce(
                (c, d) =>
                    c +
                    d.modifier_group_modifiers.reduce(
                        (e, f) => e + f.price * f.quantity,
                        0
                    ),
                0
            )
        }
        return (item.price + restCost) * item.quantity
    }

    return (
        <Box
            // maxH={{
            //     xl: '500px',
            //     lg: '300px'
            // }}
            maxH="calc(100vh - 450px)"
            overflowY="auto"
        >
            {selectedItems.map(selected => (
                <Fragment key={selected.category_item_id}>
                    <Heading size="sm" textTransform="uppercase" mb="3" px="8">
                        {selected.category_item_name}
                    </Heading>
                    <Box
                        maxH="500px"
                        overflowY="auto"
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        px="8"
                        mb={2}
                    >
                        {selected.items.map((item, i) => (
                            <Item
                                quantity={item.quantity}
                                key={i}
                                description={item.name}
                                cost={`S/ ${getPrice(item).toFixed(2)}`}
                                modifierGroups={item?.modifier_groups ?? []}
                            />
                        ))}
                    </Box>
                </Fragment>
            ))}
        </Box>
    )
}
