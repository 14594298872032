import { Button, Flex, IconButton } from '@chakra-ui/react'
import { useState } from 'react'
import Search from '../../../../components/Icon/Search'
import colors from '../../../../config/theme/colors'
import CategoryItem from '../../../../models/category_item'
import Item from '../../../../models/item'
import { FeatureSelected } from '../../ContentModals/IceCream/types'
import useAddItems from '../../hooks/useAddItems'
import FieldQuantity from './FieldQuantity'
import FieldSearch from './FieldSearch'
import ModalModifiersItem from 'components/ModalModifiersItem'
import { ModifierGroup } from 'types/runway_state'

export default function Searcher() {
    type ItemData = Item & { category_item: CategoryItem }
    const [item, setItem] = useState<ItemData | null>(null)
    const [quantity, setQuantity] = useState('')
    const { addItem, addItemWithFeatures } = useAddItems(item as ItemData)

    function submitHandler() {
        if (!item || !quantity) return
        addItem(Number(quantity))
        setItem(null)
    }

    function modalDoneHandler(
        featureSelected: FeatureSelected[],
        _quantity: number,
        modifiersGroup: ModifierGroup[],
        description: string
    ) {
        addItemWithFeatures(
            featureSelected,
            _quantity,
            modifiersGroup,
            description
        )
        setItem(null)
    }

    return (
        <Flex
            px={{ base: 2, md: '4' }}
            pt={{
                base: 2,
                md: '3'
            }}
            pb={{ base: 2, md: '10px' }}
            bg="base-3"
            borderBottomWidth={{
                base: 0,
                md: '1px'
            }}
            borderRightWidth={{
                base: 0,
                md: '1px'
            }}
            borderColor="blackAlpha.50"
            position="relative"
        >
            <IconButton
                aria-label="Search database"
                icon={<Search fill={colors.dark} />}
                borderRightRadius={0}
                display={{
                    base: 'none',
                    lg: 'flex'
                }}
                pointerEvents="none"
            />
            <FieldSearch item={item} onSelect={setItem} />
            <FieldQuantity
                item={item}
                quantity={quantity}
                setQuantity={setQuantity}
            />
            <ModalModifiersItem
                itemId={item?.id as number}
                onDone={(
                    featuresSelected,
                    _quantity,
                    modifierGroupsSelected,
                    _item_price_plus_features_price,
                    _idGenerated,
                    description
                ) => {
                    modalDoneHandler(
                        featuresSelected,
                        _quantity,
                        modifierGroupsSelected,
                        description ?? ''
                    )
                }}
                quantity={Number(quantity || 1)}
                element={click => (
                    <Button
                        fontSize="xl"
                        colorScheme="blue"
                        isDisabled={
                            !item || quantity === '' || quantity === '0'
                        }
                        onClick={
                            item?.has_modifier_groups ? click : submitHandler
                        }
                    >
                        +
                    </Button>
                )}
            />
        </Flex>
    )
}
