import { useEffect } from 'react'
import {
    listCategory,
    listCombo,
    listDestiny,
    listItem,
    listModifier,
    listModifierGroup
} from './storage'

export default function useInitial() {
    useEffect(() => {
        Promise.all([
            listCategory.load(),
            listDestiny.load(),
            listItem.load(),
            listModifier.load(),
            listModifierGroup.load(),
            listCombo.load()
        ])
    }, [])
}
