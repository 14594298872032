import { Box, Fade, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import BoxItem from './components/BoxItem'
import useListColors from '../../hooks/useListColors'
import { useEffect, useState } from 'react'
import { get } from '../../lib/http'
import { ItemsGridProps } from './types'
import useMenu from '../../hooks/useMenu'
import { ItemData } from '../../context/MenuContext'
import ModalModifiersItem from 'components/ModalModifiersItem'

export default function ItemsGrid({
    categoryItemId,
    isLoading,
    setIsLoading
}: ItemsGridProps & {
    isLoading: boolean
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const { getQuantity, addItem, addItemWithModifiers } = useMenu()
    const { get: getColor } = useListColors()
    const [categoryItems, setCategoryItems] = useState<ItemData[]>([])

    async function load() {
        if (!categoryItemId) return
        setIsLoading(true)
        const result = await get<ItemData[]>(
            `/api/items?category_item_id=${categoryItemId || '0'}`
        )
        if (result.data) {
            setCategoryItems(result.data.result)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        load()
    }, [categoryItemId])

    return (
        <Box
            w="full"
            p={{
                base: 3,
                lg: '5'
            }}
            bg="white"
            maxH={{
                base: 'calc(100vh - 260px)',
                lg: 'calc(100vh - 60px)'
            }}
            overflow="auto"
        >
            {isLoading && (
                <Flex justify="center" pt={10}>
                    <Spinner />
                </Flex>
            )}
            {!isLoading && (
                <Fade in>
                    <SimpleGrid
                        spacing={[2, 2, 2, 3]}
                        minChildWidth={{
                            sm: '150px',
                            xl: '160px'
                        }}
                    >
                        {categoryItems.map((item, i) => (
                            <ModalModifiersItem
                                key={i}
                                itemId={item.id}
                                onDone={(
                                    _featureSelected,
                                    quantity,
                                    modifierGroupsSelected,
                                    _item_price_plus,
                                    _,
                                    description
                                ) => {
                                    addItemWithModifiers(
                                        item,
                                        quantity,
                                        modifierGroupsSelected,
                                        description
                                    )
                                }}
                                element={click => (
                                    <BoxItem
                                        bg={getColor(i)}
                                        quantity={getQuantity(item.id)}
                                        onClick={() => {
                                            if (item?.has_modifier_groups) {
                                                click()
                                            } else {
                                                addItem(item)
                                            }
                                        }}
                                        name={item.name}
                                        imageUrl={item.image_url}
                                        price={item.price}
                                        hasInventoryControl={
                                            item.has_inventory_control
                                        }
                                        stock={item.stock}
                                        hasStock={
                                            !(
                                                item.has_inventory_control &&
                                                item.stock <= 0
                                            ) // por consultar
                                        }
                                    />
                                )}
                            />
                        ))}
                    </SimpleGrid>
                </Fade>
            )}
            {categoryItems.length === 0 && !isLoading && (
                <Box textAlign="center" color="gray-3">
                    No existen resultados
                </Box>
            )}
        </Box>
    )
}
