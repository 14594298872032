import { useHistory, useLocation } from 'react-router'
import { Box, Link as ChakraLink, Icon as RectIcon } from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import { useEffect, useState } from 'react'

interface PropsLink {
    label: string
    path?: string
    Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
    subLinks?: Array<{
        label: string
        path: string
    }>
}

export default function Link({ path, label, Icon, subLinks }: PropsLink) {
    const { push } = useHistory()
    const { pathname } = useLocation()

    const [open, setOpen] = useState(false)

    function isCurrent() {
        if (path === pathname) return true
        const findSubLink = subLinks?.find(data => data.path === pathname)
        if (findSubLink) return true

        return false
    }

    const current = isCurrent()

    useEffect(() => {
        if (subLinks) {
            const findSubLink = subLinks.find(data => data.path === pathname)
            if (findSubLink) {
                setOpen(true)
            }
        }
    }, [])

    function clickHandler() {
        if (path) {
            push(path)
            return
        }
        if (subLinks) {
            setOpen(!open)
        }
    }

    return (
        <>
            <ChakraLink
                mb={2}
                mx={1}
                paddingX="5"
                display="flex"
                alignItems="center"
                //w="full"
                {...(current || open ? { bg: 'white', color: 'black' } : {})}
                h={10}
                onClick={() => clickHandler()}
                _hover={{ textDecoration: 'none' }}
                whiteSpace="nowrap"
                fontSize={{ base: '14px', xl: '16px' }}
                borderRadius={4}
            >
                {Icon && (
                    <RectIcon
                        as={Icon}
                        fill={current || open ? colors.black : colors.white}
                        mr={4}
                        fontSize={20}
                    />
                )}
                {label}
            </ChakraLink>
            {open && subLinks && (
                <Box display={'flex'} flexDirection={'column'}>
                    {subLinks.map((subLink, i) => (
                        <ChakraLink
                            key={i}
                            ml={14}
                            py={2}
                            onClick={() => push(subLink.path)}
                            fontSize={{ base: '14px', xl: '16px' }}
                            _hover={{ textDecoration: 'none' }}
                            borderLeftWidth={2}
                            //borderLeftColor={'white'}
                            px={4}
                            {...(subLink.path === useLocation().pathname
                                ? {
                                      fontWeight: 'bold',
                                      borderColor: 'white'
                                  }
                                : { borderColor: 'gray.600' })}
                        >
                            {subLink.label}
                        </ChakraLink>
                    ))}
                </Box>
            )}
        </>
    )
}
