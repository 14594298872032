import React, { useMemo } from 'react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalFooter,
    Text,
    Tr,
    Td,
    Input,
    Flex,
    Checkbox,
    FormControl
} from '@chakra-ui/react'
import List from '../../../../lib/list'
import Clean from '../../../../components/Clean'
import ErrorHelper from '../../../../components/ErrorHelper'
import ProductionItem from '../../../../models/production_item'
import ControlList from '../../../../lib/list/ControlList'

interface Props {
    open: boolean
    onClose: () => void
    handleAddProductionItems: (productionItems: ProductionItem[]) => void
    isSimple?: boolean
    listProductionItem: ControlList<ProductionItem>
    title: string
    selectedProductionItems?: ProductionItem[]
    size?:
        | 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | 'full'
}

export default function ModalProductionItems({
    open,
    onClose,
    isSimple = false,
    handleAddProductionItems,
    listProductionItem,
    title,
    selectedProductionItems = [],
    size = '2xl'
}: Props) {
    const finalRef = React.useRef<any>()
    const [helper, setHelper] = React.useState<string>('')
    const [checkedProductionItems, setCheckedProductionItems] = React.useState<{
        [id: number]: boolean
    }>({})
    const [productionItems, setProductionItems] = React.useState<
        ProductionItem[]
    >([])

    const ids_production_items = useMemo(
        () => selectedProductionItems.map(data => data.id),
        [selectedProductionItems]
    )

    React.useEffect(() => {
        listProductionItem.load()
    }, [])

    const isValid = () => {
        let values = {}
        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                values = {
                    ...values,
                    [key]: value
                }
            }
        }
        return Object.keys(values).length !== 0
    }

    const clearData = () => {
        setProductionItems([])
        setCheckedProductionItems({})
    }

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!isValid()) {
            setHelper('Debe seleccionar por lo menos uno')
            return
        }

        const _productionItems = []

        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                const findProductionItem = productionItems.find(
                    data => Number(data.id) === Number(key)
                )
                if (findProductionItem) {
                    _productionItems.push(findProductionItem)
                }
            }
        }
        handleAddProductionItems(_productionItems)
        clearData()
    }

    const handleChange = (e: any, productionItem: ProductionItem) => {
        const value = e.target.checked
        const id = productionItem.id
        if (value) {
            setProductionItems(prev => [...prev, productionItem])
        }
        if (isSimple) {
            const findProductionItem = productionItems.find(
                data => data.id === id
            )
            if (findProductionItem) {
                if (!value) {
                    setCheckedProductionItems({})
                    return
                }
            }
            setCheckedProductionItems({
                [id]: value
            })
        } else {
            setCheckedProductionItems(prev => {
                return {
                    ...prev,
                    [id]: value
                }
            })
        }
    }

    const handleClose = () => {
        clearData()
        onClose()
    }

    const { clear, ...props } = listProductionItem.useSearch()

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={open}
            onClose={handleClose}
            isCentered={true}
            size={size}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    <Text>{title}</Text>
                </ModalHeader>
                <form onSubmit={handleSubmit} noValidate>
                    <ModalBody pb={6}>
                        <FormControl>
                            <ErrorHelper helper={helper} />
                        </FormControl>
                        <Flex mb="4">
                            <Clean
                                width={'full'}
                                value={props.value}
                                onClick={clear}
                            >
                                <Input
                                    placeholder="🏸 Buscar por nombre"
                                    {...props}
                                />
                            </Clean>
                        </Flex>
                        <List
                            control={listProductionItem}
                            tablesize="sm"
                            tbody={(productionItem, e) => {
                                const selected = ids_production_items.includes(
                                    productionItem.id
                                )

                                const isChecked =
                                    selected ||
                                    (checkedProductionItems[
                                        productionItem.id
                                    ] ??
                                        false)

                                return (
                                    <Tr key={e}>
                                        <Td textAlign="center" width="28">
                                            <Checkbox
                                                size="lg"
                                                isChecked={isChecked}
                                                disabled={selected}
                                                onChange={event =>
                                                    handleChange(
                                                        event,
                                                        productionItem
                                                    )
                                                }
                                            />
                                        </Td>
                                        <Td textAlign="center">
                                            {productionItem.name}
                                        </Td>
                                    </Tr>
                                )
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={handleClose}
                            type="button"
                        >
                            Cancelar
                        </Button>
                        <Button variant="alo-green:lg-md" ml={3} type="submit">
                            Agregar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
