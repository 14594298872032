import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useCombo from './useCombo'
import Combo from '../../../models/combo'

export default function Edition({ combo }: { combo: Combo }) {
    const openModal = useCombo(combo)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
