import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { listModifierGroup } from '../storage'
import { END_POINTS } from '../constants'
import ModifierGroup from '../../../models/modifier_group'

export default function DeleteModifierGroup({
    modifierGroup
}: {
    modifierGroup: ModifierGroup
}) {
    return (
        <Delete
            title="Eliminar grupo modificador"
            path={`/api${END_POINTS.MODIFIER_GROUP}/${modifierGroup.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listModifierGroup.setQuery('page', 1)
                listModifierGroup.load()
            }}
        >
            ¿Esta seguro de eliminar el grupo modificador{' '}
            <b>{modifierGroup.name}</b>?
        </Delete>
    )
}
