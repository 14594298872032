import ProductionItem from '../../../models/production_item'

export interface ProductionItemModifier {
    quantity: string
    magnitude_id: string
    production_item: ProductionItem
}

export const SEPARATOR = ';'

export const getInformationEditModifier = (
    production_items: ProductionItemModifier[],
    initial_production_items: ProductionItemModifier[]
) => {
    const production_item_ids_to_delete = []
    const production_item_ids = production_items?.map(
        data => data.production_item.id
    )

    for (const production_item of initial_production_items) {
        const production_item_id = production_item.production_item.id
        if (!production_item_ids.includes(production_item_id)) {
            production_item_ids_to_delete.push(production_item_id)
        }
    }

    return {
        production_items_to_save:
            production_items?.map((productionItem: any) => {
                return {
                    quantity: Number(productionItem.quantity),
                    magnitude_id: Number(productionItem.magnitude_id),
                    production_item_id: Number(
                        productionItem.production_item.id
                    )
                }
            }) ?? [],
        production_item_ids_to_delete
    }
}
