import ModifierGroup, { ModifierGroupModifier } from 'models/modifier_group'
import { ModifierGroupSelected } from './types'
import ModifierGroupItem from 'models/modifier_group_item'
import ModifierOrder from 'models/modifier_order'
import Big from 'big.js'

const getModifierGroupModifier = (
    modifierGroupSelected: ModifierGroupSelected[],
    modifierGroup: ModifierGroup,
    modifierGroupModifier: ModifierGroupModifier
) => {
    const findModifierGroup = modifierGroupSelected.find(
        m => m.modifier_group_id === modifierGroup.id
    )

    if (!findModifierGroup) return undefined

    const findModifierGroupModifier =
        findModifierGroup.modifier_group_modifiers.find(
            mg => mg.id === modifierGroupModifier.id
        )

    return findModifierGroupModifier
}

export const getQuantity = (
    modifierGroupSelected: ModifierGroupSelected[],
    modifierGroup: ModifierGroup,
    modifierGroupModifier: ModifierGroupModifier
) => {
    const findModifierGroupModifier = getModifierGroupModifier(
        modifierGroupSelected,
        modifierGroup,
        modifierGroupModifier
    )

    return findModifierGroupModifier?.quantity ?? 0
}

export const isCheckedModifierGroupModifier = (
    modifierGroupSelected: ModifierGroupSelected[],
    modifierGroup: ModifierGroup,
    modifierGroupModifier: ModifierGroupModifier,
    canRepeat: boolean
) => {
    if (canRepeat) return false

    const findModifierGroup = modifierGroupSelected.find(
        m => m.modifier_group_id === modifierGroup.id
    )

    if (!findModifierGroup) return false

    const findModifierGroupModifier =
        findModifierGroup.modifier_group_modifiers.find(
            mg => mg.id === modifierGroupModifier.id
        )

    return findModifierGroupModifier ? true : false
}

export const isDisabledAdd = (
    modifierGroupSelected: ModifierGroupSelected[],
    modifierGroupItem: ModifierGroupItem,
    modifierGroupModifier: ModifierGroupModifier
) => {
    const findModifierGroup = modifierGroupSelected.find(
        m => m.modifier_group_id === modifierGroupModifier.modifier_group_id
    )

    if (!findModifierGroup) return false

    const totalQuantity = findModifierGroup.modifier_group_modifiers.reduce(
        (a, b) => a + b.quantity,
        0
    )

    return modifierGroupItem.max_quantity === totalQuantity
}

export const isCompleteQuantityModifiers = (
    modifierGroupItem: ModifierGroupItem,
    modifierGroupSelected: ModifierGroupSelected | undefined
) => {
    const modifierGroupModifiersQuantity =
        modifierGroupSelected?.modifier_group_modifiers?.reduce(
            (a, b) => a + b.quantity,
            0
        ) ?? 0

    const minQuantity = modifierGroupItem.has_range
        ? modifierGroupItem.min_quantity
        : modifierGroupItem.max_quantity

    const isComplete = modifierGroupModifiersQuantity >= minQuantity

    return isComplete
}

export function compareModifierGroups(
    prevModifierGroups: ModifierGroupSelected[],
    nextModifierGroups: ModifierGroupSelected[]
) {
    function comparePrev(
        nextMG: ModifierGroupSelected,
        nextMGM: ModifierGroupSelected['modifier_group_modifiers'][number]
    ) {
        let finded = false
        for (const prevMG of prevModifierGroups) {
            if (
                nextMG.modifier_group_id === prevMG.modifier_group_id &&
                prevMG.modifier_group_modifiers.length !==
                    nextMG.modifier_group_modifiers.length
            ) {
                return false
            }

            for (const prevFSSF of prevMG.modifier_group_modifiers) {
                if (
                    nextMG.modifier_group_id === prevMG.modifier_group_id &&
                    prevFSSF.id === nextMGM.id &&
                    prevFSSF.quantity === nextMGM.quantity
                ) {
                    finded = true
                }
            }
        }

        return finded
    }

    if (prevModifierGroups.length !== nextModifierGroups.length) return false

    for (const nextMG of nextModifierGroups) {
        for (const nextMGM of nextMG.modifier_group_modifiers) {
            if (!comparePrev(nextMG, nextMGM)) {
                return false
            }
        }
    }

    return true
}

interface DataModifierGroup {
    name_modifier_group: string
    id: number
    modifier_id: number
    name: string
    price: number
    quantity: number
}

export const getModifierGroups = (
    modifierOrders: ModifierOrder[]
): ModifierGroupSelected[] => {
    let data: {
        [key: string]: DataModifierGroup[]
    } = {}

    for (const modifierOrder of modifierOrders) {
        const modifierGroupModifier =
            modifierOrder.modifier?.modifier_group_modifier
        const modifier_group_id = modifierGroupModifier?.modifier_group_id
        if (!modifier_group_id) continue

        const dataModifierGroup = {
            name_modifier_group:
                modifierGroupModifier?.modifier_group?.name ?? '',
            id: modifierOrder.id,
            modifier_id: modifierOrder.modifier_id,
            name: modifierOrder.modifier?.name ?? '',
            price: modifierOrder.price,
            quantity: modifierOrder.quantity
        }

        if (data[modifier_group_id]) {
            const arrayData = data[modifier_group_id]

            const newData = [...arrayData, { ...dataModifierGroup }]
            data = {
                ...data,
                [modifier_group_id]: newData
            }
        } else {
            data = {
                ...data,
                [modifier_group_id]: [
                    {
                        ...dataModifierGroup
                    }
                ]
            }
        }
    }

    const modifierGroups = []

    for (const [key, value] of Object.entries(data)) {
        const _value = value
        const firstValue = _value?.[0]
        const dataModifierGroup = {
            modifier_group_id: Number(key),
            name: firstValue?.name_modifier_group,
            modifier_group_modifiers: _value.map(_data => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { name_modifier_group, ...rest } = _data
                return rest
            })
        }
        modifierGroups.push(dataModifierGroup)
    }

    return modifierGroups
}

export const getTotalPrice = (
    price: number,
    modifierGroups: ModifierGroupSelected[]
) => {
    const acumulate_price_modifiers = modifierGroups.reduce(
        (a, b) =>
            a +
            b.modifier_group_modifiers.reduce(
                (c, d) => c + d.price * d.quantity,
                0
            ),
        0
    )
    return Number(
        new Big(price ?? 0).plus(acumulate_price_modifiers).toString()
    )
}
