import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import { MdlEditItemFormData, ModalEditItemProps } from './types'
import { FormEvent, useState } from 'react'
import extractDataForm from '../../../../utilities/extractDataForm'
import ContentForm from './ContentForm'
import ModalPreferences from '../ModalPreferences'
import useSession from '../../../../hooks/useSession'
import useParamsQuickSaleBy from '../../hooks/useParamsQuickSaleBy'
import useMenu from '../../../../hooks/useMenu'
import useStorage from '../../../../hooks/useStorage'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'

export default function ModalEditItem(props: ModalEditItemProps) {
    const { isWaiter, isDealer, isLogged } = useSession()
    const [helperPrice, setHelperPrice] = useState('')
    const { updatePrice, updateQuantity, updateDescription, initialMenu } =
        useMenu()
    const [modlPreferenceIsOpen, setMoldPreferenceIsOpen] = useState(false)
    const [description, setDescription] = useState<string>(
        () => props.data.description
    )
    const _isWaiter = isWaiter() || !isLogged
    const { by } = useParamsQuickSaleBy()
    const { business_variables } = useStorage()

    const isEnabledEditPrice =
        business_variables.find(v => v.code === '024')?.value === '1'

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<MdlEditItemFormData>(ev.target)

        if (!_isWaiter && form.price === '') {
            setHelperPrice('Escriba el precio')
            return
        }
        if (!_isWaiter && isEnabledEditPrice) {
            updatePrice(props.data.hash, Number(form.price))
        }
        updateQuantity(props.data.hash, Number(form.quantity))
        updateDescription(props.data.hash, description)
        props.onClose()
    }

    function openPreferencesHandler() {
        setMoldPreferenceIsOpen(true)
        props.onClose()
    }

    function addPreferenceHandler(prefence: Record<string, string>) {
        setDescription(
            `${description}${description ? ', ' : ''}${Object.values(
                prefence
            ).join(', ')}`
        )
        props.onOpen()
        setMoldPreferenceIsOpen(false)
    }

    function clickClosePreferenceHandler() {
        props.onOpen()
        setMoldPreferenceIsOpen(false)
    }

    function getMin() {
        if (by === 'pre_orders') return 1
        const initMenuItem = initialMenu.find(
            menuItem => menuItem.itemId === props.data.itemId
        )
        if (initMenuItem && (_isWaiter || isDealer())) {
            return initMenuItem.quantity
        }
        return 1
    }

    return (
        <>
            {modlPreferenceIsOpen && (
                <ModalPreferences
                    categoryItemId={props.data.categoryItemId}
                    isOpen={modlPreferenceIsOpen}
                    onClose={clickClosePreferenceHandler}
                    onAdd={addPreferenceHandler}
                />
            )}
            <Modal
                isOpen={props.isOpen}
                onClose={props.onClose}
                closeOnOverlayClick
                isCentered
                size="md"
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalBody>
                        <Flex flexDir="column">
                            <Text
                                fontSize="2xl"
                                fontWeight="bold"
                                textAlign={{ base: 'left', lg: 'center' }}
                                mb="2"
                                order={1}
                            >
                                {props.data.name}
                            </Text>

                            {props.data.modifierGroups &&
                                props.data.modifierGroups?.length > 0 && (
                                    <Box order={2} mb="1">
                                        {props.data.modifierGroups.map(
                                            (fes, _i) => (
                                                <Box
                                                    key={_i}
                                                    display={'flex'}
                                                    gap={2}
                                                    fontSize="14px"
                                                >
                                                    <Text pl={6} pr={5}>
                                                        <TextModifierGroupModifiers
                                                            modifierGroup={fes}
                                                        />
                                                    </Text>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )}
                            <Text
                                fontSize="sm"
                                textAlign={{ base: 'left', lg: 'center' }}
                                mb="8"
                                order={3}
                            >
                                Cantidad actual: {props.data.quantity}
                            </Text>
                            <Box order={4}>
                                <ContentForm
                                    onSubmit={submitHandler}
                                    defaultUnitPrice={props.data.unitPrice}
                                    description={description}
                                    onChangeDescription={setDescription}
                                    defaultQuantity={props.data.quantity}
                                    onOpenPreference={openPreferencesHandler}
                                    helperPrice={helperPrice}
                                    onChangePrice={() => setHelperPrice('')}
                                    min={getMin()}
                                />
                            </Box>
                            <Flex
                                w="full"
                                mt="5"
                                justifyContent="space-between"
                                gap={4}
                                order={[0, 0, 4]}
                            >
                                <Button
                                    h="60px"
                                    w="180px"
                                    border="1px solid #434343"
                                    color="#434343"
                                    bg="white"
                                    _hover={{}}
                                    onClick={props.onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    h="60px"
                                    w="180px"
                                    color="white"
                                    bg="orange"
                                    _hover={{}}
                                    type="submit"
                                    form="form-edit-item"
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
