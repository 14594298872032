import { getModifierGroups } from 'components/ModalModifiersItem/utils'
import { SelectedItem } from '../../../types/runway_state'
import { generateId } from '../../../utilities/generateId'
import { Flavor, TableData } from '../types'

export default function orderItemsToSelectedItems(
    orderItems: TableData['order_items']
): SelectedItem[] {
    const selectedItems: SelectedItem[] = []
    for (const orderItem of orderItems) {
        const selectedItem = selectedItems.find(
            selected =>
                selected.category_item_id === orderItem.item.category_item.id
        )

        if (selectedItem) {
            selectedItem.items = [
                ...selectedItem.items,
                ...parseItem(orderItem)
            ]
        } else {
            selectedItems.push({
                category_item_id: orderItem.item.category_item.id,
                category_item_name: orderItem.item.category_item.name,
                items: parseItem(orderItem)
            })
        }
    }

    return selectedItems
}

function parseItem(
    orderItem: TableData['order_items'][number]
): Array<SelectedItem['items'][number]> {
    const items: Array<SelectedItem['items'][number]> = []

    const dataItem = {
        id: orderItem.item.id,
        order_item_id: orderItem.id,
        name: orderItem.item.name,
        quantity: orderItem.quantity,
        quantity_recorded: orderItem.quantity,
        price: orderItem.item.price ?? orderItem.price ?? 0,
        description: orderItem.aditional_information ?? '',
        user_price_edition: orderItem.user_price_edition,
        feature_settings: [],
        pre_order_item_id: orderItem.id,
        modifier_groups: getModifierGroups(orderItem.modifier_orders ?? [])
    }

    if (orderItem?.feature_settings?.length > 0) {
        orderItem?.feature_settings?.forEach(fs => {
            const featureGroups: Array<Array<Flavor & { name: string }>> = []
            Object.keys(fs.feature_groups).forEach(key => {
                if (key !== 'quantity' && key !== 'feature_setting_id') {
                    const fgroup = fs.feature_groups[key]
                    featureGroups.push(
                        fgroup.map(fg => ({
                            ...fg,
                            name: key
                        }))
                    )
                }
            })

            items.push({
                ...dataItem,
                _id_generated: generateId(),
                quantity: fs.quantity,
                quantity_recorded: fs.quantity,
                feature_settings: featureGroups.map(fg => {
                    return {
                        feature_id: fg[0].feature_id,
                        quantity: fg[0].quantity,
                        name: fg[0].name,
                        feature_setting_id: fs.feature_setting_id,
                        feature_setting_sub_features: fg.map(featureGroup => ({
                            sub_feature_id: featureGroup.sub_feature_id,
                            feature_setting_sub_feature_id:
                                featureGroup.feature_setting_sub_feature_id,
                            quantity: featureGroup.quantity,
                            name: featureGroup.sub_feature_name,
                            price: featureGroup.price_feature,
                            aditional_information:
                                featureGroup.aditional_informacion
                        }))
                    }
                })
            })
        })
    } else {
        items.push(dataItem)
    }

    return items
}
