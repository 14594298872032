import { Button, Flex, Box } from '@chakra-ui/react'
import { listWarehouses } from '../../storage'
import WarehouseForm from './WarehouseForm'

export default function Actions({ reload }: { reload: () => void }) {
    return (
        <Flex
            mt={4}
            mb={4}
            w={{
                base: 'full'
            }}
            justifyContent={{
                base: 'space-between'
            }}
        >
            <Box />
            <WarehouseForm
                element={click => (
                    <Button onClick={click} variant="alo-blue:md-xl" ml="2">
                        Nuevo almacén
                    </Button>
                )}
                onDone={() => {
                    listWarehouses.load()
                    reload()
                }}
            />
        </Flex>
    )
}
