import { Box, Text, VStack } from '@chakra-ui/react'
import { Fragment } from 'react'

import useDelivery from '../../../../../hooks/useDelivery'

import DataItem from '../../../shared/DataItem'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'

export default function Order() {
    const { products } = useDelivery()

    return (
        <VStack
            w="full"
            alignItems="flex-start"
            fontFamily="RedHatDisplay"
            mb={6}
        >
            {products
                .filter(product =>
                    product.items.some(item => item.quantity > 0)
                )
                .map(product => (
                    <Fragment key={product.category_item_id}>
                        <Text fontWeight="bold" fontSize="sm">
                            {product.category_item_name}
                        </Text>
                        {product.items
                            .filter(item => item.quantity > 0)
                            .map(item => {
                                const hasModifiers =
                                    item.modifier_groups &&
                                    item.modifier_groups?.length > 0
                                const price = hasModifiers
                                    ? item.total_price ?? 0
                                    : item.price
                                return (
                                    <Fragment key={item.itemId}>
                                        <DataItem
                                            title={`(${item.quantity}) ${item.name} `}
                                            value={(
                                                price * item.quantity
                                            ).toFixed(2)}
                                        />
                                        {item.modifier_groups &&
                                            item.modifier_groups?.length >
                                                0 && (
                                                <Box mb="1">
                                                    {item.modifier_groups.map(
                                                        (fes, _i) => (
                                                            <Box
                                                                key={_i}
                                                                display={'flex'}
                                                                gap={2}
                                                                fontSize="14px"
                                                            >
                                                                <Text
                                                                    pl={4}
                                                                    pr={5}
                                                                >
                                                                    <TextModifierGroupModifiers
                                                                        modifierGroup={
                                                                            fes
                                                                        }
                                                                    />
                                                                </Text>
                                                            </Box>
                                                        )
                                                    )}
                                                </Box>
                                            )}
                                        {/* <VStack w="full" pl={4}>
                                        <DataItem
                                            color="blue.600"
                                            title="Cono"
                                            value="S/1.00"
                                        />
                                        <DataItem
                                            color="blue.600"
                                            title="Chispas de chocolate"
                                            value="S/0.50"
                                        />
                                    </VStack> */}
                                    </Fragment>
                                )
                            })}
                    </Fragment>
                ))}
        </VStack>
    )
}
