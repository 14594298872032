import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useModifierGroup from './useModifierGroup'
import ModifierGroup from '../../../models/modifier_group'

export default function Edition({
    modifierGroup
}: {
    modifierGroup: ModifierGroup
}) {
    const openModal = useModifierGroup(modifierGroup)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
