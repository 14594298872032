import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listModifierGroup } from '../storage'
import Edition from './Edition'
import DeleteModifierGroup from './DeleteModifierGroup'
import ModifiersQuantity from './AsocciatedModifiers'
import AsocciatedProducts from './AsocciatedProducts'

export default function ModifierGroup() {
    return (
        <List
            control={listModifierGroup}
            tbody={(modifierGroup, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{modifierGroup?.name}</Td>
                    <Td textAlign="center">
                        {modifierGroup?.modifier_group_modifiers?.length ===
                        0 ? (
                            0
                        ) : (
                            <ModifiersQuantity modifierGroup={modifierGroup} />
                        )}
                    </Td>
                    <Td textAlign="center">
                        {modifierGroup?.modifier_group_items?.length === 0 ? (
                            0
                        ) : (
                            <AsocciatedProducts modifierGroup={modifierGroup} />
                        )}
                    </Td>
                    <Td textAlign="center">
                        <Edition modifierGroup={modifierGroup} />
                        <DeleteModifierGroup modifierGroup={modifierGroup} />
                    </Td>
                </Tr>
            )}
        />
    )
}
