import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputProps,
    // SelectProps,
    Text
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DraggableProvided
} from 'react-beautiful-dnd'
import ErrorHelper from '../../../../components/ErrorHelper'
import Close from '../../../../components/Icon/Close'
import colors from '../../../../config/theme/colors'
import { formModifierGroup } from '../../storage'
import ModalModifiers from './ModalModifiers'
import Modifier from '../../../../models/modifier'
import Apps from '../../../../components/Icon/Apps'

export default function FormModifierGroup() {
    return (
        <>
            <Name />
            <Modifiers />
        </>
    )
}

function Name() {
    const { props, helper, error } =
        formModifierGroup.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Modifiers() {
    const [open, setOpen] = useState<boolean>(false)
    const { value, setValue } = formModifierGroup.useValue('modifiers')

    const handleChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        value[index][e.target.name] = e.target.value
        setValue([...value])
    }

    const handleDelete = (index: number) => {
        const newFeatures = []
        for (let i = 0; i < value.length; i++) {
            if (i !== index) {
                newFeatures.push(value[i])
            }
        }
        setValue([...newFeatures])
    }

    const handleAddModifiers = (data: Modifier[]) => {
        const newsProductionItems = []

        for (const productionItem of data) {
            const findProductionItem = value?.find(
                (_data: any) => _data.id === productionItem?.id
            )
            if (!findProductionItem) {
                newsProductionItems.push({
                    modifier: productionItem,
                    price: 0
                })
            }
        }

        setValue([...value, ...newsProductionItems])
        setOpen(false)
    }

    const modifiers = value.map(data => data.modifier)

    return (
        <FormControl mb={4}>
            <FormLabel>Modificadores</FormLabel>
            <Text fontSize="13px" mb="3">
                Agrega modificadores
            </Text>
            {value.length !== 0 ? <TitlesModifiers /> : null}

            <Rows
                data={value}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setData={setValue}
            />
            <Box>
                <Button
                    background="#FFF"
                    height="40px"
                    fontSize="14px"
                    color="#4E6BD3"
                    border="1px solid #C5C9D0"
                    borderRadius="18px"
                    fontWeight={500}
                    width="114px"
                    type="button"
                    onClick={() => setOpen(true)}
                >
                    Agregar
                </Button>
            </Box>
            <ModalModifiers
                open={open}
                onClose={() => setOpen(false)}
                handleAddModifiers={handleAddModifiers}
                title="Seleccionar Modificador(es)"
                size="xl"
                selectedModifiers={modifiers ?? []}
            />
        </FormControl>
    )
}

function TitlesModifiers() {
    return (
        <Box display="flex">
            <Box width={'5%'} paddingX="4"></Box>
            <Box
                width={{
                    base: '50%',
                    lg: '70%'
                }}
                paddingX="4"
            >
                <Text fontSize="12px" mb="2">
                    Nombre
                </Text>
            </Box>
            <Box paddingX="2" width={'20%'}>
                <Text fontSize="12px" mb="2">
                    Precio
                </Text>
            </Box>
        </Box>
    )
}

function Rows({
    data,
    handleChange,
    handleDelete,
    setData
}: {
    data: any[]
    handleChange: any
    handleDelete: any
    setData: (value: any[]) => void
}) {
    const [isDisabledDrop, setDisabledDrop] = useState(false)

    function dragEndHandler(result: DropResult) {
        if (!result.destination) return
        setDisabledDrop(true)

        const originIndex = result.source.index
        const destinationIndex = result.destination.index

        if (originIndex === destinationIndex) return

        const toTop = originIndex > destinationIndex

        const dataOrigin = data[originIndex]

        const newCategoryItems = []

        for (let i = 0; i < data.length; i++) {
            const categoryItem = data[i]
            if (i === originIndex) continue
            if (toTop) {
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
                newCategoryItems.push(categoryItem)
            } else {
                newCategoryItems.push(categoryItem)
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
            }
        }

        setData([...newCategoryItems])

        setDisabledDrop(false)
    }

    return (
        <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable-1">
                {provided => (
                    <>
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {data.map((modifier, i) => (
                                <Draggable
                                    key={modifier.modifier?.id}
                                    draggableId={modifier.modifier?.id?.toString()}
                                    index={i}
                                >
                                    {_provided => (
                                        <SubFeature
                                            handleChange={handleChange}
                                            handleDelete={handleDelete}
                                            index={i}
                                            sub_feature={modifier}
                                            isDisabledDrop={isDisabledDrop}
                                            provided={_provided}
                                        />
                                    )}
                                </Draggable>
                            ))}
                        </Box>
                        {provided.placeholder}
                    </>
                )}
            </Droppable>
        </DragDropContext>
    )
}

function SubFeature({
    sub_feature,
    handleChange,
    index,
    handleDelete,
    isDisabledDrop,
    provided
}: {
    sub_feature: any
    handleChange: any
    index: number
    handleDelete: any
    isDisabledDrop: boolean
    provided: DraggableProvided
}) {
    return (
        <Box
            display="flex"
            border="1px solid #C4C4C4"
            borderRadius="10px"
            marginBottom="2"
            paddingX="2"
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <Box
                paddingX="2"
                paddingY="2"
                width={'5%'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                {...provided.dragHandleProps}
                {...(isDisabledDrop
                    ? {
                          pointerEvents: 'none'
                      }
                    : {})}
            >
                <Icon fill={colors['gray-3']} as={Apps} fontSize="20" />
            </Box>
            <Box
                width={{
                    base: '50%',
                    lg: '70%'
                }}
                paddingX="2"
                paddingY="2"
                borderRight="1px solid #C4C4C4"
            >
                <Input
                    value={sub_feature.modifier.name}
                    placeholder=""
                    maxLength={180}
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="name"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                paddingX="2"
                borderRight="1px solid #C4C4C4"
                paddingY="2"
                width={{
                    base: '40%',
                    lg: '20%'
                }}
            >
                <Input
                    value={sub_feature.price}
                    placeholder="Precio"
                    maxLength={180}
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="price"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                paddingX="2"
                paddingY="2"
                width={'5%'}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Icon
                    onClick={() => handleDelete(index)}
                    fill={colors.error}
                    as={Close}
                    cursor="pointer"
                />
            </Box>
        </Box>
    )
}
