import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useModifier from './useModifier'
import Modifier from '../../../models/modifier'

export default function Edition({ modifier }: { modifier: Modifier }) {
    const openModal = useModifier(modifier)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
