import { storagePage } from '../storage'

export default function getItemsForPost(userId: number | null) {
    const items = storagePage.selectedItems.map(item => item.items)
    const nextItems: DataForPost[] = []

    for (const item of items.flat()) {
        const restDataItem: DataForPost = {
            id: item.id,
            quantity: item.quantity,
            aditional_information: item.description,
            price: item.price,
            user_price_edition: userId //debe enviar el id del usuario que edita, cuando se edita el precio de un ítem
        }

        if (item.feature_settings.length > 0) {
            //const nextItem = nextItems.find(_item => _item.id === item.id)

            const feature_setting_sub_features: DataFeatureSetting['feature_setting_sub_features'] =
                item.feature_settings
                    .map(fs =>
                        fs.feature_setting_sub_features.map(fssf => ({
                            sub_feature_id: fssf.sub_feature_id,
                            quantity: fssf.quantity,
                            price_feature: fssf.price,
                            aditional_information: fssf.aditional_information
                        }))
                    )
                    .flat()

            /*
            ANTES SE JUNTABAN TODAS LAS CONFIGURACIONES DEL ITEM EN 1 SOLO REGISTRO
            if (nextItem) {
                nextItem.quantity = nextItem.quantity + item.quantity
                const featureSettings =
                    nextItem.feature_settings as DataFeatureSetting[]

                featureSettings.push({
                    quantity: item.quantity,
                    feature_setting_sub_features
                })
            } else {
                nextItems.push({
                    ...restDataItem,
                    quantity: item.quantity,
                    feature_settings: [
                        {
                            quantity: item.quantity,
                            feature_setting_sub_features
                        }
                    ]
                })
            }
            */
            nextItems.push({
                ...restDataItem,
                quantity: item.quantity,
                feature_settings: [
                    {
                        quantity: item.quantity,
                        feature_setting_sub_features
                    }
                ]
            })
        } else {
            nextItems.push(restDataItem)
        }
    }

    return nextItems
}

interface DataForPost {
    id: number
    quantity: number
    aditional_information: string
    price: number
    user_price_edition: number | null
    feature_settings?: DataFeatureSetting[]
}

export interface DataFeatureSetting {
    quantity: number
    feature_setting_sub_features: Array<{
        sub_feature_id: number
        quantity: number
        price_feature: number
        aditional_information: string
    }>
}

export interface DataModifier {
    modifier_id: number
    quantity: number
    price: number
}
