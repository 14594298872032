import { Icon } from '@chakra-ui/react'
import ProductionArea from '../../models/production_area'
import { listProductionArea } from './storage'
import Delete from '../../lib/delete'
import Trash from '../../components/Icon/Trash'
import colors from '../../config/theme/colors'

export default function DeleteProductionArea({
    productionArea
}: {
    productionArea: ProductionArea
}) {
    return (
        <Delete
            title="Eliminar área de producción"
            path={`/api/production_areas/${productionArea.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listProductionArea.setQuery('page', 1)
                listProductionArea.load()
            }}
        >
            ¿Esta seguro de eliminar el área de producción{' '}
            <b>{productionArea.name}</b>?
        </Delete>
    )
}
