import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { useGet } from '../../hooks/useHttp'
import useTab from '../../hooks/useTab'
import Warehouse from '../../models/warehouse'
import WarehousesSection from './components/WarehousesSection'

export default function Warehouses() {
    const props = useTab('warehouses')
    const { reload } = useGet<Warehouse[]>('2>/api/warehouses', [])

    return (
        <Sidebar>
            <SimpleNav title="Gestión de almacenes" />
            {props.value === 'warehouses' && (
                <WarehousesSection reload={reload} />
            )}
        </Sidebar>
    )
}
