import { Tr, Td, Badge, Flex } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import List from '../../lib/list'

import useInitial from './useInitial'
import { listProductionArea } from './storage'
import HeaderProductionArea from './Header'
import colors from '../../config/theme/colors'
import Edition from './Edition'
import DeleteProductionArea from './DeleteProductionArea'

export default function ProductionAreas() {
    useInitial()

    return (
        <Sidebar>
            <SimpleNav title="Áreas de producción" />
            <HeaderProductionArea />
            <List
                control={listProductionArea}
                tbody={(productionArea, e) => (
                    <Tr key={e}>
                        <Td textAlign="center">{productionArea.name}</Td>
                        <Td textAlign="center">
                            {productionArea.warehouse?.name ?? '-'}
                        </Td>
                        <Td textAlign="center">
                            {productionArea.production_area_printers.length ===
                            0 ? (
                                '-'
                            ) : (
                                <Flex gap={2}>
                                    {productionArea.production_area_printers.map(
                                        printer => (
                                            <Badge
                                                mx={1}
                                                key={printer.id}
                                                bg={colors['alo-blue']}
                                                variant="solid"
                                                textTransform="capitalize"
                                                borderRadius="full"
                                                px={2}
                                            >
                                                {printer.printer.name}
                                            </Badge>
                                        )
                                    )}
                                </Flex>
                            )}
                        </Td>
                        <Td textAlign="center">
                            {productionArea.production_area_zones.length ===
                            0 ? (
                                '-'
                            ) : (
                                <Flex gap={2}>
                                    {productionArea.production_area_zones.map(
                                        zone => (
                                            <Badge
                                                mx={1}
                                                key={zone.id}
                                                bg={colors['alo-blue']}
                                                variant="solid"
                                                textTransform="capitalize"
                                                borderRadius="full"
                                                px={2}
                                            >
                                                {zone.zone.name}
                                            </Badge>
                                        )
                                    )}
                                </Flex>
                            )}
                        </Td>
                        <Td textAlign="center">
                            {productionArea.production_area_cashboxes.length ===
                            0 ? (
                                '-'
                            ) : (
                                <Flex>
                                    {productionArea.production_area_cashboxes.map(
                                        cashbox => (
                                            <Badge
                                                mx={1}
                                                key={cashbox.id}
                                                bg={colors['alo-blue']}
                                                variant="solid"
                                                textTransform="capitalize"
                                                borderRadius="full"
                                                px={2}
                                            >
                                                {cashbox.cashbox.name}
                                            </Badge>
                                        )
                                    )}
                                </Flex>
                            )}
                        </Td>
                        <Td textAlign="center">
                            <Edition productionArea={productionArea} />
                            <DeleteProductionArea
                                productionArea={productionArea}
                            />
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
