import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import { formCombo, listCombo } from '../storage'
import FormCombo from './FormCombo'
import Combo from '../../../models/combo'

export default function useCombo(combo?: Combo) {
    const { validation, observer, submit } = useSubmit(formCombo, {
        path: combo ? `/api/combos/${combo.id}` : '/api/combos',
        method: combo ? 'put' : 'post',
        done: () => listCombo.load()
    })

    const toast = useToast()

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.price.isEmpty('Es requerido asignar un precio')
        v.items.condition(a => {
            if (!a) return ''
            return a.length < 2
                ? 'Es requerido mínimo 2 productos asociados'
                : ''
        })
        v.category_id.isEmpty('Selecciona una categoría')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        price: Number(f.price),
        category_item_id: parseInt(f.category_id),
        image_url: f.image_url ?? null,
        items: f.items.map((_f: any) => {
            return {
                id: _f.id,
                quantity: parseInt(_f.quantity)
            }
        })
    }))

    async function openModal() {
        if (combo) {
            const { data, error } = await get(`/api/combos/${combo.id}`)

            if (data) {
                const _item = data.result as Combo

                formCombo.store({
                    id: _item.id.toString() ?? '',
                    name: _item.name,
                    category_id: _item.category_item_id.toString(),
                    price: _item.price.toString(),
                    items: _item.combo_items.map(combo_item => {
                        return {
                            ...combo_item.item,
                            id: combo_item.item_id,
                            quantity: combo_item.quantity
                        }
                    }),
                    image_url: _item.image_url ?? null
                })
                formCombo.init(formCombo.store)
            }
            if (error) {
                toast({
                    title:
                        error.message ||
                        'Lo sentimos, ocurrió un error al recuperar la información , vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                return
            }
        } else {
            formCombo.init()
        }
        modal(FormCombo, {
            title: combo ? 'Editar combo' : 'Nuevo combo',
            onSubmit: submit,
            size: '2xl',
            isCentered: false
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    price: number
    category_item_id: number
    image_url: string | null
    items: any[]
}
