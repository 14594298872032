import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import useProductionArea from './useProductionArea'
import FiltersProductionArea from './Filters'

export default function HeaderProductionArea() {
    const openModal = useProductionArea()

    return (
        <Flex
            mt={4}
            mb={4}
            w={{
                xl: 'auto',
                lg: 'full'
            }}
            justifyContent={{
                base: 'space-between'
            }}
            flexDirection={{
                base: 'column',
                md: 'row'
            }}
            gap={2}
        >
            <FiltersProductionArea />
            <>
                <Button
                    variant="alo-blue:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'inline' }}
                    ml="2"
                >
                    Nueva Área de producción
                </Button>
                <Button
                    display={{ base: 'inline', md: 'none' }}
                    variant="alo-blue:md-xl"
                    onClick={openModal}
                >
                    + Nuevo
                </Button>
            </>
        </Flex>
    )
}
