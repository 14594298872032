import { Button, Flex, Heading, Icon, VStack } from '@chakra-ui/react'
import ArrowLeft from '../../../../../components/Icon/ArrowLeft'
import colors from '../../../../../config/theme/colors'
import useOrderByTable from '../../../hooks/useOrderByTable'
import OrderInfo from './OrderInfo'
import ModalPin from '../../../modals/ModalPin'
import { useState } from 'react'
import useSession from '../../../../../hooks/useSession'
import OtherOptions from './OtherOptions'
import PreOrderOptions from './PreOrderOptions'
import useParamsQuickSaleBy from '../../../hooks/useParamsQuickSaleBy'
import usePreOrder from '../../../hooks/usePreOrder'
import useStorage from '../../../../../hooks/useStorage'
import useMenu from '../../../../../hooks/useMenu'
import useDialog from 'hooks/useDialog'

interface HeadDetailsProps {
    /**
     * Solo para modo movil
     */
    onBack: () => void
}

export default function HeadDetails({ onBack }: HeadDetailsProps) {
    const { isLogged, isWaiter, isDealer, session, isAdmin, isCashier } =
        useSession()
    const { clear } = useMenu()
    const [isOpenPin, setIsOpenPin] = useState(false)
    const { enableEdition, isModeEdition, orderId, waiter } = useOrderByTable()
    const { preOrderId } = usePreOrder()
    const { by } = useParamsQuickSaleBy()
    const { business_variables } = useStorage()

    const isEnabledBookings =
        business_variables.find(bv => bv.code === '016')?.value === '1'

    const isAvailableEditAllWaiters =
        business_variables.find(bv => bv.code === '005')?.value === '1'

    const userId = session ? session.user.id : 0
    const allowEditOrder = userId == waiter?.id || isAdmin() || isCashier()

    const dialogInvalidEditOrder = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                No puede editar este pedido. Usted no está atendiendo esta mesa.
            </>
        )
    })

    function editHandler() {
        if (isLogged) {
            if (allowEditOrder || isAvailableEditAllWaiters) {
                enableEdition()
            } else {
                dialogInvalidEditOrder()
            }
        } else {
            setIsOpenPin(true)
        }
    }

    const pageEdition = !!preOrderId || !!orderId

    function canClear() {
        if (!pageEdition) return true
        if (pageEdition && isModeEdition && !(isWaiter() || isDealer()))
            return true
        if (pageEdition && !isModeEdition) return false
        if (!pageEdition && !isLogged) return true
        if (!pageEdition && (isWaiter() || isDealer())) return true
        return false
    }

    return (
        <>
            <ModalPin
                isOpen={isOpenPin}
                onClose={() => setIsOpenPin(false)}
                onPin={pin => enableEdition(pin)}
            />
            <VStack mb={2} px={{ base: 3, lg: 6 }} w="full">
                <Flex justifyContent="space-between" w="full" align="center">
                    <Flex align="center" gap={{ base: 2, lg: 0 }}>
                        <Button
                            display={{ base: 'block', lg: 'none' }}
                            onClick={onBack}
                        >
                            <Icon as={ArrowLeft} fill={colors.dark} />
                        </Button>
                        <Heading size="17px" color="dark">
                            Detalle de pedido
                        </Heading>
                    </Flex>
                    {canClear() && (
                        <Button
                            variant="outline"
                            colorScheme="red"
                            size="sm"
                            rounded="full"
                            onClick={clear}
                        >
                            Limpiar pedido
                        </Button>
                    )}
                    {!isModeEdition && (
                        <Button
                            variant="primary:sm-xl"
                            bg="transparent"
                            color="primary"
                            onClick={editHandler}
                        >
                            Editar pedido
                        </Button>
                    )}
                </Flex>
                {by === 'tables' && <OrderInfo />}
                {isEnabledBookings && <PreOrderOptions />}
                <OtherOptions />
            </VStack>
        </>
    )
}
