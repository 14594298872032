import { Button, Flex, Text } from '@chakra-ui/react'
import { CounterProps } from '../types'
import colors from 'config/theme/colors'

export default function Counter({ counter, setCounter }: CounterProps) {
    return (
        <Flex alignItems="center" color="white" justifyContent="center">
            <Button
                bg={colors.dark}
                w={{
                    base: 10,
                    md: 12
                }}
                h={{
                    base: 10,
                    md: 12
                }}
                borderRadius="lg"
                fontSize="3xl"
                onClick={() => setCounter(counter - 1)}
                cursor="pointer"
                {...(counter === 1 && {
                    opacity: '.6',
                    pointerEvents: 'none'
                })}
                _hover={{}}
                _active={{}}
            >
                -
            </Button>
            <Text
                fontSize="xl"
                color={colors.dark}
                mx={2}
                minW={{
                    base: 10,
                    md: 14
                }}
                textAlign="center"
            >
                {counter}
            </Text>
            <Button
                bg={colors.dark}
                w={{
                    base: 10,
                    md: 12
                }}
                h={{
                    base: 10,
                    md: 12
                }}
                borderRadius="lg"
                fontSize="3xl"
                onClick={() => setCounter(counter + 1)}
                cursor="pointer"
                _hover={{}}
                _active={{}}
            >
                +
            </Button>
        </Flex>
    )
}
