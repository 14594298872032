import { Box, Center, Heading, Flex, Icon, Text } from '@chakra-ui/react'
import Close from '../../../../components/Icon/Close'
import colors from '../../../../config/theme/colors'
import useSession from '../../../../hooks/useSession'
// import IceCream from '../../ContentModals/IceCream'
import useListItems from './useListItems'
import { storagePage } from '../../storage'
import filterItems from '../../utilities/filterItems'
import ItemInfo from './ItemInfo'
import { getDataForEdition } from './utilities'
import { Fragment } from 'react'
import ModifierGroups from './ModifierGroups'
import ModalModifiersItem from 'components/ModalModifiersItem'

export default function ListItems() {
    const {
        clickOpenModalHandler,
        clickDeleteItemHandler,
        saveItemWithFeatures
    } = useListItems()

    const { userCanCancelItems } = useSession()
    const { selectedItems, editOrderTable, hasOrderTable, isPreOrder } =
        storagePage

    const isEditable = (hasOrderTable && editOrderTable) || !hasOrderTable
    const canDeleteItems = !hasOrderTable || userCanCancelItems()

    return (
        <Box
            maxHeight={
                isPreOrder ? 'calc(100vh - 300px)' : 'calc(100vh - 260px)'
            }
            overflow="auto"
            px="6"
        >
            {filterItems(selectedItems).map((selected, i) => (
                <Box color="alo-gray" key={i}>
                    <Heading size="sm" my="3">
                        {selected.category_item_name}
                    </Heading>
                    {selected.items.map((item, _i) => (
                        <Fragment key={_i}>
                            {item.quantity !== 0 && (
                                <Flex
                                    position="relative"
                                    align="center"
                                    justify="space-between"
                                >
                                    <ModalModifiersItem
                                        itemId={item.id}
                                        onDone={(
                                            featureSelected,
                                            quantity,
                                            modifierGroupsSelected,
                                            item_price_plus_features_price,
                                            _idGenerated,
                                            description
                                        ) =>
                                            saveItemWithFeatures(
                                                selected.category_item_id,
                                                item.id,
                                                featureSelected,
                                                modifierGroupsSelected,
                                                quantity,
                                                item._id_generated as string,
                                                item_price_plus_features_price,
                                                description ?? ''
                                            )
                                        }
                                        {...(item.modifier_groups && {
                                            edition: getDataForEdition(
                                                item.modifier_groups
                                            )
                                        })}
                                        description={item.description ?? ''}
                                        quantity={item.quantity}
                                        element={click => (
                                            <Box
                                                width="full"
                                                {...(isEditable
                                                    ? {
                                                          onClick: () => {
                                                              if (
                                                                  Boolean(
                                                                      item.has_modifiers
                                                                  ) ||
                                                                  (item.modifier_groups &&
                                                                      item
                                                                          .modifier_groups
                                                                          .length >
                                                                          0)
                                                              ) {
                                                                  click()
                                                              } else {
                                                                  clickOpenModalHandler(
                                                                      item,
                                                                      selected.category_item_id
                                                                  )
                                                              }
                                                          }
                                                      }
                                                    : {})}
                                            >
                                                <ItemInfo
                                                    item={item}
                                                    isEditable={isEditable}
                                                />
                                                <ModifierGroups
                                                    modifierGroups={
                                                        item.modifier_groups
                                                    }
                                                />
                                            </Box>
                                        )}
                                    />
                                    {isEditable && canDeleteItems && (
                                        <Center
                                            width="10"
                                            height="10"
                                            cursor="pointer"
                                            position="absolute"
                                            right="-6"
                                            zIndex="1"
                                            background="blackAlpha.50"
                                        >
                                            <Icon
                                                as={Close}
                                                fontSize={{
                                                    xl: 18,
                                                    lg: 15
                                                }}
                                                fill={colors.secondary}
                                                onClick={() =>
                                                    clickDeleteItemHandler(
                                                        selected.category_item_id,
                                                        item.id,
                                                        _i
                                                    )
                                                }
                                            />
                                        </Center>
                                    )}
                                </Flex>
                            )}
                        </Fragment>
                    ))}
                </Box>
            ))}
            {filterItems(selectedItems).length === 0 && (
                <Text color="gray-3" fontSize="sm" textAlign="center" mt={2}>
                    Selecciona platos o bebidas
                </Text>
            )}
        </Box>
    )
}
