import { Box, Center, Flex, Text } from '@chakra-ui/react'
import colors from '../../../../config/theme/colors'
import useAddItems from './useAddItems'
import { ItemBoxProps } from './types'
import { SelectedItem } from '../../../../types/runway_state'
import { storagePage } from '../../storage'
import ModalModifiersItem from 'components/ModalModifiersItem'

export default function BoxItem({
    bg,
    item,
    enabled_validation_stock
}: ItemBoxProps) {
    const { addItem, addItemWithModifiers } = useAddItems(item)

    function getCountItem(item_id: number) {
        let items_selected: SelectedItem['items'] = []
        for (const category of storagePage.selectedItems) {
            const items = category.items.filter(data => data.id === item_id)
            if (items.length > 0) {
                items_selected = items
                break
            }
        }
        return items_selected.reduce((a, b) => a + b.quantity, 0)
    }

    return (
        <ModalModifiersItem
            itemId={item.id}
            onDone={(
                featuresSelected,
                quantity,
                modifierGroupsSelected,
                _item_price_plus_features_price,
                _idGenerated,
                description
            ) => {
                addItemWithModifiers(
                    featuresSelected,
                    quantity,
                    modifierGroupsSelected,
                    description ?? ''
                )
            }}
            element={click => (
                <Flex
                    bg={bg}
                    borderRadius="lg"
                    position="relative"
                    cursor="pointer"
                    h={{
                        sm: '130px',
                        xl: '160px'
                    }}
                    p={3}
                    color="white"
                    flexDirection="column"
                    justifyContent="space-between"
                    onClick={() => {
                        if (item?.has_modifier_groups) {
                            click()
                        } else {
                            addItem()
                        }
                    }}
                    pointerEvents={
                        item.stock_production_item_simple !== null &&
                        enabled_validation_stock &&
                        item.stock_production_item_simple <= 0
                            ? 'none'
                            : 'auto'
                    }
                    opacity="initial"
                >
                    {getCountItem(item.id) > 0 && (
                        <Center
                            position="absolute"
                            right={'8px'}
                            top={'10px'}
                            color={colors.dark}
                            bg={colors.white}
                            borderRadius="50%"
                            w={'25px'}
                            h={'25px'}
                            lineHeight={1}
                            fontSize={14}
                            zIndex="1"
                        >
                            {getCountItem(item.id)}
                        </Center>
                    )}

                    {item.image_url && (
                        <Box
                            bg={`linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${item.image_url}) ,0, center/cover, no-repeat`}
                            h="full"
                            w="full"
                            borderRadius="10px"
                            position="absolute"
                            backgroundSize="cover"
                            left="0"
                            top="0"
                        />
                    )}
                    <Text
                        position="relative"
                        fontWeight="semibold"
                        fontSize={{
                            sm: 'sm',
                            xl: 'md'
                        }}
                        marginRight="20px"
                        textDecorationLine={
                            item.stock_production_item_simple !== null &&
                            enabled_validation_stock &&
                            item.stock_production_item_simple <= 0
                                ? 'line-through'
                                : 'none'
                        }
                    >
                        {item.name}
                    </Text>
                    <Flex align="flex-end" justify="space-between" gap="2">
                        <Text position="relative" fontSize="md">
                            S/ {item.price.toFixed(2)}
                        </Text>
                        {item.inventory_management_type ===
                            'Inventario simple' && (
                            <Text fontSize="xs">
                                Quedan {item.stock_production_item_simple}
                            </Text>
                        )}
                    </Flex>
                </Flex>
            )}
        />
    )
}
