import { Box, Text } from '@chakra-ui/react'
import ModalSubFeatures from '../../../components/ModalSubFeatures'
import ModifierGroup from '../../../models/modifier_group'

export default function AsocciatedProducts({
    modifierGroup
}: {
    modifierGroup: ModifierGroup
}) {
    return (
        <ModalSubFeatures
            title={`Productos asociados de ${modifierGroup.name}`}
            element={click => (
                <Box
                    cursor="pointer"
                    onClick={() => {
                        click()
                    }}
                    textDecoration="underline"
                >
                    {modifierGroup.modifier_group_items.length}
                </Box>
            )}
        >
            <Text marginBottom="5" fontSize="13px" fontWeight={300}>
                Listado de productos asociados
            </Text>
            <Box display="flex">
                <Box width={'50%'} paddingX="4">
                    <Text fontSize="12px" mb="2">
                        Nombre
                    </Text>
                </Box>
                <Box paddingX="2" width={'50%'}>
                    <Text fontSize="12px" mb="2">
                        Precio
                    </Text>
                </Box>
            </Box>
            {modifierGroup.modifier_group_items.map(sub_feature => (
                <Box
                    key={sub_feature.id}
                    display="flex"
                    border="1px solid #C4C4C4"
                    borderRadius="10px"
                    marginBottom="2"
                    paddingX="2"
                >
                    <Box
                        width={'50%'}
                        paddingX="2"
                        paddingY="2"
                        borderRight="1px solid #C4C4C4"
                    >
                        {sub_feature.item?.name}
                    </Box>
                    <Box paddingX="2" paddingY="2" width={'50%'}>
                        S./ {sub_feature.item?.price}
                    </Box>
                </Box>
            ))}
        </ModalSubFeatures>
    )
}
