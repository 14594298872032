import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    useId
} from '@chakra-ui/react'
import { FormEvent, useRef, useState } from 'react'

interface Props {
    onDone: (reason: string) => void
    isOpen: boolean
    onClose: () => void
}

export default function ReducedItemsDelivery({
    isOpen,
    onDone,
    onClose
}: Props) {
    const initRef = useRef<HTMLInputElement>(null)
    const [reason, setReason] = useState('')
    const id = useId()

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()

        onDone(reason)
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={initRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Anulación de ítems
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <form id={id} onSubmit={handleSubmit}>
                                <Text mb={4}>
                                    Debido a que se han disminuído ítems al
                                    pedido, debe ingresar el motivo de anulación
                                </Text>
                                <FormControl isRequired>
                                    <FormLabel fontSize="sm">
                                        Ingresar motivo
                                    </FormLabel>
                                    <Input
                                        placeholder="Ingresar motivo de anulación de ítem(S)"
                                        value={reason}
                                        autoFocus
                                        ref={initRef}
                                        onChange={ev =>
                                            setReason(ev.target.value)
                                        }
                                    />
                                </FormControl>
                            </form>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onClose}>Cancelar</Button>
                            <Button
                                colorScheme="red"
                                isDisabled={!reason}
                                ml={2}
                                form={id}
                                type="submit"
                            >
                                Confirmar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
