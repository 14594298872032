import { Flex, Input } from '@chakra-ui/react'
import { listProductionArea } from './storage'
import Warehouse from '../../models/warehouse'
import { useGet } from '../../hooks/useHttp'
import Clean from '../../components/Clean'
import SelectWithLabel from '../../components/SelectWithLabel'

export default function FiltersProductionArea() {
    const { clear, ...props } = listProductionArea.useSearch()
    const propsFilter = listProductionArea.useFilter('warehouse_id')
    const { data } = useGet<Warehouse[]>(
        '2>/api/warehouses?limit=0&page=1&order=asc&sort=name&search=',
        []
    )

    return (
        <Flex
            flexDirection={{
                base: 'column',
                md: 'row'
            }}
            gridGap="2"
        >
            <Clean value={props.value} onClick={clear}>
                <Input placeholder="🏸 Buscar por nombre" {...props} />
            </Clean>
            <SelectWithLabel
                {...propsFilter}
                label="Seleccionar almacén:"
                placeholder="Todos"
            >
                {data.result.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </SelectWithLabel>
        </Flex>
    )
}
