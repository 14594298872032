import { Box, Flex } from '@chakra-ui/layout'
import { useMemo, useState } from 'react'
import Initial from '../../components/Initial'
import MenuContext, { MenuItem } from '../../context/MenuContext'
import NavbarAlt from '../../components/NavbarAlt'
import useMenuProviderValues from '../../hooks/useMenuProviderValues'
import SidebarCategories from '../../shared/SidebarCategories'
import ItemsGrid from '../../shared/ItemsGrid'
import MenuList from '../../shared/MenuList'
import DetailsContainer from './components/DetailsContainer'
import ItemsSearcher from '../../shared/ItemsSearcher'
import useDelivery from '../../hooks/useDelivery'
import { Redirect } from 'react-router-dom'
import itemHash from '../../utilities/item-hash'

export default function DeliveryOrder() {
    const [categoryItemId, setCategoryItemId] = useState(0)
    const { products, client } = useDelivery()
    const [isLoading, setIsLoading] = useState(true)

    if (!client) {
        return <Redirect to="/delivery/customers" />
    }

    const menuItems = useMemo<MenuItem[]>(() => {
        const _menuItems: MenuItem[] = []
        products.forEach(product => {
            product.items.forEach(item => {
                _menuItems.push({
                    hash: itemHash({
                        itemId: item.itemId,
                        orderItemId: item.orderItemId
                    }),
                    orderItemId: item.orderItemId,
                    itemId: item.itemId,
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    description: item.description,
                    categoryItemId: product.category_item_id,
                    categoryName: product.category_item_name,
                    commission: 0,
                    modifierGroups: item.modifier_groups ?? [],
                    isForEdition: item.isForEdition ?? false,
                    total_price: item.total_price ?? 0
                })
            })
        })

        return _menuItems
    }, [])

    const menuValues = useMenuProviderValues(menuItems)

    return (
        <Initial minH="100vh" bg="base-2">
            <MenuContext.Provider value={menuValues}>
                <NavbarAlt title="Agregar pedido" backLabel="CANCELAR" />
                <Flex>
                    <Box w="full">
                        <ItemsSearcher />
                        <Flex
                            w="full"
                            minH="calc(100vh - 117px)"
                            maxH="calc(100vh - 117px)"
                            flexDir={['column', 'column', 'column', 'row']}
                        >
                            <SidebarCategories
                                categoryItemId={categoryItemId}
                                onCategoryItem={setCategoryItemId}
                                isLoadingCategory={isLoading}
                            />
                            <ItemsGrid
                                categoryItemId={categoryItemId}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        </Flex>
                    </Box>
                    <DetailsContainer>
                        <MenuList canEdit isModeEdition />
                    </DetailsContainer>
                </Flex>
            </MenuContext.Provider>
        </Initial>
    )
}
