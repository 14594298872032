export const INVENTORY_TYPES = Object.freeze({
    SIMPLE: 'Inventario simple',
    COMPUESTO: 'Inventario compuesto',
    NO_INVENTORY: 'Sin inventario'
})

export const CHOICE_TYPES = Object.freeze({
    OPTIONAL: 'Opcional',
    MANDATORY: 'Obligatorio'
})

export const QUANTITY_CHOICE_TYPES = Object.freeze({
    FIXED: 'Fija',
    RANGE: 'Rango'
})

export const inventory_types = [
    {
        label: 'Sin inventario',
        value: INVENTORY_TYPES.NO_INVENTORY
    },
    {
        label: 'Simple',
        value: INVENTORY_TYPES.SIMPLE
    },
    {
        label: 'Compuesto',
        value: INVENTORY_TYPES.COMPUESTO
    }
]

export const choice_types = [
    {
        label: 'Opcional',
        value: CHOICE_TYPES.OPTIONAL
    },
    {
        label: 'Obligatorio',
        value: CHOICE_TYPES.MANDATORY
    }
]

export const quantity_choice_types = [
    {
        label: 'Fija',
        value: QUANTITY_CHOICE_TYPES.FIXED
    },
    {
        label: 'Rango',
        value: QUANTITY_CHOICE_TYPES.RANGE
    }
]

export const SEPARATOR = ';'

import ProductionItem from 'models/production_item'
import ModifierGroup from '../../../models/modifier_group'

interface ProductionItemModifier {
    id: number
    modifier_group: ModifierGroup
}
export interface ModifierGroupLetter {
    modifier_group: any
    choice_type: string
    quantity_choice_type: string
    can_repeat: boolean
    max_quantity: number
    min_quantity: number
}

interface ItemProductionItemModifier {
    id: number
    quantity: number
    magnitude_id: number
    production_item: ProductionItem
}

export const getInformationEditModifierGroup = (
    modifier_groups: ProductionItemModifier[],
    initial_modifier_groups: ProductionItemModifier[]
) => {
    const modifier_group_ids_to_delete = []
    const production_item_ids = modifier_groups?.map(
        data => data.modifier_group.id
    )

    for (const production_item of initial_modifier_groups) {
        const production_item_id = production_item.modifier_group.id
        if (!production_item_ids.includes(production_item_id)) {
            modifier_group_ids_to_delete.push(production_item_id)
        }
    }

    return {
        modifier_groups_to_save: modifier_groups?.map(parseModifierGroup) ?? [],
        modifier_group_ids_to_delete
    }
}

export const parseModifierGroup = (modifierGroup: any) => {
    return {
        modifier_group_id: modifierGroup.modifier_group.id,
        is_required: modifierGroup.choice_type === CHOICE_TYPES.MANDATORY,
        has_range:
            modifierGroup.quantity_choice_type === QUANTITY_CHOICE_TYPES.RANGE,
        can_repeat: modifierGroup.can_repeat,
        max_quantity: modifierGroup.max_quantity,
        min_quantity:
            modifierGroup.quantity_choice_type === QUANTITY_CHOICE_TYPES.RANGE
                ? modifierGroup.min_quantity
                : 0
    }
}

export const getInformationEditProductionItem = (
    item_production_items: ItemProductionItemModifier[],
    initial_item_production_items: ItemProductionItemModifier[]
) => {
    const production_item_ids_to_delete = []
    const production_item_ids = item_production_items?.map(
        data => data.production_item.id
    )

    for (const production_item of initial_item_production_items) {
        const production_item_id = production_item.production_item.id
        if (!production_item_ids.includes(production_item_id)) {
            production_item_ids_to_delete.push(production_item_id)
        }
    }

    return {
        production_items_to_save:
            item_production_items?.map(parseProductionItem) ?? [],
        production_item_ids_to_delete
    }
}

export const parseProductionItem = (productionItem: any) => {
    return {
        production_item_id: productionItem.production_item.id,
        quantity: productionItem.quantity,
        magnitude_id: productionItem.magnitude_id
    }
}

export const validationModifierGroups = (
    modifier_groups: ModifierGroupLetter[]
) => {
    if (modifier_groups?.length <= 0) return ''
    let errors = {}

    for (const modifierGroup of modifier_groups) {
        if (!modifierGroup.choice_type || modifierGroup.choice_type === '') {
            errors = {
                ...errors,
                [modifierGroup.modifier_group.id]:
                    'Debe seleccionar una elección'
            }
            continue
        }

        if (
            modifierGroup.choice_type === CHOICE_TYPES.MANDATORY &&
            modifierGroup.quantity_choice_type === QUANTITY_CHOICE_TYPES.RANGE
        ) {
            if (Number(modifierGroup.min_quantity) <= 0) {
                errors = {
                    ...errors,
                    [modifierGroup.modifier_group.id]:
                        'La cant. mínima deber ser mayor a cero'
                }
                continue
            } else {
                if (
                    Number(modifierGroup.min_quantity) >
                    Number(modifierGroup.max_quantity)
                ) {
                    errors = {
                        ...errors,
                        [modifierGroup.modifier_group.id]:
                            'La cant. máxima deber ser mayor o igual a la cant. mínima'
                    }
                    continue
                }
            }
        } else {
            if (Number(modifierGroup.max_quantity) <= 0) {
                errors = {
                    ...errors,
                    [modifierGroup.modifier_group.id]:
                        'La cant. máxima deber ser mayor a cero'
                }
                continue
            }
        }
    }

    if (Object.keys(errors).length > 0) {
        return JSON.stringify(errors)
    }

    return ''
}
