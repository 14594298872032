import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import Description from '../../../components/Icon/Description'
import Close from '../../../components/Icon/Close'
import ModalEditItem from '../modals/ModalEditItem'
import { useState } from 'react'
import { MenuItemProps } from './types'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'
import ModalModifiersItem from 'components/ModalModifiersItem'
import useMenu from 'hooks/useMenu'

export default function MenuItem({
    menuItem,
    onDelete,
    isModeEdition,
    canEdit
}: MenuItemProps) {
    const [isOpen, setIsOpen] = useState(false)
    const { updateItemWithModifiers } = useMenu()
    function clickHandler(ev: any) {
        const target = ev.target as HTMLDivElement
        if (target.classList.contains('close')) return
        setIsOpen(true)
    }

    const hasModifiers =
        menuItem.modifierGroups && menuItem.modifierGroups?.length > 0

    const price = hasModifiers ? menuItem.total_price ?? 0 : menuItem.price

    return (
        <>
            <ModalEditItem
                isOpen={isOpen}
                data={{
                    itemId: menuItem.itemId,
                    categoryItemId: menuItem.categoryItemId,
                    name: menuItem.name,
                    quantity: menuItem.quantity,
                    description: menuItem.description,
                    unitPrice: menuItem.price,
                    modifierGroups: menuItem.modifierGroups
                }}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                by="pre_orders"
            />
            <ModalModifiersItem
                itemId={menuItem.itemId}
                onDone={(
                    _featuresSelected,
                    quantity,
                    modifierGroupsSelected,
                    _item_price_plus,
                    _,
                    description
                ) => {
                    updateItemWithModifiers(
                        menuItem.hash,
                        quantity,
                        modifierGroupsSelected,
                        description ?? ''
                    )
                }}
                description={menuItem.description ?? ''}
                quantity={menuItem.quantity}
                edition={menuItem.modifierGroups}
                element={click => (
                    <>
                        <Flex
                            onClick={ev => {
                                if (
                                    (hasModifiers ||
                                        Boolean(menuItem.hasModifiers)) &&
                                    !Boolean(menuItem.isForEdition)
                                ) {
                                    click()
                                    return
                                }
                                clickHandler(ev)
                            }}
                            justifyContent="space-between"
                            align="center"
                            role="group"
                            fontSize="lg"
                            pr={{ base: 0, lg: 6 }}
                            pl={{ base: 3, lg: 6 }}
                            py={0.5}
                            cursor="pointer"
                            userSelect="none"
                            pointerEvents={isModeEdition ? 'auto' : 'none'}
                            w="full"
                            _hover={{
                                bg: 'blackAlpha.50'
                            }}
                        >
                            <Text
                                textAlign="left"
                                fontSize={{
                                    xl: '16px',
                                    lg: '14px'
                                }}
                            >
                                <Box minW="6" as="span" display="inline-block">
                                    {menuItem.quantity}
                                </Box>
                                {menuItem.name}
                                {menuItem.description && (
                                    <Icon
                                        as={Description}
                                        fill="gray-2"
                                        fontSize="18px"
                                        ml={2}
                                    />
                                )}
                            </Text>
                            <Flex align="center">
                                <Text
                                    textAlign="right"
                                    fontSize={{ xl: '16px', lg: '14px' }}
                                >
                                    S/{(price * menuItem.quantity).toFixed(2)}
                                </Text>
                                {canEdit && (
                                    <IconButton
                                        icon={<Close />}
                                        aria-label="Eliminar"
                                        size="sm"
                                        fill="red.400"
                                        className="close"
                                        ml={1}
                                        variant="ghost"
                                        mr={{
                                            base: 0,
                                            lg: -4
                                        }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            onDelete()
                                        }}
                                    />
                                )}
                            </Flex>
                        </Flex>
                        <>
                            {menuItem.modifierGroups &&
                                menuItem.modifierGroups.map((fes, _i) => (
                                    <Box
                                        key={_i}
                                        display={'flex'}
                                        gap={2}
                                        fontSize="14px"
                                        pointerEvents={
                                            isModeEdition ? 'auto' : 'none'
                                        }
                                        onClick={ev => {
                                            if (
                                                (hasModifiers ||
                                                    Boolean(
                                                        menuItem.hasModifiers
                                                    )) &&
                                                !Boolean(menuItem.isForEdition)
                                            ) {
                                                click()
                                                return
                                            }

                                            clickHandler(ev)
                                        }}
                                        pl={6}
                                    >
                                        <Text pl={6} pr={5}>
                                            <TextModifierGroupModifiers
                                                modifierGroup={fes}
                                            />
                                        </Text>
                                    </Box>
                                ))}
                        </>
                    </>
                )}
            />
        </>
    )
}
