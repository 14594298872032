import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import ProductionArea from '../../models/production_area'
import { FormProductionArea } from './types'

export const listProductionArea = new ControlList<ProductionArea>({
    path: '/api/production_areas',
    theads: [
        'Nombre',
        'Almacén',
        '¿Dónde se imprimirán las comandas?',
        'Zonas de atención',
        'Cajas de atención',
        'Acciones'
    ]
})

export const formProductionArea = new FormEvent<FormProductionArea>({
    id: '',
    warehouse_id: null,
    name: '',
    printer_ids: [],
    zone_ids: [],
    cashbox_ids: []
})
