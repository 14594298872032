import { Box, Button, Textarea } from '@chakra-ui/react'
import React from 'react'
import Counter from './Counter'

import { SectionCounterProps } from '../types'
import colors from 'config/theme/colors'

export default function SectionCounter({
    counter,
    setCounter,
    isEdition,
    modifierGroupSelected,
    basePrice,
    description,
    setDescription
}: SectionCounterProps) {
    const total = (
        (modifierGroupSelected.reduce(
            (a, b) =>
                a +
                b.modifier_group_modifiers.reduce(
                    (c, d) => c + d.price * d.quantity,
                    0
                ),
            0
        ) +
            basePrice) *
        counter
    ).toFixed(2)

    return (
        <Box>
            <Box py="1" px={'6'}>
                <Textarea
                    w="full"
                    name="description"
                    placeholder="Ingresar información adicional"
                    value={description}
                    onChange={ev => setDescription(ev.target.value)}
                    rows={2}
                />
            </Box>

            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                minH="16"
                overflow={'hidden'}
                margin={'0px'}
                px={'6'}
                gap={3}
                flexDirection="row"
                py="2"
                bg="#F6F6F6"
            >
                <Counter counter={counter} setCounter={setCounter} />
                <Button
                    borderRadius="xl"
                    bg={colors['alo-green']}
                    fontSize="sm"
                    w="full"
                    color="white"
                    type="submit"
                    h={12}
                    _hover={{}}
                    _active={{}}
                >
                    {isEdition ? 'Guardar' : 'Agregar'} S/{total}
                </Button>
            </Box>
        </Box>
    )
}
