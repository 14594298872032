import React from 'react'
import { GetItem, ModifierGroupSelected } from '../types'
import ItemCounter from './ItemCounter'
import { Box } from '@chakra-ui/react'
import ItemChecbox from './ItemChecbox'
import { getQuantity, isCheckedModifierGroupModifier } from '../utils'
import ModifierGroup, { ModifierGroupModifier } from 'models/modifier_group'

interface Props {
    modifierGroupItem: GetItem['modifier_group_items'][number]
    modifierGroupModifier: GetItem['modifier_group_items'][number]['modifier_group']['modifier_group_modifiers'][number]
    addQuantityModifierGroupItem: (
        modifierGroup: ModifierGroup,
        modifierGroupModifier: ModifierGroupModifier,
        quantity: number
    ) => void
    modifierGroupSelected: ModifierGroupSelected[]
    disabled: boolean
}

export default function Item({
    modifierGroupModifier,
    addQuantityModifierGroupItem,
    modifierGroupSelected,
    modifierGroupItem,
    disabled
}: Props) {
    const { can_repeat, modifier_group } = modifierGroupItem
    const isChecked = isCheckedModifierGroupModifier(
        modifierGroupSelected,
        modifier_group,
        modifierGroupModifier,
        can_repeat
    )

    const quantity = getQuantity(
        modifierGroupSelected,
        modifier_group,
        modifierGroupModifier
    )

    return (
        <Box borderBottom={'2px solid #F6F6F6'}>
            {can_repeat ? (
                <ItemCounter
                    name={modifierGroupModifier.modifier.name}
                    price={modifierGroupModifier.price}
                    addQuantity={() => {
                        if (disabled) return
                        addQuantityModifierGroupItem(
                            modifier_group,
                            modifierGroupModifier,
                            1
                        )
                    }}
                    decrementQuantity={() =>
                        addQuantityModifierGroupItem(
                            modifier_group,
                            modifierGroupModifier,
                            -1
                        )
                    }
                    quantity={quantity}
                    disabled={disabled}
                />
            ) : (
                <ItemChecbox
                    name={modifierGroupModifier.modifier.name}
                    price={modifierGroupModifier.price}
                    isChecked={isChecked}
                    disabled={isChecked ? false : disabled}
                    handleClick={() => {
                        const _quantity = isChecked ? -1 : 1

                        if (!isChecked && disabled) return

                        addQuantityModifierGroupItem(
                            modifier_group,
                            modifierGroupModifier,
                            _quantity
                        )
                    }}
                />
            )}
        </Box>
    )
}
