//Storage.ts Enfocado en guardar variables para toda la página
import colors from '../../config/theme/colors'
import FormEvent from '../../lib/form_event'
import CategoryItem from '../../models/category_item'
import Item from '../../models/item'
import { StoragePage } from './types'

export const nextColors: string[] = [
    colors.accent,
    colors.orange[400],
    colors.secondary,
    colors.primary,
    colors['alo-gray'],
    colors.dark
]

//Objeto para almacenar datos generales de la página
export const storagePage: StoragePage = {
    categories: [],
    selectedCategoryId: 0,
    items: [],
    selectedItems: [],
    aditionalInformationCategories: [],
    modalUtils: {
        category_item_id: 0,
        item_id: 0
    },
    isOpenCashbox: false,
    table: null,
    hasOrderTable: false,
    editOrderTable: false,
    currentPin: '',
    cashbox_id: null,
    isPreOrder: false,
    loadingCategory: false
}

//Controlador enfocado para formularios, controla los inputs y el renderizado
export const formItem = new FormEvent({
    category_item_id: 0,
    name: '',
    quantity: 0,
    currentQuantity: 0,
    description: '',
    price: '',
    user_price_edition: 0,
    isDisable_description: true
})

export const formPreOrder = new FormEvent({
    alias: '',
    booking_id: 0,
    text_booking: '',
    type: 0,
    /**
     * Si ya existía una pre-orden con esa reserva (booking)
     */
    pre_order_id: 0
})

export interface ItemData extends Item {
    category_item: CategoryItem
}

export const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}
