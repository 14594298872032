import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listModifierGroup } from '../storage'

export default function FiltersModifierGroup() {
    const { clear, ...props } = listModifierGroup.useSearch()

    return (
        <Flex>
            <Clean value={props.value} onClick={clear}>
                <Input w="80" placeholder="🏸 Buscar por nombre" {...props} />
            </Clean>
        </Flex>
    )
}
