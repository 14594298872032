import React, { useMemo } from 'react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalFooter,
    Text,
    Tr,
    Td,
    Input,
    Flex,
    Checkbox,
    FormControl,
    Box
} from '@chakra-ui/react'
import List from '../../../../lib/list'
import Clean from '../../../../components/Clean'
import ErrorHelper from '../../../../components/ErrorHelper'
import ControlList from '../../../../lib/list/ControlList'
import Modifier from '../../../../models/modifier'
import Pagination from 'lib/list/Pagination'

const listModifier = new ControlList<Modifier>({
    path: '/api/modifiers',
    theads: ['', 'Nombre']
})

interface Props {
    open: boolean
    onClose: () => void
    handleAddModifiers: (modifier: Modifier[]) => void
    isSimple?: boolean
    title: string
    selectedModifiers?: Modifier[]
    size?:
        | 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | 'full'
}

export default function ModalModifiers({
    open,
    onClose,
    isSimple = false,
    handleAddModifiers,
    title,
    selectedModifiers = [],
    size = '2xl'
}: Props) {
    const finalRef = React.useRef<any>()
    const [helper, setHelper] = React.useState<string>('')
    const [checkedProductionItems, setCheckedProductionItems] = React.useState<{
        [id: number]: boolean
    }>({})
    const [modifiers, setModifiers] = React.useState<Modifier[]>([])

    const ids_modifiers = useMemo(
        () => selectedModifiers.map(data => data.id),
        [selectedModifiers]
    )

    React.useEffect(() => {
        listModifier.load()
    }, [])

    const isValid = () => {
        let values = {}
        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                values = {
                    ...values,
                    [key]: value
                }
            }
        }
        return Object.keys(values).length !== 0
    }

    const clearData = () => {
        setModifiers([])
        setCheckedProductionItems({})
    }

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!isValid()) {
            setHelper('Debe seleccionar por lo menos uno')
            return
        }

        const _productionItems = []

        for (const [key, value] of Object.entries(checkedProductionItems)) {
            if (value) {
                const findProductionItem = modifiers.find(
                    data => Number(data.id) === Number(key)
                )
                if (findProductionItem) {
                    _productionItems.push(findProductionItem)
                }
            }
        }
        handleAddModifiers(_productionItems)
        clearData()
    }

    const handleChange = (e: any, modifier: Modifier) => {
        const value = e.target.checked
        const id = modifier.id
        if (value) {
            setModifiers(prev => [...prev, modifier])
        }
        if (isSimple) {
            const findProductionItem = modifiers.find(data => data.id === id)
            if (findProductionItem) {
                if (!value) {
                    setCheckedProductionItems({})
                    return
                }
            }
            setCheckedProductionItems({
                [id]: value
            })
        } else {
            setCheckedProductionItems(prev => {
                return {
                    ...prev,
                    [id]: value
                }
            })
        }
    }

    const { clear, ...props } = listModifier.useSearch()

    const handleClose = () => {
        clearData()
        onClose()
    }

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={open}
            onClose={handleClose}
            isCentered={true}
            size={size}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    <Text>{title}</Text>
                </ModalHeader>
                <form onSubmit={handleSubmit} noValidate>
                    <ModalBody pb={6}>
                        <FormControl>
                            <ErrorHelper helper={helper} />
                        </FormControl>
                        <Flex mb="4">
                            <Clean
                                width={'full'}
                                value={props.value}
                                onClick={clear}
                            >
                                <Input
                                    placeholder="🏸 Buscar por nombre"
                                    {...props}
                                />
                            </Clean>
                        </Flex>
                        <Box maxH={'60dvh'} overflowY={'auto'}>
                            <List
                                control={listModifier}
                                hiddenPagination={true}
                                tablesize="sm"
                                tbody={(productionItem, e) => {
                                    const selected = ids_modifiers.includes(
                                        productionItem.id
                                    )

                                    const isChecked =
                                        selected ||
                                        (checkedProductionItems[
                                            productionItem.id
                                        ] ??
                                            false)
                                    return (
                                        <Tr key={e}>
                                            <Td textAlign="center" width="28">
                                                <Checkbox
                                                    size="lg"
                                                    isChecked={isChecked}
                                                    disabled={selected}
                                                    onChange={event =>
                                                        handleChange(
                                                            event,
                                                            productionItem
                                                        )
                                                    }
                                                />
                                            </Td>
                                            <Td textAlign="center">
                                                {productionItem.name}
                                            </Td>
                                        </Tr>
                                    )
                                }}
                            />
                        </Box>
                        <Pagination control={listModifier} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={handleClose}
                            type="button"
                        >
                            Cancelar
                        </Button>
                        <Button variant="alo-green:lg-md" ml={3} type="submit">
                            Agregar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
