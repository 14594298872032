import { formProductionArea, listProductionArea } from './storage'
import useSubmit from '../../hooks/useSubmit'
import modal from '../../lib/modal'
import ProductionArea from '../../models/production_area'
import { FormProductionArea as FormProductionAreaType } from './types'
import FormProductionArea from './FormProductionArea'

export default function useProductionArea(productionArea?: ProductionArea) {
    const { validation, observer, submit } = useSubmit(formProductionArea, {
        path: productionArea
            ? `/api/production_areas/${productionArea.id}`
            : '/api/production_areas',
        method: productionArea ? 'put' : 'post',
        done: () => listProductionArea.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<FormProductionAreaType>(f => ({
        name: f.name,
        warehouse_id: f.warehouse_id
            ? parseInt(f.warehouse_id).toString()
            : null,
        printer_ids: f.printer_ids,
        zone_ids: f.zone_ids,
        cashbox_ids: f.cashbox_ids
    }))

    async function openModal() {
        if (productionArea) {
            formProductionArea.store({
                id: productionArea.id.toString() ?? '',
                name: productionArea.name,
                zone_ids: productionArea.production_area_zones.map(
                    data => data.zone_id
                ),
                cashbox_ids: productionArea.production_area_cashboxes.map(
                    data => data.cashbox_id
                ),
                printer_ids: productionArea.production_area_printers.map(
                    data => data.printer_id
                ),
                warehouse_id: productionArea.warehouse_id?.toString() ?? null
            })
            formProductionArea.init(formProductionArea.store)
        } else {
            formProductionArea.init()
        }

        modal(FormProductionArea, {
            title: productionArea
                ? 'Editar Área de producción'
                : 'Nueva Área de producción',
            onSubmit: submit,
            size: '2xl',
            isCentered: false
        })
    }

    return openModal
}
