import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
    name: string
    price?: number
    isChecked: boolean
    handleClick: () => void
    disabled: boolean
}

export default function ItemChecbox({
    name,
    isChecked,
    handleClick,
    disabled,
    price = 0
}: Props) {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}
            minH="40px"
            py="2"
        >
            <Box>
                <Text>{name}</Text>
                {price > 0 && (
                    <Flex alignItems={'center'} direction={'row'} gap={2}>
                        <Text>S/{price.toFixed(2)}</Text>
                    </Flex>
                )}
            </Box>
            <Checkbox
                onChange={() => handleClick()}
                isChecked={isChecked}
                size="lg"
                colorScheme="green"
                isDisabled={disabled}
            />
        </Box>
    )
}
