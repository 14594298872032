import colors from '../config/theme/colors'

export default function useListColors() {
    const listColors: string[] = [
        colors.accent,
        colors.orange[400],
        colors.red[500],
        colors.red[400],
        colors['alo-gray'],
        colors.dark
    ]

    function get(index: number) {
        const i = index % 6
        return listColors[i]
    }

    return {
        colors: listColors,
        get
    }
}
