import { Text } from '@chakra-ui/react'
import { storagePage } from '../../storage'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import StatDivider from '../../Components/StatDivider'

export default function DeliveryDetail() {
    const { cashboxMovements } = storagePage

    const totalOrders = cashboxMovements
        .map(i => i.totalOrdersDeliveries)
        .reduce((a, b) => a + b, 0)

    const totalSale = cashboxMovements
        .map(i => i.totalSaleDeliveries)
        .reduce((a, b) => a + b, 0)

    const totalCost = cashboxMovements
        .map(i => i.totalCostDeliveries)
        .reduce((a, b) => a + b, 0)

    return (
        <Card direction="column" gridGap={4} w="full">
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Delivery
            </Text>
            <StatCard>
                <StatBox
                    label="Total"
                    value={totalSale.toFixed(2)}
                    withSymbol={true}
                />
                <StatDivider />
                <StatBox label="N° pedidos" value={totalOrders} />
                <StatDivider />
                <StatBox
                    label="Costo"
                    value={totalCost.toFixed(2)}
                    withSymbol={true}
                />
            </StatCard>
        </Card>
    )
}
