import React from 'react'
import { Box } from '@chakra-ui/react'
import colors from 'config/theme/colors'

interface Props {
    isComplete: boolean
}

export default function Badge({ isComplete }: Props) {
    return (
        <Box
            bg={isComplete ? colors['alo-green'] : colors.dark}
            px={3}
            py={1}
            borderRadius="full"
            color={'white'}
            fontSize={'xs'}
        >
            {isComplete ? 'Completado' : 'Obligatorio'}
        </Box>
    )
}
