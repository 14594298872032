import { Button } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import Download from '../../../../components/Icon/Download'
import { get } from '../../../../lib/http'
import { DownloadExcelProps, FindItem } from './types'

const head: (...values: string[]) => Row[number][] = (...values) =>
    values.map<Row[number]>(val => ({
        value: val,
        fontWeight: 'bold',
        wrap: true,
        alignVertical: 'center',
        align: 'left',
        fontSize: 11
    }))

export default function DownloadExcel({
    startDate,
    endDate
}: DownloadExcelProps) {
    const [isLoading, setIsLoading] = useState(false)

    async function load() {
        setIsLoading(true)
        const sDate = dayjs(startDate).format('DD/MM/YYYY')
        const eDate = dayjs(endDate).format('DD/MM/YYYY')
        const { data: items } = await get<FindItem[]>(
            `/api/inventory_production_items?&search=`
        )

        const rows: SheetData = [
            [],
            [
                {
                    value: 'Administración de Inventario',
                    fontWeight: 'bold',
                    span: 5,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [],
            head(
                'Artículo',
                'Almacén',
                'Stock',
                'Unidad de medida',
                'Costo unitario',
                'Costo Total'
            )
        ]

        items.result.forEach(item => {
            rows.push([
                {
                    value: item.production_item.name,
                    type: String
                },
                {
                    value: item.warehouse.name,
                    type: String
                },
                {
                    value: item.stock,
                    type: Number
                },
                {
                    value: item.production_item.magnitude?.name,
                    type: String
                },
                {
                    value: item.cost ? item.cost : '-',
                    type: item.cost ? Number : String
                },
                {
                    value: item.cost
                        ? Math.round(item.cost * item.stock * 100) / 100
                        : '-',
                    type: item.cost ? Number : String
                }
            ])
        })

        await writeXlsxFile(rows, {
            fileName: `kardex_${sDate}_${eDate}.xlsx`,
            columns: [30, 12, 12, 12, 12].map(width => ({ width })),
            fontSize: 10
        })

        setIsLoading(false)
    }

    return (
        <Button
            variant="alo-gray:md-md"
            type="submit"
            rightIcon={<Download fill="white" />}
            onClick={load}
            isLoading={isLoading}
        >
            Exportar a Excel
        </Button>
    )
}
