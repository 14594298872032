import { useEffect, useState } from 'react'
import Table from '../../../models/table'
import { get } from '../../../lib/http'
import { OrderByTableContextInterface } from '../contexts/OrderByTableContext'
import { FindOneTable } from '../types'
import { MenuContextInterface } from '../../../context/MenuContext'
import User from '../../../models/user'
import useSession from '../../../hooks/useSession'
import useParamsQuickSaleBy from './useParamsQuickSaleBy'
import { PreOrderContextInterface } from '../contexts/PreOrderContext'
import itemHash from '../../../utilities/item-hash'
import {
    getModifierGroups,
    getTotalPrice
} from 'components/ModalModifiersItem/utils'

/**
 * Provee los datos y funciones necesarios para una orden por mesa
 */
export default function useOrderByTableProviderValues(
    menuProviderValues: MenuContextInterface,
    preOrderValues: PreOrderContextInterface
): OrderByTableContextInterface {
    const { tableId, by } = useParamsQuickSaleBy()
    const [table, setTable] = useState<Table | null>(null)
    const [orderId, setOrderId] = useState(0)
    const [waiter, setWaiter] = useState<Omit<User, 'password'> | null>(null)
    const [isModeEdition, setIsModeEdition] = useState(true)
    const [_pin, setPin] = useState('')
    const { isLogged } = useSession()

    async function loader() {
        const response = await get<FindOneTable>(`/api/tables/${tableId}`)
        if (!response.data) return
        const dataTable = response.data.result
        setTable(dataTable)
        if (dataTable.order) {
            setIsModeEdition(false)
            setWaiter(dataTable.waiter)
            setOrderId(dataTable.order.id)
            if (dataTable.order?.booking) {
                preOrderValues.setBooking(dataTable.order.booking)
            }
            if (
                dataTable.order?.booking?.type === 2 &&
                dataTable.order.booking.driver &&
                !dataTable.order.booking.direct_booking
            ) {
                preOrderValues.setDriver(dataTable.order.booking.driver)
            }

            menuProviderValues.init(
                dataTable.order_items.map(orderItem => {
                    const modifierGroups = getModifierGroups(
                        orderItem.modifier_orders ?? []
                    )
                    return {
                        hash: itemHash({
                            orderItemId: orderItem.id,
                            itemId: orderItem.item_id
                        }),
                        itemId: orderItem.item_id,
                        orderItemId: orderItem.id,
                        userPriceEdition: orderItem.user_price_edition,
                        name: orderItem.item.name,
                        quantity: orderItem.quantity,
                        price: orderItem.item?.price ?? 0,
                        description: orderItem?.aditional_information ?? '',
                        categoryItemId: orderItem.item.category_item_id,
                        categoryName: orderItem.item.category_item.name,
                        commission: orderItem.commission,
                        isForEdition: true,
                        total_price: getTotalPrice(
                            orderItem.item.price ?? 0,
                            modifierGroups
                        ),
                        modifierGroups
                    }
                })
            )
        }
    }

    function enableEdition(pin?: string) {
        if (pin && !isLogged) {
            setPin(pin)
            setIsModeEdition(true)
        } else {
            setIsModeEdition(true)
        }
    }

    function disableEdition() {
        setIsModeEdition(false)
    }

    useEffect(() => {
        if (by !== 'tables') return
        if (!tableId) return
        loader()
    }, [])

    /**
     * Evalúa si la mesa ha cargado sus datos.
     * En caso de que no reciba la id de la mesa por ruta,
     * quiere decir que no es un pedido por mesa.
     */
    const isSuccess = (tableId && table) || !tableId ? true : false

    return {
        table,
        waiter,
        setWaiter,
        setTable,
        isSuccess,
        orderId,
        isModeEdition,
        enableEdition,
        disableEdition,
        pin: _pin,
        setPin
    }
}
