import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import useDelivery from '../../../hooks/useDelivery'
import useInfoDialog from '../../../hooks/useInfoDialog'
import useOverlapLoader from '../../../hooks/useOverlapLoader'
import useSession from '../../../hooks/useSession'
import { post, put } from '../../../lib/http'
import val from '../../../lib/val'
import { PaymentMethod } from '../../../types/deliveryMode'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'
import {
    CreateDelivery,
    MenuItem,
    UpdateDelivery,
    UpdateMenuItem
} from './types'

export default function useSaveDelivery() {
    const dialog = useInfoDialog()
    const setHelpers = useDeliveryHelper(state => state.setHelpers)
    const deliveryForm = useDeliveryForm()
    const {
        client,
        products,
        delivery,
        setClient,
        setDelivery,
        setProducts,
        orderEdition,
        total
    } = useDelivery()
    const loader = useOverlapLoader()
    const { push } = useHistory()
    const toast = useToast()
    const { session } = useSession()

    function clear() {
        deliveryForm.clear()
        setClient(null)
        setDelivery(null)
        setProducts([])
        orderEdition(false)
    }

    function validForm() {
        const v = val(deliveryForm)
        v.address.isEmpty('Seleccione una dirección')
        v.phone.isEmpty('Seleccione un teléfono')
        v.deliveryMode.isEmpty('Seleccione el modo de entrega')
        if (deliveryForm.deliveryMode === '1') {
            v.date.isEmpty('Agregue la fecha de entrega')
            v.hour.isEmpty('Agregue la hora de entrega')
        }
        v.paymentMethod.isEmpty('Seleccione un método de pago')
        if (deliveryForm.paymentMethod === '0') {
            v.cashClient.isEmpty('Agregue un monto')
        }
        v.priceDelivery.isEmpty('Agregue precio por delivery')

        const result = v.runtest()

        if (result) {
            setHelpers(result)
            return false
        }
        return true
    }

    const getDataForUpdate: () => Omit<UpdateDelivery, 'items'> = () => {
        const paymentMethod = Number(
            deliveryForm.paymentMethod
        ) as PaymentMethod

        const cashClient =
            paymentMethod === PaymentMethod.CASH
                ? Number(deliveryForm.cashClient)
                : total

        return {
            userId: session?.user.id as number,
            address: deliveryForm.address[0],
            reference: deliveryForm.address[1],
            cashClient,
            deliveryPrice: Number(deliveryForm.priceDelivery),
            observation: deliveryForm.observation,
            paymentMethod,
            phoneNumber: deliveryForm.phone,
            programmed: deliveryForm.deliveryMode === '1',
            deliveryDate:
                deliveryForm.deliveryMode === '1'
                    ? dayjs(
                          `${deliveryForm.date} ${deliveryForm.hour}`,
                          'YYYY-MM-DD HH:mm'
                      ).toISOString()
                    : dayjs().toISOString()
        }
    }

    async function create() {
        if (!validForm()) return

        const body: CreateDelivery = {
            ...getDataForUpdate(),
            clientId: client?.id as number,
            userId: session?.user.id as number,
            items: products
                .map(prod =>
                    prod.items.map<MenuItem>(item => ({
                        itemId: item.itemId,
                        commission: 0,
                        description: item.description,
                        price: item.price,
                        quantity: item.quantity,
                        modifiers:
                            item.modifier_groups
                                ?.map(fs =>
                                    fs.modifier_group_modifiers.map(fssf => ({
                                        modifier_id: fssf.modifier_id,
                                        quantity: fssf.quantity,
                                        price: fssf.price
                                    }))
                                )
                                .flat() ?? []
                    }))
                )
                .flat()
        }

        loader.start('Registrando pedido...')
        const response = await post('/api/deliveries', body)
        loader.end()
        if (response.error) {
            if (response.error.type === 'customer') {
                dialog.show('Stock insuficiente', response.error.message)
                return
            }

            toast({
                title: 'Hubo un error al registrar el pedido',
                position: 'top',
                status: 'error'
            })
            return
        }
        toast({
            title: 'Pedido registrado.',
            position: 'top',
            status: 'success'
        })
        clear()
        push('/deliveries')
    }

    async function update(reason?: string) {
        if (!validForm()) return
        const items = products
            .map(prod =>
                prod.items.map<UpdateMenuItem>(item => {
                    const orderItem = delivery?.order.order_items.find(
                        _orderItem =>
                            _orderItem.id === (item.orderItemId as number)
                    )

                    const initQuantity = orderItem?.quantity || 0
                    const marginalQuantity = item.quantity - initQuantity

                    return {
                        orderItemId: item.orderItemId,
                        itemId: item.itemId,
                        commission: 0,
                        description: item.description,
                        price: item.price,
                        marginalQuantity,
                        modifiers:
                            item.modifier_groups
                                ?.map(fs =>
                                    fs.modifier_group_modifiers.map(fssf => ({
                                        modifier_id: fssf.modifier_id,
                                        quantity: fssf.quantity,
                                        price: fssf.price
                                    }))
                                )
                                .flat() ?? []
                    }
                })
            )
            .flat()

        const body: UpdateDelivery = {
            ...getDataForUpdate(),
            items,
            deliveryDate:
                deliveryForm.deliveryMode === '1'
                    ? dayjs(
                          `${deliveryForm.date} ${deliveryForm.hour}`,
                          'YYYY-MM-DD HH:mm'
                      ).toISOString()
                    : (delivery?.delivery_date as string),
            ...(reason && {
                reason
            })
        }

        loader.start('Guardando pedido...')
        const response = await put(`/api/deliveries/${delivery?.id}`, body)
        loader.end()
        if (response.error) {
            if (response.error.type === 'customer') {
                dialog.show('Stock insuficiente', response.error.message)
                return
            }

            toast({
                title: 'Hubo un error al guardar el pedido',
                position: 'top',
                status: 'error'
            })
            return
        }
        toast({
            title: 'Pedido guardado',
            position: 'top',
            status: 'success'
        })
        clear()
        push('/deliveries')
    }

    return {
        create,
        update
    }
}
