import { Icon } from '@chakra-ui/react'
import useProductionArea from './useProductionArea'
import ProductionArea from '../../models/production_area'
import Pencil from '../../components/Icon/Pencil'
import Colors from '../../config/colors'

export default function Edition({
    productionArea
}: {
    productionArea: ProductionArea
}) {
    const openModal = useProductionArea(productionArea)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
