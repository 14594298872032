import { Box, Spinner } from '@chakra-ui/react'
import { BoxCategoryProps } from './types'

export default function BoxCategory({
    name,
    isActive,
    onClick,
    isLoading
}: BoxCategoryProps) {
    const isLoadingCategory = isActive && isLoading
    return (
        <Box
            minH={{ base: '30px', lg: '60px' }}
            borderRadius="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={{ base: 'sm', xl: 'md' }}
            cursor="pointer"
            whiteSpace={{ base: 'nowrap', lg: 'normal' }}
            w="100%"
            textAlign="center"
            p="0 10px"
            bg={{
                base: isActive ? 'dark' : 'transparent',
                lg: isActive ? 'white' : 'transparent'
            }}
            color={{
                base: isActive ? 'white' : 'dark',
                lg: isActive ? 'dark' : 'white'
            }}
            border="2px solid"
            borderColor={{ base: 'dark', lg: 'white' }}
            onClick={() => {
                if (isLoadingCategory) return
                onClick()
            }}
        >
            {isLoadingCategory ? <Spinner /> : <>{name}</>}
        </Box>
    )
}
