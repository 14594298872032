import { render } from 'redity'
import CategoryItem from '../../../models/category_item'
import Item from '../../../models/item'
import {
    FeatureSetting,
    ModifierGroup,
    SelectedItem
} from '../../../types/runway_state'
import { generateId } from '../../../utilities/generateId'
import Keys from '../keys'
import { FeatureSelected } from '../ContentModals/IceCream/types'
import { storagePage } from '../storage'
import getFeatureSettingSubFeatures from '../utilities/getFeatureSettingSubFeatures'
import getItemsSelected from '../utilities/getItemsSelected'
import Big from 'big.js'
import { compareModifierGroups } from 'components/ModalModifiersItem/utils'

export default function useAddItems(
    item: Item & {
        category_item: CategoryItem
    }
) {
    const { selectedItems } = storagePage
    const getItemForAdd = (
        quantity: number,
        feature_settings: FeatureSetting[],
        modifier_groups: ModifierGroup[],
        idGenerated?: string,
        features_price?: number,
        hasModifiers = false,
        description = ''
    ) => ({
        id: item.id,
        _id_generated: idGenerated,
        name: item.name,
        quantity,
        quantity_recorded: 0,
        price: item.price,
        description,
        user_price_edition: 0,
        feature_settings,
        modifier_groups,
        has_modifiers: hasModifiers,
        total_price: Number(
            new Big(item.price).plus(features_price ?? 0).toString()
        )
    })

    function addItem(quantity?: number) {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )
        if (currentItems[0]) {
            currentItems[0].quantity = quantity
                ? quantity + currentItems[0].quantity
                : ++currentItems[0].quantity
        } else {
            contitionalPushItem(category, quantity || 1)
        }
        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function addItemWithFeatures(
        featureSelected: FeatureSelected[],
        quantity: number,
        modifiersGroup: ModifierGroup[] = [],
        description: string
    ) {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )

        const featureSettings = featureSelected.map(fs => ({
            feature_id: fs.feature_id,
            quantity: fs.quantity,
            name: fs.name,
            feature_setting_sub_features: getFeatureSettingSubFeatures(fs.subs)
        }))

        const features_price = modifiersGroup.reduce(
            (a, b) =>
                a +
                b.modifier_group_modifiers.reduce(
                    (c, d) => c + d.price * d.quantity,
                    0
                ),
            0
        )

        if (currentItems.length > 0) {
            let isFinded = false
            for (const currentItem of currentItems) {
                if (
                    compareModifierGroups(currentItem.modifier_groups, [
                        ...modifiersGroup
                    ])
                ) {
                    currentItem.quantity = currentItem.quantity + quantity
                    isFinded = true
                    break
                }
            }
            if (!isFinded) {
                contitionalPushItem(
                    category,
                    quantity,
                    featureSettings,
                    modifiersGroup,
                    features_price,
                    item.has_modifier_groups,
                    description
                )
            }
        } else {
            contitionalPushItem(
                category,
                quantity,
                featureSettings,
                modifiersGroup,
                features_price,
                item.has_modifier_groups,
                description
            )
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function contitionalPushItem(
        category: SelectedItem | undefined,
        quantity = 1,
        featureSettings: FeatureSetting[] = [],
        modifiersGroup: ModifierGroup[] = [],
        features_price = 0,
        hasModifiers = false,
        description = ''
    ) {
        const generatedId =
            featureSettings.length > 0 ? generateId() : undefined
        if (category) {
            category.items.push(
                getItemForAdd(
                    quantity,
                    featureSettings,
                    modifiersGroup,
                    generatedId,
                    features_price,
                    hasModifiers,
                    description
                )
            )
        } else {
            selectedItems.push({
                category_item_id: item.category_item_id,
                category_item_name: item.category_item.name,
                items: [
                    getItemForAdd(
                        quantity,
                        featureSettings,
                        modifiersGroup,
                        generatedId,
                        features_price,
                        hasModifiers,
                        description
                    )
                ]
            })
        }
    }

    return {
        addItem,
        addItemWithFeatures
    }
}
