import { Box, Flex, Spinner } from '@chakra-ui/react'
import BoxCategory from './BoxCategory'
import { useGet } from '../../../../hooks/useHttp'
import CategoryItem from '../../../../models/category_item'
import { useEffect } from 'react'
import useCashbox from '../../hooks/useCashbox'
import useSession from '../../../../hooks/useSession'
import { SidebearCategoriesProps } from './types'

export default function SidebarCategories({
    onCategoryItem,
    categoryItemId,
    isLoadingCategory
}: SidebearCategoriesProps) {
    const { isLogged } = useSession()
    const { cashboxUser } = useCashbox()
    const { data, isLoading } = useGet<CategoryItem[]>(
        '/api/category_items?sort=order',
        []
    )

    useEffect(() => {
        if (data.result.length === 0) return
        onCategoryItem(data.result[0].id)
    }, [data.result.length])

    return (
        <Box
            display="flex"
            flexDirection={{ base: 'row', lg: 'column' }}
            bg={{ base: 'white', lg: 'gray-2' }}
            w={{ base: '100%', lg: '165px', xl: '180px' }}
            p="3"
            minW="120px"
            gridGap="15px"
            overflowY="auto"
            maxH={`calc(100vh - ${!isLogged || cashboxUser ? '117' : '143'}px)`}
        >
            {isLoading && (
                <Flex justify="center">
                    <Spinner color="white" />
                </Flex>
            )}
            {data.result.map(categoryItem => (
                <BoxCategory
                    key={categoryItem.id}
                    isActive={categoryItem.id === categoryItemId}
                    name={categoryItem.name}
                    onClick={() => {
                        if (
                            isLoadingCategory &&
                            categoryItem.id === categoryItemId
                        ) {
                            return
                        }
                        onCategoryItem(categoryItem.id)
                    }}
                    isLoading={isLoadingCategory}
                />
            ))}
        </Box>
    )
}
