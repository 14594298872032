import { Box, Text } from '@chakra-ui/react'
import { ModifierGroup } from '../../../types/runway_state'
import TextModifierGroupModifiers from 'components/TextModifierGroupModifers'

export default function Item({
    quantity,
    description,
    cost,
    modifierGroups
}: PropsItem) {
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mb="1"
                alignItems="start"
            >
                <Box display="flex" mr={8}>
                    <Text w="7">{quantity}</Text>
                    <Text fontSize="sm">{description}</Text>
                </Box>
                <Text fontSize="sm" w="24" textAlign="right">
                    {cost}
                </Text>
            </Box>
            {modifierGroups.map((modifierGroup, i) => (
                <Box pl={7} key={i} fontSize="14px">
                    <Text>
                        <TextModifierGroupModifiers
                            modifierGroup={modifierGroup}
                        />
                    </Text>
                </Box>
            ))}
        </Box>
    )
}

interface PropsItem {
    quantity: number
    description: string
    cost: string
    modifierGroups: ModifierGroup[]
}
