import React from 'react'
import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Select,
    SelectProps
} from '@chakra-ui/react'
import { useGet } from '../../../hooks/useHttp'
import { formProductionArea } from '../storage'
import Warehouse from '../../../models/warehouse'
import MultipleField from './MutipleField'
import ErrorHelper from '../../../components/ErrorHelper'

export default function FormProductionArea() {
    return (
        <>
            <>
                <Name />
                <WarehouseSelect />
                <Printers />
                <AttentionZones />
                <CashBoxes />
            </>
        </>
    )
}

function Name() {
    const { props, helper, error } =
        formProductionArea.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del área de producción"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function WarehouseSelect() {
    const { data } = useGet<Warehouse[]>('/api/warehouses?sort=name', [])
    const { props } = formProductionArea.useField<SelectProps>('warehouse_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Almacén</FormLabel>
            <Select {...props} placeholder="Seleccionar almacén">
                {data.result.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
        </FormControl>
    )
}

function Printers() {
    const { value, setValue } = formProductionArea.useValue('printer_ids')

    return (
        <FormControl marginBottom="4">
            <FormLabel>¿Dónde se imprimirán las comandas?</FormLabel>
            <MultipleField
                value={value}
                setValue={setValue}
                path="printer_machines"
            />
        </FormControl>
    )
}

function AttentionZones() {
    const { value, setValue } = formProductionArea.useValue('zone_ids')

    return (
        <FormControl marginBottom="4">
            <FormLabel>Zonas de atención</FormLabel>
            <MultipleField value={value} setValue={setValue} path="zones" />
        </FormControl>
    )
}

function CashBoxes() {
    const { value, setValue } = formProductionArea.useValue('cashbox_ids')

    return (
        <FormControl marginBottom="4">
            <FormLabel>Cajas de atención</FormLabel>
            <MultipleField value={value} setValue={setValue} path="cashboxes" />
        </FormControl>
    )
}
