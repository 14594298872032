import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import SelectWithLabel from '../../../components/SelectWithLabel'
import { useGet } from '../../../hooks/useHttp'
import CategoryItem from '../../../models/category_item'
import { listCombo } from '../storage'

export default function FiltersCombo() {
    const { clear, ...props } = listCombo.useSearch()
    const propsFilter = listCombo.useFilter('category_item_id')
    const { data } = useGet<CategoryItem[]>(
        '2>/api/category_items?limit=0&page=1&order=asc&sort=name&search=',
        []
    )

    return (
        <Flex gridGap="2">
            <Clean value={props.value} onClick={clear}>
                <Input placeholder="🏸 Buscar por nombre" {...props} />
            </Clean>
            <SelectWithLabel
                {...propsFilter}
                label="Seleccionar categoría:"
                placeholder="Todos"
            >
                {data.result.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </SelectWithLabel>
        </Flex>
    )
}
