import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Box,
    Button,
    Text,
    Icon,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    NumberIncrementStepper
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import { formModifier, listProductionItem } from '../storage'
import React from 'react'
import ModalProductionItems from '../modals/ModalProductionItems'
import ProductionItem from '../../../models/production_item'
import { useGet } from '../../../hooks/useHttp'
import TypeMeasure from '../../../models/type_measure'
import colors from '../../../config/theme/colors'
import Close from '../../../components/Icon/Close'
import { SEPARATOR } from './util'

export default function FormModifier() {
    return (
        <>
            <Name />
            <AsociatedProductionItems />
        </>
    )
}

function Name() {
    const { props, helper, error } = formModifier.useField<InputProps>('name')

    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del modificador"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function AsociatedProductionItems() {
    const [open, setOpen] = React.useState<boolean>(false)

    const {
        value: item_production_items,
        setValue: setItemProductionItems,
        helper
    } = formModifier.useValue('production_items')

    const { data: dataMagnitude } = useGet<TypeMeasure[]>(
        '/api/magnitudes?sort=name',
        []
    )

    const errors = helper !== '' ? helper?.split(SEPARATOR) : []

    const handleAddItemProductionItems = (data: ProductionItem[]) => {
        const newsProductionItems = []
        for (const productionItem of data) {
            const findProductionItem = item_production_items?.find(
                (_data: any) => _data.production_item.id === productionItem?.id
            )
            if (!findProductionItem) {
                newsProductionItems.push({
                    production_item: productionItem,
                    quantity: 0,
                    magnitude_id: ''
                })
            }
        }

        setItemProductionItems([
            ...item_production_items,
            ...newsProductionItems
        ])
        setOpen(false)
    }

    const handleDelete = (index: number) => {
        const newsProductionItems = []
        for (let i = 0; i < item_production_items.length; i++) {
            if (i !== index) {
                newsProductionItems.push(item_production_items[i])
            }
        }
        setItemProductionItems([...newsProductionItems])
    }

    const handleChange = (index: number, _value: string, name: string) => {
        item_production_items[index][name] = _value
        setItemProductionItems([...item_production_items])
    }

    const handleChangeMeasure = (
        e: React.ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        item_production_items[index]['magnitude_id'] = e.target.value
        setItemProductionItems([...item_production_items])
    }

    const productionItems =
        item_production_items.map(data => data.production_item) ?? []

    return (
        <FormControl mb={4}>
            <FormLabel>Asociar artículos de inventario</FormLabel>
            <Box>
                <Button
                    background="#FFF"
                    height="40px"
                    fontSize="14px"
                    color="#4E6BD3"
                    border="1px solid #C5C9D0"
                    borderRadius="18px"
                    fontWeight={500}
                    width="114px"
                    type="button"
                    onClick={() => setOpen(true)}
                >
                    Agregar
                </Button>
            </Box>
            <ModalProductionItems
                open={open}
                onClose={() => setOpen(false)}
                handleAddProductionItems={handleAddItemProductionItems}
                title="Asociar artículo(s) de inventario"
                listProductionItem={listProductionItem}
                size="xl"
                selectedProductionItems={productionItems}
            />
            {item_production_items?.length !== 0 ? (
                <Box display="flex" mt="4">
                    <Box width={'40%'} paddingX="4">
                        <Text fontSize="12px">Nombre</Text>
                    </Box>
                    <Box paddingX="2" width={'25%'}>
                        <Text fontSize="12px">Cantidad</Text>
                    </Box>
                    <Box width={'30%'} paddingX="2">
                        <Text fontSize="12px">Unidad</Text>
                    </Box>
                </Box>
            ) : null}
            {item_production_items?.map(
                (item_production_item: any, i: number) => (
                    <Box key={i} marginBottom="2" marginTop="2">
                        <Box
                            display="flex"
                            border="1px solid #C4C4C4"
                            borderRadius="10px"
                            paddingX="2"
                        >
                            <Box
                                width={'40%'}
                                paddingX="2"
                                paddingY="2"
                                borderRight="1px solid #C4C4C4"
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Input
                                    value={
                                        item_production_item.production_item
                                            ?.name
                                    }
                                    placeholder=""
                                    border="none"
                                    borderRadius="0px"
                                    height="30px"
                                    readOnly
                                    padding={'0px'}
                                />
                            </Box>
                            <Box
                                width={'25%'}
                                paddingX="1"
                                paddingY="2"
                                borderRight="1px solid #C4C4C4"
                            >
                                <NumberInput
                                    onChange={_value =>
                                        handleChange(i, _value, 'quantity')
                                    }
                                    placeholder={''}
                                    value={item_production_item.quantity ?? 0}
                                    precision={2}
                                    min={0}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box
                                width={'30%'}
                                paddingX="1"
                                paddingY="2"
                                borderRight="1px solid #C4C4C4"
                            >
                                <Select
                                    value={item_production_item.magnitude_id}
                                    onChange={e => handleChangeMeasure(e, i)}
                                    placeholder="Seleccionar unidad"
                                >
                                    {dataMagnitude.result.map(typeMeasure => (
                                        <option
                                            key={typeMeasure.id}
                                            value={typeMeasure.id}
                                        >
                                            {typeMeasure.name}
                                        </option>
                                    ))}
                                </Select>
                            </Box>
                            <Box
                                paddingX="2"
                                paddingY="2"
                                width={'5%'}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Icon
                                    onClick={() => handleDelete(i)}
                                    cursor="pointer"
                                    fill={colors.error}
                                    as={Close}
                                />
                            </Box>
                        </Box>
                        {errors?.[i] && (
                            <Text fontSize={'14px'} color={colors.error}>
                                {errors[i]}
                            </Text>
                        )}
                    </Box>
                )
            )}
        </FormControl>
    )
}
